import {createStore} from "vuex"
import VuexPersistence from 'vuex-persist'
import cart from './modules/cart'
import createMultiTabState from 'vuex-multi-tab-state'
import auth from "@/store/modules/auth";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default createStore({
    modules: {
        cart: cart,
        auth: auth
    },
    plugins: [vuexLocal.plugin, createMultiTabState()],
})
