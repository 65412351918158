import axios from "axios";
import {router} from "@/router";
import Swal from 'sweetalert2/dist/sweetalert2';
import store from './store/index'

// export const verifyPhoneCheck = () => {
//     if(localStorage.getItem('token')) {
//
//         let isVerified = JSON.parse(localStorage.getItem('user'))?.phone_verified
//         if(!isVerified) {
//             $('#verifyPhoneModal').modal('show')
//
//             axios.get(`${process.env.VUE_APP_API_URL}check-phone-verification`, {
//                 headers: {
//                     'Authorization': `Bearer ${localStorage.getItem('token')}`,
//                     'Accepted-Language': localStorage.getItem('lang')
//                 }
//             }).then(res => {
//                 Swal.fire({
//                     icon: 'success',
//                     title: res.data.message,
//                     showConfirmButton: false,
//                     customClass: {
//                         container: 'swal-visibility'
//                     },
//                     toast: true,
//                     timer: 5000,
//                     position: localStorage.getItem('lang')  === 'en' ? 'top-end' : 'top-start',
//                 })
//                 return false
//             }).catch(err => {
//                 $('#verifyPhoneModal').hide()
//                 $('.modal-backdrop').remove();
//
//                 Swal.fire({
//                     icon: 'success',
//                     title: localStorage.getItem('lang') === 'en' ? 'Already verified' : 'تم التفعيل مسبقاً',
//                     showConfirmButton: false,
//                     customClass: {
//                         container: 'swal-visibility'
//                     },
//                     toast: true,
//                     timer: 5000,
//                     position: localStorage.getItem('lang')  === 'en' ? 'top-end' : 'top-start',
//                 })
//
//                 axios.get(`${process.env.VUE_APP_API_URL}profile`, {
//                     headers: {
//                         'Authorization': `Bearer ${localStorage.getItem('token')}`,
//                         'Accepted-Language': localStorage.getItem('lang')
//                     }
//                 }).then(res => {
//                     localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
//                 }).catch(err => {
//
//                 })
//                 return true
//             })
//         } else {
//             return true
//         }
//     }
// }

export const checkAuth = (message) => {
    if(message === 'Unauthenticated') {

        store.commit('resetCart')
        let currentLang = localStorage.getItem('lang')
        localStorage.clear()
        localStorage.setItem('lang', currentLang)
        router.replace('/')
    }
}
