<template>
    <!-- Privacy policy -->
	<div class="main-wrapper">
		<layouts></layouts>

		<div class="page-wrapper">
			<breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

			<div class="login-content">
				<div class="container">
					<div class="row">
						<div class="col-md-6 col-lg-5 mx-auto">
							<div class="login-wrap password-form">

								<div class="login-header">
									<h3>{{ $t("Reset_Password") }}</h3>
								</div>

								<!-- Login Form -->
								<b-form action="login">
									<div class="form-group group-img">
										<div class="group-img">
											<i class="feather-mail"></i>
											<b-form-input  v-model="email" type="text" class="form-control" :placeholder="Email_placeholder" />
										</div>
										<br>
										<div class="group-img">
											<i class="feather-lock"></i>
											<b-form-input  v-model="password" :type="passwordType" class="form-control" :placeholder="password_placeholder" />
											<span
													class="toggle-password"
													@click="toggleShow"
													:class="{
											'feather-eye': showPassword,
											'feather-eye-off': !showPassword,
											}"
													style="font-family: 'feather' !important"
											></span>
										</div>
										<br>
										<div class="group-img">
											<i class="feather-lock"></i>
											<b-form-input  v-model="password_confirmation" :type="passwordType" class="form-control" :placeholder="password_confirm_placeholder" />

										</div>
									</div>

									<span v-for="error in errors" class="text-danger">{{ error[0] }}  <br></span>

									<b-button @click="reset" variant="primary w-100 login-btn" type="button">{{ $t("Change_Password") }}</b-button>
								</b-form>
								<!-- /Login Form -->

							</div>
						</div>
					</div>

				</div>
			</div>
							<foot />

							<scroll />
						</div>
				</div>


		<!-- /Privacy policy -->
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
	data() {
		return {
			title: this.$t("Reset_Password"),
			text: "Home",
			text1: "Reset Password",
			name: "/",
			passwordType:"password",
			password_placeholder:this.$t("password"),
			Email_placeholder:this.$t("email_address"),
			password_confirm_placeholder:this.$t("password_confirm_placeholder"),
			errors:[],
			message:''
		}
	},
	methods:{
		toggleShow() {
			this.showPassword = !this.showPassword
			if (this.passwordType === 'password') {
				this.passwordType = 'text'
			} else {
				this.passwordType = 'password'
			}
		},
		reset() {
			console.log(this.$route.params.code);
			let data = {
				email: this.email,
				password_confirmation:this.password_confirmation,
				password:this.password,
				token:this.$route.params.code

			}
			axios.post(`${process.env.VUE_APP_API_URL}reset-password-email`, data,{ headers: {
					'Accepted-Language': localStorage.getItem('lang')
				}}).then(res => {
				console.log(res);

				router.replace('/login')
			}).catch(err => {
				console.log( err.response.data.errors)
				this.errors= err.response.data.errors
			})
		}
	}
}
</script>