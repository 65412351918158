<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />
            
            <loginform />

            <foot />

            <scroll />
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            title: this.$t("Login"),
            text:this.$t("Home") ,
            text1: this.$t("Login"),
            name: "/",
        }
    },
}
</script>