<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">

                <index-banners />

<!--                <indexexplore />-->

                <indexcategory />

                <index-commercial-ads />

<!--                <index-products />-->
<!--                <indexfeatured />-->

                <index-latest-ads />
<!--                <indexpopular />-->

                <index-latest-auctions />

<!--                <indexcta />-->

<!--                <indexclient />-->

<!--                <indexpartners />-->

<!--                <indexpricing />-->

<!--                <indexblog />-->

                <foot />

                <scroll />
        </div>
    </div>
</template>

<script>
import IndexBanners from "@/views/pages/index/IndexBanners";
import IndexProducts from "@/views/pages/index/IndexProducts";
import IndexLatestAds from "@/views/pages/index/IndexLatestAds";
import IndexLatestAuctions from "@/views/pages/index/IndexLatestAuctions";
export default {
    components: {IndexBanners, IndexProducts, IndexLatestAds, IndexLatestAuctions}
}
</script>
