<template>
    <div class="card"> <!--  :class="isReply ? 'card mx-5' : 'card'" -->
        <div class="card-header d-block pb-2 mb-2">
            <div class="d-flex justify-content-between align-items-start">
                <div class="author-headings d-flex justify-content-start mb-3">
                    <div class="author-img">
                        <img :src="comment.user.image" alt="authorimg">
                    </div>
                    <div class="authordetails mx-2">
                        <h6>{{ comment.user.full_name }}</h6>
                        <p>{{comment.comment}}</p>
                        <div v-if="comment.gif" class="py-2">
                            <img :src="comment.gif.gif" class="gif-img" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <span class="small mx-2">{{ handleCommentDate() }}</span>
                    <div class="dropdown ">
                        <a href="#" class="profile-userlink" data-bs-toggle="dropdown"
                           aria-expanded="false">
                            ...
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <button v-if="authUser && comment.user.id !== authUser.id" class="dropdown-item" @click="reportComment(comment.id)">{{ $t('report') }}</button>
                            <button v-if="authUser && comment.user.id === authUser.id" class="dropdown-item" @click="deleteComment(comment.id)">{{ $t('delete') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-3">
                <i class="fa-solid fa-heart main-color fs-6"></i>
                <span class="small mx-1">{{comment.likes}} </span>
            </div>
        </div>
        <div class="card-body">
            <button class="btn" @click="likeComment(comment.id)">
                <i class="fa-solid fa-heart main-color" v-if="comment.is_like"></i><i class="fa-regular fa-heart" v-else></i> {{ $t('like') }}
            </button>
            <button v-if="comment.replies.length > 0" class="btn" @click="repliesShown = !repliesShown"><i class="fa-regular fa-comment"></i> {{ repliesShown ? $t('hide_replies') : $t('show_replies') }}</button>
        </div>
        <div v-if="repliesShown">
            <ad-comment
                :ad-id="adId"
                :is-reply="true"
                v-for="commentReply in comment.replies"
                :key="commentReply.id"
                :comment="commentReply"
                @update-comment-details="$emit('updateCommentDetails', commentReply)"
                @remove-comment="$emit('removeComment', commentReply, true)"
            />
        </div>
        <div class="my-3" v-if="!isReply">
            <div class="d-flex ">
                <div class="d-flex align-items-center w-100">
                    <input v-model="reply" class="form-control" type="text" :placeholder="$t('write_reply')" />
                    <comment-gif @handleSelectGif="handleSelectGif" />
                </div>
                <button class="btn btn-light-success d-flex align-items-center mx-2" @click="addComment(comment.id)"><i class="fa-regular fa-paper-plane"></i></button>
            </div>
            <div v-if="selectedGif" class="py-2 position-relative fit-content">
                <img :src="selectedGif.gif" class="gif-img" />
                <button class="btn btn-danger btn-sm rounded-circle position-absolute remove-gif d-flex" @click="selectedGif = null">
                    <span class="fa fa-close"></span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import CommentGif from "@/components/CommentGif";

export default {
    name: 'ad-comment',
    components: {CommentGif},
    props: {
        comment: {
            type: Object,
            required: true
        },
        adId: {
            type: Number,
            required: true
        },
        isReply: {
            type: Boolean,
            default: false
        }
    },
    emits:["updateCommentDetails", "removeComment"],
    data() {
        return {
            reply: '',
            repliesShown: false,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            date: '',
            selectedGif: null,
        }
    },
    methods: {
        handleSelectGif(gif) {
            this.selectedGif = gif
        },
        handleCommentDate() {
            let createdDate = new Date(this.comment.created_at).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()
            // get total seconds between the times
            var delta = Math.abs(currentDate - createdDate) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = delta % 60;  // in theory the modulus is not required

            if (days)
                return days + ` ${this.$t('d')} `
            else if (hours)
                return hours + ` ${this.$t('h')} `
            else if (minutes)
                return minutes + ` ${this.$t('m')} `
            else
                return seconds + ` ${this.$t('s')}`
        },
        addComment(parent_id) {
            if (this.reply || this.selectedGif) {

                let data = {
                    comment: this.reply,
                    parent_id: parent_id
                }

                if(this.selectedGif) {
                    data.gif_id = this.selectedGif.id
                }

                axios.post(`${process.env.VUE_APP_API_URL}user/ads/${this.adId}/comments`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    this.$swal.fire({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                    let newCommentData = this.comment
                    newCommentData['replies'].push(res.data.data.comment)
                    this.$emit('updateCommentDetails', newCommentData)
                    this.reply = ''
                    this.selectedGif = null
                }).catch(err => {
                    if (err.response.data.message === 'Unauthenticated') {
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('you_have_to_login'),
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    }
                })
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('comment_required'),
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            }
        },
        likeComment(id) {
            axios.put(`${process.env.VUE_APP_API_URL}user/comments/${id}/like`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(res => {
                let newCommentData = this.comment

                if(res.data.message === 'User Liked Comment') {
                    newCommentData['is_like'] = true
                    newCommentData['likes']++
                } else if (res.data.message === 'User Un Liked Comment') {
                    newCommentData['is_like'] = false
                    newCommentData['likes']--
                }
                this.$emit('updateCommentDetails', newCommentData)
            }).catch(err => {
                console.log(err)
                if (err.response.data.message === 'Unauthenticated') {
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('you_have_to_login'),
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        },
        deleteComment(id) {
            let deletedComment = this.comment
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('delete_comment'),
                text: this.$t('are_you_sure'),
                showDenyButton: true,
                confirmButtonText: this.$t('yes'),
                denyButtonText: this.$t('no')
            }).then(result => {
                if(result.isConfirmed) {

                    axios.delete(`${process.env.VUE_APP_API_URL}user/comments/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.$emit('removeComment', deletedComment, this.isReply)

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    }).catch(err => {
                        if (err.response.data.message === 'Unauthenticated') {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('you_have_to_login'),
                                showConfirmButton: false,
                                toast: true,
                                timer: 5000,
                                position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                            })
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                toast: true,
                                timer: 5000,
                                position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                            })
                        }
                    })
                }
            })
        },
        reportComment(id) {
            this.$swal.fire({
                title: this.$t('report_this_comment'),
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    required: true
                },
                showDenyButton: true,
                denyButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('report'),
                showLoaderOnConfirm: true,
                preConfirm: (reason) => {
                    let data = {
                        comment: reason,
                        target_type: 'review',
                        target_id: id
                    }
                    return axios.post(`${process.env.VUE_APP_API_URL}user/reports`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    })
                        .then(response => {
                            return response.data.message
                        })
                        .catch(error => {
                            this.$swal.showValidationMessage(
                                error
                            )
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire({
                        icon: 'success',
                        title: result.value,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        }
    }
}
</script>
