<template>
    <!-- Login Section -->
		<div class="login-content">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap password-form">

							<div class="login-header">
								<h3>{{ $t("Forgot_Password") }}</h3>
								<p>{{$t("Enter_your_email_and_we_will_send_you_a_link_to_reset_your_password")}}</p>
							</div>

							<!-- Login Form -->
							<b-form action="login">
								<div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-mail"></i>
										<b-form-input  v-model="email" type="text" class="form-control" :placeholder="Email_placeholder" />
									</div>
								</div>
								<div class="row">

									<span :hidden="hidden" v-for="error in errors" class="text-danger">{{ error[0]}}</span>
									<span  :hidden="!hidden" class="text-success text-center" id="confirm">{{ message}}</span>
									<span :hidden="hidden" class="text-danger">{{ exception}}</span>
								</div>
								<b-button @click="reset" variant="primary w-100 login-btn" type="button" :disabled="clicked">
									<b-spinner v-if="clicked" style="float: right;"></b-spinner>{{ $t("Submit") }}
								</b-button>
							</b-form>
							<router-link to="/" class="back-home"><i class="fas fa-regular fa-arrow-left me-1"></i> {{ $t("Back_to_Home") }}</router-link>
							<!-- /Login Form -->

						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- /Login Section -->
</template>

<script>
import axios from "axios";
import {router} from "@/router";

export default {
	data() {
		return {
			clicked:false,
			hidden:true,
			// hiddenSuccess:true,
			Forgot_Password:this.$t("Forgot_Password"),
			Back_to_Home:this.$t("Back_to_Home"),
			Submit:this.$t("Submit"),
			Email_placeholder:this.$t("email_address"),
			errors:[],
			message:'',
			exception:'',
			Enter_your_email_and_we_will_send_you_a_link_to_reset_your_password:this.$t("Enter_your_email_and_we_will_send_you_a_link_to_reset_your_password")
		}
	},
	methods:{
		reset() {
			let data = {
				email: this.email,

			}
			if (this.clicked) {
				return;
			}
			this.clicked = true;

			axios.post(`${process.env.VUE_APP_API_URL}forget-password-email`, data,{ headers: {
					'Accepted-Language': this.$i18n.locale
				}}).then(res => {
				console.log(res.data.message);
				// router.replace('/')
				this.clicked = false;
				this.message= res.data.message
				this.hidden=true
			}).catch(err => {
				console.log( err.response)
				this.clicked = false;
				// document.getElementById("confirm").setAttribute("hidden", this.clicked);
				this.hidden=false
				if(err.response.status==500){
					this.exception= err.response.data.message

				}else {
					this.errors= err.response.data.errors

				}
			})
		}
	}
}
</script>
