<template>
    <div class="card flex-fill">
        <div class="blog-widget">
            <div class="cursor-pointer" data-bs-toggle="modal" :data-bs-target="`#commercialModal-${ad.id}`">
                <img :src="ad.image" class="card-image w-100 rounded" alt="Commercial Image" loading="lazy" />
            </div>
            <div class="bloglist-content">
                <div class="card-body text-center">
                    <a :href="'tel:965'+ad.phone" target="_blank" class="btn btn-light m-1">
                        <i class="fa-solid fa-phone mx-1"></i> {{ $t('call') }}
                    </a>
                    <a :href="'https://wa.me/965'+ad.whatsapp" target="_blank" class="btn btn-light-success m-1">
                        <i class="fa-brands fa-whatsapp mx-1"></i> {{ $t('whatsapp') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="modal fade" :id="`commercialModal-${ad.id}`" tabindex="-1" role="dialog" aria-labelledby="commercialModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <img :src="ad.image" class="w-100" alt="Commercial Image" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        ad: {
            type: Object,
            required: true
        }
    },
}
</script>

