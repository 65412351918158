<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <div v-if="user">
                <profile-head :user="user" />

                <foot />

                <scroll />
            </div>
            <div v-else>
                <profile-skeleton />
                <scroll />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {router} from "@/router";
import ProfileHead from "@/views/pages/profile/ProfileHead";
import ProfileSkeleton from "@/components/profile/ProfileSkeleton";

export default {
    components: {
        ProfileHead,
        ProfileSkeleton
    },
    data() {
        return {
            user: null
        }
    },
    mounted() {
        this.getUser(this.$route.params.id)
    },
    methods: {
        getUser(id) {
            if(id) {
                axios.post(`${process.env.VUE_APP_API_URL}user/view/${id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    this.user = res.data.data.user
                }).catch(err => {
                    router.replace('/error-404')
                    // console.log(err.response)
                })
            } else {
                axios.get(`${process.env.VUE_APP_API_URL}profile`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    this.user = res.data.data.user_details
                    localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
                }).catch(err => {
                    router.replace('/error-404')
                    // console.log(err.response)
                })
            }
        }
    }
}
</script>
