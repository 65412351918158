<template>
    <div class="grid-view listgrid-sidebar">
        <div class="row" v-if="comments.length > 0">
            <div class="col-lg-6 col-md-6" v-for="comment in comments" :key="comment.id">
                <router-link :to="`/ads/${comment.target_id}/details`" target="_blank">
                    <div class="card">
                        <div class="d-block pb-2 mb-2">
                            <div class="d-flex justify-content-between align-items-start">
                                <div class="author-headings d-flex justify-content-start mb-3">
                                    <div class="author-img">
                                        <img :src="comment.user.image" alt="authorimg">
                                    </div>
                                    <div class="authordetails mx-2">
                                        <h6>{{ comment.user.full_name }}</h6>
                                        <p>{{comment.comment}}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <span class="small mx-2 text-dark">{{ handleCommentDate(comment.created_at) }}</span>
                                    <div class="dropdown ">
                                        <a href="#" class="profile-userlink" data-bs-toggle="dropdown"
                                           aria-expanded="false">
                                            ...
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <button v-if="authUser && comment.user.id === authUser.id" class="dropdown-item"
                                                    @click.prevent="deleteComment(comment.id)">{{ $t('delete') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-else-if="loading" class="text-center">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="text-center my-5 py-5">
            <h3>{{ $t('no_results') }}</h3>
        </div>
    </div>
    <div class="text-center">
        <button v-if="currentPage < totalPages" class="btn btn-primary" @click="handleLoadMore">
            {{ $t('load_more') }}
        </button>
    </div>
</template>
<script>

import axios from "axios";

export default {
    data() {
        return {
            comments: [],
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            loading: true,
            currentPage: 1,
            totalPages: 1,
            totalComments: 0,
            showing: 0,
            perPage: 15,
        }
    },
    mounted() {
        this.getComments()
    },
    methods: {
        handleCommentDate(createdAt) {
            let createdDate = new Date(createdAt).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()
            // get total seconds between the times
            var delta = Math.abs(currentDate - createdDate) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = delta % 60;  // in theory the modulus is not required

            if (days)
                return days + ` ${this.$t('d')} `
            else if (hours)
                return hours + ` ${this.$t('h')} `
            else if (minutes)
                return minutes + ` ${this.$t('m')} `
            else
                return seconds + ` ${this.$t('s')}`
        },
        getComments(more = false) {

            let url = `${process.env.VUE_APP_API_URL}user/comments?page=${this.currentPage}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(more) {
                    this.comments = [...this.comments, ...res.data.data.items]
                } else {
                    this.comments = res.data.data.items
                }
                this.totalPages = res.data.data.last_page
                this.totalComments = res.data.data.total
                this.showing = res.data.data.count
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleLoadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++

                this.getComments(true)
            } else {
                this.$swal.fire({
                    title: this.$t('no_more'),
                    icon: 'error',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
        deleteComment(id) {
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('delete_comment'),
                text: this.$t('are_you_sure'),
                showDenyButton: true,
                confirmButtonText: this.$t('yes'),
                denyButtonText: this.$t('no')
            }).then(result => {
                if(result.isConfirmed) {

                    axios.delete(`${process.env.VUE_APP_API_URL}user/comments/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.comments = this.comments.filter(comment => comment.id !== id)

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    }).catch(err => {
                        if (err.response.data.message === 'Unauthenticated') {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('you_have_to_login'),
                                showConfirmButton: false,
                                toast: true,
                                timer: 5000,
                                position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                            })
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                toast: true,
                                timer: 5000,
                                position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                            })
                        }
                    })
                }
            })
        },
    }
}
</script>
