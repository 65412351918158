<template>
    <!--Details Main  Section-->
    <div class="details-main-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="card " v-if="auction.description">
                        <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
                            <h4 class="mx-2">{{ $t('description') }}</h4>
                        </div>
                        <div class="card-body text-center">{{ auction.description }}</div>
                    </div>
                    <div class="card " v-if="auction.long_description">
                        <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
                            <h4 class="mx-2">{{ $t('description') }}</h4>
                        </div>
                        <div class="card-body" v-html="auction.long_description">
                        </div>
                    </div>

                    <!--Gallery Section-->
                    <div class="card gallery-section " v-if="auction.images.length > 0">
                        <div class="card-header ">
                            <img src="../../../../assets/img/galleryicon.svg" alt="gallery">
                            <h4 class="mx-2">{{ $t('gallery') }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="gallery-content">
                                <div class="row w-100">
                                    <div class="col-12 col-lg-3 col-md-3 col-sm-4" v-for="(img, index) in auction.images" :key="index"
                                         @click="() => showImg(index)">
                                        <div class="gallery-widget">
                                            <a data-fancybox="gallery1">
                                                <img height="205" width="205" alt="Image"
                                                     :src="img.url">
                                            </a>
                                        </div>
                                    </div>

                                    <vue-easy-lightbox
                                        :visible="visible"
                                        :imgs="imgs"
                                        :index="index"
                                        @hide="handleHide"
                                    ></vue-easy-lightbox>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card gallery-section " v-if="auction.video.length > 0">
                        <div class="card-header ">
                            <img src="../../../../assets/img/galleryicon.svg" alt="gallery">
                            <h4 class="mx-2">{{ $t('videos') }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="gallery-content">
                                <div class="row w-100">
                                    <div class="col-12 col-lg-3 col-md-3 col-sm-4" v-for="(vid, index) in auction.video" :key="index">
                                        <video width="200" height="200" controls>
                                            <source :src="vid.path">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card gallery-section ">
                        <div class="card-header ">
                            <img src="../../../../assets/icons/bids.png" width="25" alt="bids">
                            <h4 class="mx-2">{{ $t('bids') }} ({{ auction.bids_count }})</h4>
                        </div>
                        <div class="card-body ">
                            <div class="gallery-content">
                                <div class="row w-100 p-2">
                                    <auction-bid
                                        v-for="bid in bids"
                                        :key="bid.id"
                                        :auction-id="auction.id"
                                        :bid="bid"
                                        :auction-user-id="auction.user ? auction.user.id : null"
                                        @remove-bid="removeBid"
                                    />
                                </div>
                                <div v-if="!expired && !(auction.user && authUser && (auction.user.id === authUser.id))" class="row w-100 position-sticky bottom-0 m-2">
                                    <div class="card">
                                        <div class="card-body d-flex">
                                            <input v-model="bid" class="form-control" type="number" :min="auction.highest_bid ? +auction.highest_bid.price : auction.min_price" :placeholder="$t('add_bid')" />
                                            <button class="btn btn-light-success d-flex align-items-center mx-2" @click="addBid" :disabled="submitDisabled"><i class="fas fa-gavel"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/Gallery Section-->


                </div>
                <div class="col-lg-3 theiaStickySidebar">
                    <div class="stickysidebar">
                        <div class="rightsidebar">
                            <div v-if="auction.user && ((authUser && (auction.user.id !== authUser.id)) || !authUser)" class="card">
                                <h4><img src="../../../../assets/img/details-icon.svg" alt="details-icon"> <span class="mx-2">{{ $t('published_by') }}</span></h4>
                                <div class="author-headings d-flex justify-content-start align-items-center mb-3">
                                    <div class="author-img mx-0">
                                        <img :src="auction.user.image" alt="authorimg">
                                    </div>
                                    <div class="mx-2">
                                        <h5>{{ auction.user.full_name }}</h5>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <router-link :to="`/profile/${auction.user.id}`" class="btn btn-light d-flex mx-2" :title="$t('view_account')"><i class="fa-regular fa-eye"></i></router-link>
                                    <a v-if="!auction.additional_phone" :href="'https://wa.me/965'+auction.phone" target="_blank" class="btn btn-light-success d-flex" :title="$t('whatsapp')"><i class="fa-brands fa-whatsapp"></i></a>
                                    <div v-else>
                                        <button class="btn btn-light-success d-flex" :title="$t('whatsapp')" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-brands fa-whatsapp"></i></button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a :href="'https://wa.me/965'+auction.phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ auction.phone }}
                                            </a>
                                            <a :href="'https://wa.me/965'+auction.additional_phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ auction.additional_phone }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="!auction.user" class="card">
                                <h4><img src="../../../../assets/img/details-icon.svg" alt="details-icon"> <span class="mx-2">{{ $t('published_by') }}</span></h4>

                                <div class="d-flex justify-content-center align-items-center">
                                    <a v-if="!auction.additional_phone" :href="'https://wa.me/965'+auction.phone" target="_blank" class="btn btn-light-success d-flex" :title="$t('whatsapp')"><i class="fa-brands fa-whatsapp"></i></a>
                                    <div v-else>
                                        <button class="btn btn-light-success d-flex" :title="$t('whatsapp')" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-brands fa-whatsapp"></i></button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a :href="'https://wa.me/965'+auction.phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ auction.phone }}
                                            </a>
                                            <a :href="'https://wa.me/965'+auction.additional_phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ auction.additional_phone }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <h4><img src="../../../../assets/icons/timer.png" alt="details-icon"> <span class="mx-2">{{ $t('elapsed_time') }}</span></h4>
                                <div :class="expired ? 'text-danger text-center' : 'text-success text-center'">
                                    {{timerDays + ' ' + $t('d') + ' : ' + timerHours + ' ' + $t('h') + ' : ' + timerMinutes + ' ' + $t('m') + ' : ' + timerSeconds + ' ' + $t('s')}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Details Main Section -->
</template>

<script>

import axios from "axios";
import AuctionBid from "@/views/pages/auctions/view/AuctionBid";
import {router} from "@/router";

export default {
    props: {
        auction: {
            type: Object,
            required: true,
        }
    },
    components: {
        AuctionBid
    },
    data() {
        return {
            visible: false,
            index: 0, // default: 0
            imgs: null,
            amount: 1,
            bids: null,
            bid: '',
            replies: [],
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            // timerOutput: null,
            timerDays: 0,
            timerHours: 0,
            timerMinutes: 0,
            timerSeconds: 0,
            expired: false,
            intervalId: null,
            submitDisabled: false,
            isPhoneVerified: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).phone_verified : true,
        }
    },
    mounted() {
        if(!this.isPhoneVerified) {
            this.submitDisabled = true
            $('#verifyPhoneModal').modal('show')
        }

        this.imgs = this.auction.images.map(img => {
            return {
                src: img.url
            }
        })
        this.getBids()
        this.intervalId = setInterval(() => {
            if (!this.expired)
                this.startTimer()
        }, 1000);

    },
    watch: {
        expired(newValue) {
            if(newValue) {
                clearInterval(this.intervalId)
                this.$swal.fire({
                    icon: 'info',
                    title: this.$t('auction_expired'),
                    confirmButtonText: this.$t('ok'),
                    allowOutsideClick: false
                }).then(res => {
                    if (res.isConfirmed) {
                        router.replace('/auctions')
                    }
                })
            }
        }
    },
    methods: {
        showImg(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        getBids() {
            axios.get(`${process.env.VUE_APP_API_URL}auctions/${this.auction.id}/bids`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.bids = res.data.data.items
            }).catch(err => {
                console.log(err)
            })
        },
        addBid() {
            if (this.bid) {

                let data = {
                    price: this.bid
                }
                axios.post(`${process.env.VUE_APP_API_URL}user/auctions/${this.auction.id}/bids`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {

                    this.$swal.fire({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                    this.bids.unshift(res.data.data.auction.highest_bid)
                    this.bid = ''
                }).catch(err => {
                    if (err.response.data.message === 'Unauthenticated') {
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('you_have_to_login'),
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    } else {
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    }
                })
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('bid_required'),
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            }
        },
        removeBid(deletedBid) {
            this.bids = this.bids.filter(bid => bid.id !== deletedBid.id)
        },
        startTimer() {
            let expDate = new Date(this.auction.expiration_date).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()
            // get total seconds between the times
            var delta = Math.abs(expDate - currentDate) / 1000;
            if(((expDate - currentDate) / 1000) <= 0)
                this.expired = true

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = delta % 60;  // in theory the modulus is not required
            // this.timerOutput = days + ` ${this.$t('d')} : ` + hours + ` ${this.$t('h')} : ` + minutes + ` ${this.$t('m')} : ` + seconds + ` ${this.$t('s')}`
            this.timerDays = days
            this.timerHours = hours
            this.timerMinutes = minutes
            this.timerSeconds = seconds
        }
    },
}
</script>

