<template>
    <!--Details Description  Section-->
    <section class="details-description mt-5 profile-box">
        <div class="container">
            <div class="about-details">
                <div class="about-headings">
                    <div class="author-img">
                        <img :src="selectedUser.image" alt="authorimg">
                    </div>
                    <div class="authordetails mx-2">
                        <h5>{{ selectedUser.full_name }}</h5>
                        <h6 v-if="authUser && selectedUser.id === authUser.id">{{ selectedUser.phone }}</h6>
                        <p class="small">{{ selectedUser.views }} {{ $t('views') }}</p>
                        <div class="small d-flex align-items-center follow-box">
                            <h6 class="small cursor-pointer" data-bs-toggle="modal" data-bs-target="#followingsModal">{{ selectedUser.followings_count }} {{ $t('following') }}</h6>
                            <h6 class="mx-5 small cursor-pointer" data-bs-toggle="modal" data-bs-target="#followersModal">{{ selectedUser.followers_count }} {{ $t('followers') }}</h6>
<!--                            <h6 class="mx-5 small">{{ user.followers_count }} {{ $t('followers') }}</h6>-->
                        </div>
                        <p>{{ selectedUser.bio }}</p>
                    </div>
                </div>
                <div v-if="authUser && selectedUser.id !== authUser.id" :class="$i18n.locale === 'ar' ? 'rate-details text-start' : 'rate-details text-end'">
                    <button v-if="!selectedUser.is_followed_by_me" class="btn btn-sm btn-light-success mx-2" @click="toggleFollow(selectedUser.id)">{{ $t('follow') }}</button>
                    <button v-else class="btn btn-sm btn-secondary mx-2" @click="toggleFollow(selectedUser.id)">{{ $t('unfollow') }}</button>

                    <button class="btn btn-sm btn-danger-light" @click="reportUser(selectedUser.id)">{{ $t('report') }}</button>
                </div>
                <div v-else-if="authUser && selectedUser.id === authUser.id" :class="$i18n.locale === 'ar' ? 'rate-details text-start' : 'rate-details text-end'">
                    <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#walletModal">{{ $t('wallet') }}</button>
                </div>
<!--                <div class="rate-details" v-else>-->
<!--                    <h2 :class="$i18n.locale === 'ar' ? 'text-start' : 'text-end'">{{ product.price }} {{ $t('kwd') }}</h2>-->
<!--                </div>-->
            </div>
            <div class="descriptionlinks">
                <div class="row">
                    <div class="col-lg-12">
                        <b-tabs content-class="mt-3" fill pills lazy>
                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3" active>
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/man.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('account') }}</p>
                                    </div>
                                </template>
                                <hr class="mb-4" />
                                <profiledashboard :user="selectedUser" @updateUser="getUser" />
                            </b-tab>
                            <b-tab title-item-class="col-md-3 mb-3">
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/social-media.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('ads') }}</p>
                                    </div>
                                </template>
                                <hr class="mb-4" />
                                <profile-ads :user-id="selectedUser.id" />
                            </b-tab>
                            <b-tab title-item-class="col-md-3 mb-3">
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/growth.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('auctions') }}</p>
                                    </div>
                                </template>
                                <hr class="mb-4" />
                                <profile-auctions :user-id="selectedUser.id" />
                            </b-tab>
<!--                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3">-->
<!--                                <template #title>-->
<!--                                    <div class="d-flex flex-column justify-content-between align-items-center">-->
<!--                                        <img :src="require('@/assets/icons/shopping-bag.png')" loading="lazy" alt="" width="50" />-->
<!--                                        <p class="my-2">{{ $t('orders') }}</p>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                                <hr class="mb-4" />-->
<!--                                <profile-orders :user-id="selectedUser.id" />-->
<!--                            </b-tab>-->
                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3">
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/wishlist.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('wishlist') }}</p>
                                    </div>
                                </template>

                                <hr class="mb-4" />
                                <profile-wishlist :user-id="selectedUser.id" />
                            </b-tab>
                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3">
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/archive.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('archive') }}</p>
                                    </div>
                                </template>
                                <hr class="mb-4" />
                                <profile-archive :user-id="selectedUser.id" />
                            </b-tab>
                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3">
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/legal.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('bids') }}</p>
                                    </div>
                                </template>
                                <hr class="mb-4" />
                                <profile-bids />
                            </b-tab>
                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3">
                                <template #title>
                                    <div class="d-flex flex-column justify-content-between align-items-center">
                                        <img :src="require('@/assets/icons/communication.png')" loading="lazy" alt="" width="50" />
                                        <p class="my-2">{{ $t('comments') }}</p>
                                    </div>
                                </template>
                                <hr class="mb-4" />
                                <profile-comments />
                            </b-tab>
<!--                            <b-tab v-if="authUser && selectedUser.id === authUser.id" title-item-class="col-md-3 mb-3">-->
<!--                                <template #title>-->
<!--                                    <div class="d-flex flex-column justify-content-between align-items-center">-->
<!--                                        <img :src="require('@/assets/icons/placeholder.png')" loading="lazy" alt="" width="50" />-->
<!--                                        <p class="my-2">{{ $t('addresses') }}</p>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                                <hr class="mb-4" />-->
<!--                                <profile-addresses />-->
<!--                            </b-tab>-->
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <follow-modal
            id="followingsModal"
            type="followings"
            :user-id="selectedUser.id"
            :title="$t('following')"
            @updateUserDetails="getUser"
        />
        <follow-modal
            id="followersModal"
            type="followers"
            :user-id="selectedUser.id"
            :title="$t('followers')"
            @updateUserDetails="getUser"
        />
        <wallet-modal v-if="authUser && selectedUser.id === authUser.id" id="walletModal" :title="$t('wallet')" :wallet="selectedUser.wallet_amount" />
    </section>
    <!--/Details Description  Section-->
</template>
<script>
import axios from "axios";
import FollowModal from "@/components/profile/FollowModal";
import ProfileAds from "@/views/pages/profile/ProfileAds";
import ProfileAuctions from "@/views/pages/profile/ProfileAuctions";
import ProfileOrders from "@/views/pages/profile/ProfileOrders";
import ProfileWishlist from "@/views/pages/profile/ProfileWishlist";
import ProfileArchive from "@/views/pages/profile/ProfileArchive";
import ProfileComments from "@/views/pages/profile/ProfileComments";
import ProfileBids from "@/views/pages/profile/ProfileBids";
import ProfileAddresses from "@/views/pages/profile/ProfileAddresses";
import WalletModal from "@/components/profile/WalletModal";
import {router} from "@/router";
import { BTabs, BTab } from "bootstrap-vue-3";

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        FollowModal,
        BTabs,
        BTab,
        ProfileAds,
        ProfileAuctions,
        ProfileOrders,
        ProfileWishlist,
        ProfileArchive,
        ProfileComments,
        ProfileBids,
        ProfileAddresses,
        WalletModal
    },
    data() {
        return {
            selectedUser: this.user,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    methods: {
        getUser() {
            axios.get(`${process.env.VUE_APP_API_URL}profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.selectedUser = res.data.data.user_details
                localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
            }).catch(err => {
                router.replace('/error-404')
            })
        },
        toggleFollow(id) {
            axios.post(`${process.env.VUE_APP_API_URL}user/toggleFollow/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                // this.$swal.fire({
                //     title: this.$t('followed_successfully'),
                //     icon: 'success',
                //     toast: true,
                //     timer: 2000,
                //     position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                //     showConfirmButton: false,
                // })

                this.selectedUser = res.data.data.followed_user

            }).catch(err => {
                // router.replace('/error-404')
                console.log(err.response)
            })
        },
        reportUser(id) {
            this.$swal.fire({
                title: this.$t('report_this_user'),
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    required: true
                },
                showDenyButton: true,
                denyButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('report'),
                showLoaderOnConfirm: true,
                preConfirm: (reason) => {
                    let data = {
                        id: id,
                        comment: reason
                    }
                    return axios.post(`${process.env.VUE_APP_API_URL}user/report`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    })
                        .then(response => {
                            return response.data.message
                        })
                        .catch(error => {
                            this.$swal.showValidationMessage(
                                error
                            )
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('done'),
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
@media (max-width: 575px) {
    .follow-box {
        display: block !important;
    }

}
</style>
