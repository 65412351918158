export default  {
    state: () => ({ user: null, isLoggedIn: false }),
    mutations: {
        login(state, payload) {
            state.isLoggedIn = true
            state.user = payload
        },
        logout(state) {
            state.isLoggedIn = false
            state.user = null
        }
    },
    // getters: {
    //     isLoggedIn (state) {
    //         return state.isLoggedIn
    //     }
    // }
}
