<template>
    <!-- Login Section -->
		<div class="login-content">
			<div class="container">
				<div class="row">
					<div class="col-md-6 col-lg-5 mx-auto">
						<div class="login-wrap register-form">

							<div class="login-header">
								<h3>{{ $t("Create_an_Account") }}</h3>
								<p>{{ $t("Lets_start_with") }} <span>{{ $t("Mazad") }}</span></p>
							</div>

							<!-- Login Form -->

							<b-form >
							    <div class="form-group group-img">
								    <div class="group-img">
										<i class="feather-user"></i>
										<b-form-input type="text" v-model="first_name" class="form-control" :placeholder="First_Name_placeholder" required/>
									</div>
								</div>
								<div class="form-group group-img">
									<div class="group-img">
										<i class="feather-user"></i>
										<b-form-input v-model="last_name" type="text" class="form-control" :placeholder="Last_Name_placeholder" required/>
									</div>
								</div>
								<div class="form-group group-img">
									<div class="group-img">
										<i class="feather-mail"></i>
										<b-form-input v-model="email" type="email" class="form-control" :placeholder="Email_placeholder" required/>
									</div>
								</div>


								<div class="form-group">
									<div class="pass-group group-img">
										<span class="lock-icon"><i class="feather-lock"></i></span>
											<b-form-input
												v-if="showPassword"
												type="text"
												class="form-control pass-input"
												v-model="password"
												placeholder="password"
										/>
										<b-form-input v-else type="password"
											class="form-control pass-input"
											:placeholder="password_placeholder" v-model="password" required/>
										<span
											class="toggle-password"
											@click="toggleShow"
											:class="{
											'feather-eye': showPassword,
											'feather-eye-off': !showPassword,
											}"
                                            style="font-family: 'feather' !important"
										></span>
									</div>
								</div>
								<div class="form-group group-img">
									<div class="group-img">
										<i class="feather-user"></i>
										<b-form-input v-model="phone" type="text" class="form-control" :placeholder="placeholder_phone" required />
									</div>
								</div>
								<div class="row">
									<span v-for="error in errors" class="text-danger">{{ error[0]}}</span>

								</div>
								<b-button variant="primary w-100 login-btn" @click="handleSignup" type="button">{{ $t("Create_Account") }}</b-button>
								<div class="register-link text-center">
									<p>{{$t("Already_have_an_account?")}} <router-link class="forgot-link" to="/login">{{ $t("Sign_in") }}</router-link></p>
								</div>
								<div class="login-or">
									<span class="or-line"></span>
									<span class="span-or">{{ $t("Sign_in_with_Social_Media_Accounts") }}</span>
								</div>
<!--								<div class="social-login">-->
<!--									<a href="#" class="btn btn-apple w-100"><img src="../../../assets/img/apple.svg" class="me-1" alt="img">Sign in with Apple</a>-->
<!--								</div>-->
								<div class="social-login">
									<a @click="signup()" class="btn btn-google w-100"><img src="../../../assets/img/google.svg" class="me-1" alt="img">{{ $t("Sign_in_with_Google") }}</a>
								</div>
								<div class="social-login">
									<SocialAuth/>
<!--									<a href="#" class="btn btn-facebook w-100 mb-0"><img src="../../../assets/img/facebook.svg" class="me-2" alt="img">{{ $t("Continue_with_Facebook") }}</a>-->
								</div>
							</b-form>
							<!-- /Login Form -->

						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- /Login Section -->
</template>

<script>
import axios from 'axios'
import {router} from "@/router";
	export default {
		data() {
		 return {
			 first_name: null,
			 last_name: null,
			 phone: null,
			 email: null,
			showPassword: false,
		 	password: null,
			title01: "Profile",
		 	title2: "User Profile",
			showPassword2: false,
		 	password2: null,
			 errors:[],
			 placeholder_phone:this.$t("placeholder_phone"),
			 password_placeholder:this.$t("password"),
			 Email_placeholder:this.$t("email_address"),
			 Last_Name_placeholder:this.$t('Last_Name_placeholder'),
			 First_Name_placeholder:this.$t('First_Name_placeholder'),
		};
	},
	computed: {
		buttonLabel() {
			return this.showPassword ? "Hide" : "Show";
		},
	},
	methods: {
		toggleShow() {
		 	this.showPassword = !this.showPassword;
		},
		handleSignup() {
			let data = {
				first_name: this.first_name,
				last_name: this.last_name,
				email: this.email,
				phone: this.phone,
				password: this.password,
				password_confirmation: this.password,
				country_id:1
			}
			axios.post(`${process.env.VUE_APP_API_URL}register`, data, {
                headers: {
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
				localStorage.setItem('token', res.data.data.access_token)
				localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
				router.replace('/')
			}).catch(err => {
				// console.log(err.response.data.errors.email);
				this.errors = err.response.data.errors
			})
		}
	},
	}
</script>
<script setup>
import { googleTokenLogin } from "vue3-google-login"
import SocialAuth from '../../../../SocialAuth.vue'

const signup = () => {
	googleTokenLogin().then((response) => {
		// console.log("Handle the response", response)
		let data = {
			token: response.access_token,
			method_id: 2
		}
		axios.post(`${process.env.VUE_APP_API_URL}social/auth`, data).then(res => {
			localStorage.setItem('token', res.data.data.access_token)
			localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
			router.replace('/')
		}).catch(err => {
			this.error = err.response.data.message
		})
	})


}
</script>
