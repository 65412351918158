<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

            <auction-form form-type="edit" />

            <foot />

            <scroll />

        </div>
    </div>
</template>

<script>
import AuctionForm from "@/views/pages/auctions/form/AuctionForm";
export default {
    components: {
        AuctionForm
    },
    data() {
        return {
            title: this.$t('edit_auction'),
            text: this.$t('home'),
            text1: this.$t('edit_auction'),
            name: "/",
        }
    },
}
</script>
