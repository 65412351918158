<template>
    <div class="card">
        <div class="blog-widget">
            <div class="blog-img">
                <router-link :to="'/products/'+product.id+'/details'">
                    <img :src="product.image" class="card-image" alt="Product Image" loading="lazy" />
                </router-link>
                <div class="fav-item d-flex justify-content-between align-items-center">
                    <button
                        v-if="authUser"
                        :class="product.is_wishlist ? 'btn selected fav-icon' : 'btn fav-icon'" @click="handleToggleWishlist(product.id)">
                        <i class="feather-heart"></i>
                    </button>
                    <span class="Featured-text"
                          v-if="product.featured_end_date">{{ $t('featured') }}</span>
                </div>
            </div>
            <div class="bloglist-content">
                <div class="card-body">
                    <div class="blogfeaturelink">
                        <div class="blog-features">
                            <span>
                                <i class="fa-regular fa-circle-stop"></i> {{ product.category?.name }}
                            </span>
                        </div>
                    </div>
                    <h6>
                        <router-link :to="'/products/'+product.id+'/details'">{{ product.name }}</router-link>
                    </h6>
                    <div class="amount-details" v-if="product.discount_price">
                        <div class="amount">
                            <span class="validrate">{{ product.discount_price }} {{ $t('kwd') }} </span>
                            <span class="mx-1">{{ product.price }} {{ $t('kwd') }}</span>
                        </div>
                    </div>
                    <div class="amount-details" v-else>
                        <div class="amount">
                            <span class="validrate">{{ product.price }} {{ $t('kwd') }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    emits: ['updateProductInList'],
    methods: {
        handleToggleWishlist(id) {
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }
            let data = {
                target_id: id,
                target_type: 'product'
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/toggle-wishlist`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })

                this.$emit('updateProductInList', id, res.data.data.item.target)

            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>
