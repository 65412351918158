<template>
    <div class="grid-view listgrid-sidebar">
        <div class="row" v-if="products.length > 0">
            <div class="col-lg-3 col-md-3" v-for="item in products" :key="item.id">
                <product :product="item" @updateProductInList="handleUpdateProductInList" />
            </div>

        </div>
        <div v-else-if="loading" class="text-center">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="text-center my-5 py-5">
            <h3>{{ $t('no_results') }}</h3>
        </div>
    </div>
    <div class="text-center">
        <button v-if="currentPage < totalPages" class="btn btn-primary" @click="handleLoadMore">
            {{ $t('load_more') }}
        </button>
    </div>
</template>
<script>
import axios from "axios";
import Product from "@/components/Product";

export default {
    props: {
        userId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: 'wishlist'
        }
    },
    components: {
        Product
    },
    data() {
        return {
            products: [],
            loading: true,
            currentPage: 1,
            totalPages: 1,
            totalProducts: 0,
            showing: 0,
            perPage: 15,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    mounted() {
        this.getProductsForWishlist()
    },
    methods: {
        getProductsForWishlist(more = false) {
            let url = `${process.env.VUE_APP_API_URL}user/wishlist/product?page=${this.currentPage}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                let items
                if(res.data.data.items.length > 0) {
                    items = res.data.data.items.map(item => item.target)
                } else {
                    items = []
                }
                if(more) {
                    this.products = [...this.products, ...items]
                } else {
                    this.products = items
                }
                this.totalPages = res.data.data.last_page
                this.totalProducts = res.data.data.total
                this.showing = res.data.data.count
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleLoadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++

                this.getProductsForWishlist(true)
            } else {
                this.$swal.fire({
                    title: this.$t('no_more'),
                    icon: 'error',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
        handleUpdateProductInList(id, updatedProduct) {
            this.products = this.products.filter(product => product.id !== id)
        }
    }
}
</script>
