<template>
    <div class="grid-view listgrid-sidebar">
        <div class="row" v-if="ads.length > 0">
            <div class="col-lg-3 col-md-3" v-for="item in ads" :key="item.id">
                <ad :ad="item" @updateAdInList="handleUpdateAdInList" :type="type" />
            </div>

        </div>
        <div v-else-if="loading" class="text-center">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="text-center my-5 py-5">
            <h3>{{ $t('no_results') }}</h3>
        </div>
    </div>
    <div class="text-center">
        <button v-if="currentPage < totalPages" class="btn btn-primary" @click="handleLoadMore">
            {{ $t('load_more') }}
        </button>
    </div>
</template>
<script>
import axios from "axios";
import Ad from '@/components/Ad'

export default {
    props: {
        userId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: 'profile'
        }
    },
    components: {
        Ad
    },
    data() {
        return {
            ads: [],
            loading: true,
            currentPage: 1,
            totalPages: 1,
            totalAds: 0,
            showing: 0,
            perPage: 15,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    mounted() {

        if(this.type === 'wishlist') {
            this.getAdsForWishlist()
        } else if(this.type === 'archive') {
            this.getAdsForArchive()
        } else {
            this.getAds()
        }
    },
    methods: {
        getAds(more = false) {

            if(this.authUser && this.authUser.id === this.userId) {

                let url = `${process.env.VUE_APP_API_URL}user/ads?page=${this.currentPage}`

                axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    if(more) {
                        this.ads = [...this.ads, ...res.data.data.items]
                    } else {
                        this.ads = res.data.data.items
                    }
                    this.totalPages = res.data.data.last_page
                    this.totalAds = res.data.data.total
                    this.showing = res.data.data.count
                    this.perPage = res.data.data.per_page
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                    console.log(err)
                })
            } else {
                let url = `${process.env.VUE_APP_API_URL}ads?page=${this.currentPage}`

                let data = {
                    filters: {
                        customer_id: this.userId
                    }
                }

                axios.post(url, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    if(more) {
                        this.ads = [...this.ads, ...res.data.data.items]
                    } else {
                        this.ads = res.data.data.items
                    }
                    this.totalPages = res.data.data.last_page
                    this.totalAds = res.data.data.total
                    this.showing = res.data.data.count
                    this.perPage = res.data.data.per_page
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                    console.log(err)
                })
            }
        },
        getAdsForWishlist(more = false) {
            let url = `${process.env.VUE_APP_API_URL}user/wishlist/ad?page=${this.currentPage}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                let items
                if(res.data.data.items.length > 0) {
                    items = res.data.data.items.map(item => item.target)
                } else {
                    items = []
                }
                if(more) {
                    this.ads = [...this.ads, ...items]
                } else {
                    this.ads = items
                }
                this.totalPages = res.data.data.last_page
                this.totalAds = res.data.data.total
                this.showing = res.data.data.count
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        getAdsForArchive(more = false) {
            let url = `${process.env.VUE_APP_API_URL}user/archive-ads?page=${this.currentPage}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(more) {
                    this.ads = [...this.ads, ...res.data.data.items]
                } else {
                    this.ads = res.data.data.items
                }
                this.totalPages = res.data.data.last_page
                this.totalAds = res.data.data.total
                this.showing = res.data.data.count
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleLoadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++

                if(this.type === 'wishlist') {
                    this.getAdsForWishlist(true)
                } else if(this.type === 'archive') {
                    this.getAdsForArchive(true)
                } else {
                    this.getAds(true)
                }
            } else {
                this.$swal.fire({
                    title: this.$t('no_more'),
                    icon: 'error',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
        handleUpdateAdInList(id, updatedAd) {

            if(this.type === 'wishlist') {
                this.ads = this.ads.filter(ad => ad.id !== id)
            } else if(this.type === 'profile' || this.type === 'archive') {
                if(updatedAd) {
                    let adIndex = this.ads.map(ad => ad.id).indexOf(id)
                    this.ads[adIndex] = updatedAd
                } else {
                    this.ads = this.ads.filter(ad => ad.id !== id)
                }
            }
        }
    }
}
</script>
