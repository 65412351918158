<template>
  <div id="app" class="mt-5 pt-1">
<!--    <navbar />-->
    <router-view />

  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>


