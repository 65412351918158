import {createRouter, createWebHistory} from 'vue-router';

import Index from '../views/pages/index/index.vue'
import Error404 from '../views/pages/Error/error404/Error404.vue'
import Error500 from '../views/pages/Error/error500/Error500.vue'
import ForgotPass from '../views/pages/forgot-password/Password.vue'
import LoginPage from '../views/pages/Login/Login.vue'
import Privacy from '../views/pages/privacy-policy/PrivacyPolicy.vue'
import forgetPasswordLink from '../views/pages/forgot-password/forgetPasswordLink.vue'
import DeletePolicy from '../views/pages/privacy-policy/DeletePolicy.vue'
import Profile from '../views/pages/profile/Profile.vue'
import SignUp from '../views/pages/signup/Signup.vue'
import TermsAndConditions from '../views/pages/terms-conditions/TermsConditions.vue'
import Products from '../views/pages/products/Products'
import Product from "@/views/pages/products/view/Product";
import ViewThrough from "@/views/ViewThrough";
import Auctions from "@/views/pages/auctions/Auctions";
import Auction from "@/views/pages/auctions/view/Auction";
import Ads from "@/views/pages/ads/Ads";
import Ad from "@/views/pages/ads/view/Ad";
import Checkout from "@/views/pages/checkout/Checkout";
import AuctionCreate from "@/views/pages/auctions/create/AuctionCreate";
import AuctionEdit from "@/views/pages/auctions/edit/AuctionEdit";
import AdCreate from "@/views/pages/ads/create/AdCreate";
import AdEdit from "@/views/pages/ads/edit/AdEdit";
import CommercialAds from "@/views/pages/commercial-ads/CommercialAds";

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    // {
    //     path: '/index-2',
    //     name: 'index-2',
    //     component: IndexTwo
    // },
    // {
    //     path: '/index-3',
    //     name: 'index-3',
    //     component: IndexThree
    // },
    // {
    //     path: '/index-4',
    //     name: 'index-4',
    //     component: IndexFour
    // },
    // {
    //     path: '/index-five',
    //     name: 'index-five',
    //     component: IndexFive
    // },
    // {
    //     path: '/about',
    //     name: 'about',
    //     component: About
    // },
    // {
    //     path: '/add-listing',
    //     name: 'add-listing',
    //     component: Listing
    // },
    // {
    //     path: '/blog-details',
    //     name: 'blog-details',
    //     component: BlogDetail
    // },
    // {
    //     path: '/blog-grid-sidebar',
    //     name: 'blog-grid-sidebar',
    //     component: BlogGridSidebar
    // },
    // {
    //     path: '/blog-grid',
    //     name: 'blog-grid',
    //     component: blogGrid
    // },
    // {
    //     path: '/blog-list-sidebar',
    //     name: 'blog-list-sidebar',
    //     component: BlogListSidebar
    // },
    // {
    //     path: '/blog-list',
    //     name: 'blog-list',
    //     component: BlogList
    // },
    // {
    //     path: '/bookmarks',
    //     name: 'bookmarks',
    //     component: BookMark
    // },
    // {
    //     path: '/categories',
    //     name: 'categories',
    //     component: Categories
    // },
    // {
    //     path: '/contact',
    //     name: 'contact',
    //     component: Contact
    // },
    // {
    //     path: '/dashboard',
    //     name: 'dashboard',
    //     component: Dashboard
    // },
    {
        path: '/error-404',
        name: 'error-404',
        component: Error404
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500
    },
    // {
    //     path: '/faq',
    //     name: 'faq',
    //     component: Faq
    // },
    {
        path: '/forgot-password',
        name: 'Password',
        component: ForgotPass
    },
    // {
    //     path: '/gallery',
    //     name: 'gallery',
    //     component: Gallery
    // },
    // {
    //     path: '/howitworks',
    //     name: 'howitworks',
    //     component: HowItWorks
    // },
    // {
    //     path: '/listing-grid-sidebar',
    //     name: 'listing-grid-sidebar',
    //     component: ListingGridSidebar
    // },
    // {
    //     path: '/listing-grid',
    //     name: 'listing-grid',
    //     component: listGrid
    // },
    // {
    //     path: '/listing-list-sidebar',
    //     name: 'listing-list-sidebar',
    //     component: ListingSidebar
    // },
    // {
    //     path: '/listingmap-grid',
    //     name: 'listingmap-grid',
    //     component: ListMapGrid
    // },
    // {
    //     path: '/listingmap-list',
    //     name: 'listingmap-list',
    //     component: listListingMap
    // },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    // {
    //     path: '/messages',
    //     name: 'messages',
    //     component: Message
    // },
    // {
    //     path: '/my-listing',
    //     name: 'my-listing',
    //     component: MyListing
    // },
    // {
    //     path: '/pricing',
    //     name: 'pricing',
    //     component: PricingPage
    // },
    {
        path: '/forget-password-link/:code',
        name: 'Reset Password',
        component: forgetPasswordLink
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: Privacy
    },
    {
        path: '/delete-policy',
        name: 'delete-policy',
        component: DeletePolicy
    },
    {
        path: '/profile',
        name: 'profile',
        component: ViewThrough,
        children: [
            {
                path: '',
                component: Profile,
            },
            {
                path: ':id',
                component: Profile,
            }
        ]
    },
    // {
    //     path: '/reviews',
    //     name: 'reviews',
    //     component: Reviews
    // },
    // {
    //     path: '/service-details',
    //     name: 'service-details',
    //     component: Service
    // },
    {
        path: '/signup',
        name: 'Signup',
        component: SignUp
    },
    {
        path: '/terms-condition',
        name: 'terms-condition',
        component: TermsAndConditions
    },
    // {
    //     path: '/products',
    //     name: 'products',
    //     component: ViewThrough,
    //     children: [
    //         {
    //             path: ':category',
    //             component: Products,
    //         },
    //         {
    //             path: ':id/details',
    //             name: 'product-details',
    //             component: Product,
    //         }
    //     ]
    // },
    {
        path: '/auctions',
        component: ViewThrough,
        children: [
            {
                path: ':category',
                name: 'auctions',
                component: Auctions,
            },
            {
                path: ':id/details',
                name: 'auction-details',
                component: Auction,
            },
            {
                path: 'create',
                name: 'auction-create',
                component: AuctionCreate,
            },
            {
                path: ':id/edit',
                name: 'auction-edit',
                component: AuctionEdit,
            }
        ]
    },
    {
        path: '/ads',
        component: ViewThrough,
        children: [
            {
                path: ':category',
                name: 'ads',
                component: Ads,
            },
            {
                path: ':id/details',
                name: 'ad-details',
                component: Ad,
            },
            {
                path: 'create',
                name: 'ad-create',
                component: AdCreate,
            },
            {
                path: ':id/edit',
                name: 'ad-edit',
                component: AdEdit,
            }
        ]
    },
    {
        path: '/commercial-ads',
        component: ViewThrough,
        children: [
            {
                path: ':category',
                name: 'commercial-ads',
                component: CommercialAds,
            }
        ]
    },
    // {
    //     path: '/checkout',
    //     name: 'checkout',
    //     component: ViewThrough,
    //     children: [
    //         {
    //             path: '',
    //             component: Checkout,
    //         }
    //     ]
    // }

]

export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
});

