<template>
    <!--Details Main  Section-->
    <div class="details-main-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="card " v-if="ad.description">
                        <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
                            <h4 class="mx-2">{{ $t('description') }}</h4>
                        </div>
                        <div class="card-body text-center">{{ ad.description }}</div>
                    </div>
                    <div class="card " v-if="ad.long_description">
                        <div class="card-header">
									<span class="bar-icon">
									<span></span>
									<span></span>
									<span></span>
									</span>
                            <h4 class="mx-2">{{ $t('description') }}</h4>
                        </div>
                        <div class="card-body" v-html="ad.long_description">
                        </div>
                    </div>

                    <!--Gallery Section-->
                    <div class="card gallery-section " v-if="ad.images.length > 0">
                        <div class="card-header ">
                            <img src="../../../../assets/img/galleryicon.svg" alt="gallery">
                            <h4 class="mx-2">{{ $t('gallery') }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="gallery-content">
                                <div class="row w-100">
                                    <div class="col-12 col-lg-3 col-md-3 col-sm-4" v-for="(img, index) in ad.images" :key="index"
                                         @click="() => showImg(index)">
                                        <div class="gallery-widget">
                                            <a data-fancybox="gallery1">
                                                <img height="205" width="205" alt="Image"
                                                     :src="img.url">
                                            </a>
                                        </div>
                                    </div>

                                    <vue-easy-lightbox
                                        :visible="visible"
                                        :imgs="imgs"
                                        :index="index"
                                        @hide="handleHide"
                                    ></vue-easy-lightbox>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card gallery-section " v-if="ad.video.length > 0">
                        <div class="card-header ">
                            <img src="../../../../assets/img/galleryicon.svg" alt="gallery">
                            <h4 class="mx-2">{{ $t('videos') }}</h4>
                        </div>
                        <div class="card-body">
                            <div class="gallery-content">
                                <div class="row w-100">
                                    <div class="col-12 col-lg-3 col-md-3 col-sm-4" v-for="(vid, index) in ad.video" :key="index">
                                        <video width="200" height="200" controls>
                                            <source :src="vid.path">
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card gallery-section ">
                        <div class="card-header ">
                            <img src="../../../../assets/icons/comments.png" width="25" alt="comments">
                            <h4 class="mx-2">{{ $t('comments') }}</h4>
                        </div>
                        <div class="card-body ">
                            <div class="gallery-content">
                                <div class="row w-100 comments-container p-2">
                                    <ad-comment
                                        v-for="comm in comments"
                                        :key="comm.id"
                                        :comment="comm"
                                        :ad-id="ad.id"
                                        @update-comment-details="updateCommentDetails"
                                        @remove-comment="removeComment"
                                    />
                                </div>
                                <div class="row w-100 position-sticky bottom-0 m-2">
                                    <div class="card">
                                        <div class="card-body d-flex">
                                            <div class="d-flex align-items-center w-100">
                                                <input v-model="comment" class="form-control" :class="$i18n.locale === 'ar' ? 'ps-5' : 'pe-5'" type="text" :placeholder="$t('write_comment')" />
                                                <comment-gif @handleSelectGif="handleSelectGif" />
                                            </div>
                                            <button class="btn btn-light-success d-flex align-items-center mx-2" @click="addComment"><i class="fa-regular fa-paper-plane"></i></button>
                                        </div>
                                        <div v-if="selectedGif" class="py-2 position-relative fit-content">
                                            <img :src="selectedGif.gif" class="gif-img" />
                                            <button class="btn btn-danger btn-sm rounded-circle position-absolute remove-gif d-flex" @click="selectedGif = null">
                                                <span class="fa fa-close"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/Gallery Section-->


                </div>
                <div class="col-lg-3 theiaStickySidebar">
                    <div class="stickysidebar">
                        <div class="rightsidebar">
                            <div v-if="ad.user && ((authUser && (ad.user.id !== authUser.id)) || !authUser)" class="card">
                                <h4><img src="../../../../assets/img/details-icon.svg" alt="details-icon"> <span class="mx-2">{{ $t('published_by') }}</span></h4>
                                <div class="author-headings d-flex justify-content-start align-items-center mb-3">
                                    <div class="author-img mx-0">
                                        <img :src="ad.user.image" alt="authorimg">
                                    </div>
                                    <div class="mx-2">
                                        <h5>{{ ad.user.full_name }}</h5>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <router-link :to="`/profile/${ad.user.id}`" class="btn btn-light d-flex mx-2" :title="$t('view_account')"><i class="fa-regular fa-eye"></i></router-link>
                                    <a v-if="!ad.additional_phone" :href="'https://wa.me/965'+ad.phone" target="_blank" class="btn btn-light-success d-flex" :title="$t('whatsapp')"><i class="fa-brands fa-whatsapp"></i></a>
                                    <div v-else>
                                        <button class="btn btn-light-success d-flex" :title="$t('whatsapp')" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-brands fa-whatsapp"></i></button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a :href="'https://wa.me/965'+ad.phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ ad.phone }}
                                            </a>
                                            <a :href="'https://wa.me/965'+ad.additional_phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ ad.additional_phone }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="!ad.user" class="card">
                                <h4><img src="../../../../assets/img/details-icon.svg" alt="details-icon"> <span class="mx-2">{{ $t('published_by') }}</span></h4>
                                <div class="d-flex justify-content-center align-items-center">
                                    <a v-if="!ad.additional_phone" :href="'https://wa.me/965'+ad.phone" target="_blank" class="btn btn-light-success d-flex" :title="$t('whatsapp')"><i class="fa-brands fa-whatsapp"></i></a>
                                    <div v-else>
                                        <button class="btn btn-light-success d-flex" :title="$t('whatsapp')" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa-brands fa-whatsapp"></i></button>
                                        <div class="dropdown-menu dropdown-menu-end">
                                            <a :href="'https://wa.me/965'+ad.phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ ad.phone }}
                                            </a>
                                            <a :href="'https://wa.me/965'+ad.additional_phone" target="_blank" class="btn btn-light-success d-flex align-items-center m-1">
                                                <i class="fa-brands fa-whatsapp mx-1"></i> {{ ad.additional_phone }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Details Main Section -->
</template>

<script>

import axios from "axios";
import AdComment from "@/views/pages/ads/view/AdComment";
import CommentGif from "@/components/CommentGif";
// import {router} from "@/router";

export default {
    props: {
        ad: {
            type: Object,
            required: true,
        }
    },
    components: {
        CommentGif,
        AdComment,
    },
    data() {
        return {
            visible: false,
            index: 0, // default: 0
            imgs: null,
            amount: 1,
            comments: null,
            comment: '',
            replies: [],
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            selectedGif: null,
        }
    },
    watch: {
        gifSearch() {
            clearTimeout(this.gifSearchTimer)
            this.gifSearchTimer = setTimeout(() => {
                this.getGifs()
            }, 500)
        }
    },
    mounted() {
        this.imgs = this.ad.images.map(img => {
            return {
                src: img.url
            }
        })
        this.getComments()

    },
    methods: {
        showImg(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        getComments() {
            axios.get(`${process.env.VUE_APP_API_URL}user/ads/${this.ad.id}/comments`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.comments = res.data.data.items
            }).catch(err => {
                console.log(err)
            })
        },
        handleSelectGif(gif) {
            this.selectedGif = gif
        },
        addComment() {
            if (this.comment || this.selectedGif) {

                let data = {
                    comment: this.comment
                }

                if(this.selectedGif) {
                    data.gif_id = this.selectedGif.id
                }

                axios.post(`${process.env.VUE_APP_API_URL}user/ads/${this.ad.id}/comments`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    this.$swal.fire({
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                    this.comments.unshift(res.data.data.comment)
                    this.comment = ''
                    this.selectedGif = null
                }).catch(err => {
                    if (err.response.data.message === 'Unauthenticated') {
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('you_have_to_login'),
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    }
                })
            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('comment_required'),
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            }
        },
        updateCommentDetails(modifiedComment) {
            let commentIndex = this.comments.map(comm => comm.id).indexOf(modifiedComment.id)
            this.comments[commentIndex] = modifiedComment
        },
        removeComment(deletedComment, isReply = false) {
            if(isReply) {
                let parent = this.comments.filter(comm => comm.id === deletedComment.parent_id)[0]
                let parentIndex = this.comments.map(comm => comm.id).indexOf(deletedComment.parent_id)
                parent['replies'] = parent.replies.filter(reply => reply.id !== deletedComment.id)
                this.comments[parentIndex] = parent
            } else {
                this.comments = this.comments.filter(comm => comm.id !== deletedComment.id)
            }
        }
    },
}
</script>

