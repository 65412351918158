<template>
    <div class="grid-view listgrid-sidebar">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h6>{{ $t('addresses') }}</h6>
            <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#addressCreateModal">{{ $t('new_address') }}</button>
        </div>
        <div class="row" v-if="addresses.length > 0">
            <div class="col-lg-4 col-md-4" v-for="item in addresses" :key="item.id">
                <div class="card flex-fill">
                    <div class="d-flex justify-content-between align-items-center">
                        <div></div>
                        <div>
                            <button class="btn btn-sm btn-light-warning mx-1" data-bs-toggle="modal" :data-bs-target="`#addressEditModal-${item.id}`"><i class="fa-regular fa-pen-to-square"></i></button>
                            <button class="btn btn-sm btn-danger-light mx-1" @click="handleDeleteAddress(item.id)"><i class="fa-regular fa-trash-can"></i></button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-4">
                        <p class="flex-fill m-0">{{ $t('name') }}</p>
                        <p class="m-0">{{ item.name }}</p>
                    </div>
                    <hr class="my-2" />
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="flex-fill m-0">{{ $t('area') }}</p>
                        <p class="m-0">{{ item.area.name }}</p>
                    </div>
                </div>

                <address-form :id="`addressEditModal-${item.id}`" type="edit" :title="$t('edit_address')" :address="item" @updateAddressInList="handleUpdateAddressInList" />
            </div>

        </div>
        <div v-else-if="loading" class="text-center">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="text-center my-5 py-5">
            <h3>{{ $t('no_results') }}</h3>
        </div>
        <address-form id="addressCreateModal" type="create" :title="$t('new_address')" @addAddressInList="handleAddNewAddressInList" />
    </div>
</template>
<script>
import axios from "axios";
import AddressForm from "@/components/address/AddressForm";

export default {
    components: {
        AddressForm
    },
    data() {
        return {
            addresses: [],
            loading: true
        }
    },
    mounted() {
        this.getAddresses()
    },
    methods: {
        getAddresses() {

            axios.get(`${process.env.VUE_APP_API_URL}user/addresses`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.addresses = res.data.data.addresses
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleDeleteAddress(id) {
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('delete_address'),
                text: this.$t('are_you_sure'),
                showDenyButton: true,
                confirmButtonText: this.$t('yes'),
                denyButtonText: this.$t('no')
            }).then(result => {
                if(result.isConfirmed) {

                    axios.delete(`${process.env.VUE_APP_API_URL}user/addresses/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })

                        this.addresses = this.addresses.filter(address => address.id !== id)
                    }).catch(err => {
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })
                }
            })

        },
        handleAddNewAddressInList(newAddress) {
            this.addresses.push(newAddress)
        },
        handleUpdateAddressInList(oldAddressId, newAddress) {
            let addressIndex = this.addresses.map(add => add.id).indexOf(oldAddressId)
            this.addresses[addressIndex] = newAddress
        }
    }
}
</script>
