<template>
    <div :class="!isFilter ? 'col-lg-6 col-md-6' : ''">
        <div class="form-group">
            <label v-if="!isFilter" class="col-form-label">{{ isSubCategory ? $t('sub_category') : $t('category') }} <span class="text-danger">*</span></label>
            <div class="pass-group">
                <vue-select
                    v-model="category"
                    :options="categories"
                    label="name"
                    :reduce="opt => opt.id"
                    :placeholder="isSubCategory ? $t('choose_sub_category') : $t('choose_category')"
                    @option:selected="handleChangeCategory"
                />
            </div>
            <span class="text-danger mx-2">{{ error }}</span>
        </div>
    </div>
    <category-select
        v-if="subCategories.length > 0"
        @set-category="handleSetCategory"
        @set-filters="handleSetFilters"
        :categories="subCategories"
        :is-sub-category="true"
        :error="error"
        :is-filter="isFilter"
        :reset="reset"
        :parent-id="category"
    />
    <div v-if="categoryObj && categoryObj.filters && categoryObj.sub_categories.length === 0" :class="!isFilter ? 'row' : ''">

        <div
            v-for="filter in categoryObj.filters"
            :key="filter.id"
            :class="!isFilter ? 'col-lg-6 col-md-6' : ''"
        >
            <div class="form-group">
                <label v-if="!isFilter" class="col-form-label">{{ filter.name }}</label>
                <div class="pass-group">
                    <vue-select
                        :options="filter.attributes"
                        label="name"
                        :reduce="opt => opt.id"
                        :placeholder="filter.name"
                        :clearable="false"
                        @option:selected="(opt) => handleChangeFilter(opt, filter.id)"
                    />
                </div>
                <span class="text-danger mx-2">{{ error }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        categories: {
            type: Array,
            required: true
        },
        isSubCategory: {
            type: Boolean,
            required: false,
            default: false
        },
        error: {
            type: String,
            required: false
        },
        isFilter: {
            type: Boolean,
            required: false,
            default: false
        },
        reset: {
            type: Boolean,
            required: false,
            default: false
        },
        parentId: {
            type: [Number, null],
            required: false,
            default: null
        },
    },
    emits: ['setCategory', 'setFilters'],
    data() {
        return {
            category: null,
            parentCategory: this.parentId,
            subCategories: [],
            categoryObj: null
            // error: null
        }
    },
    watch: {
        parentId() {
            this.parentCategory = this.parentId
        },
        category() {
            if (!this.category) {
                this.category = null

                if(!this.isFilter)
                    this.handleSetCategory(this.category)
                else
                    this.handleSetCategory(this.category, this.isSubCategory, this.parentCategory)


                this.subCategories = []
                // this.error = this.$t('category_required')
                this.categoryObj = null
            } else {
                this.categoryObj = this.categories.filter(cat => cat.id === +this.category)[0]
            }
        },
        categories() {
            this.category = null
        },
        reset() {
            this.category = null
            this.parentCategory = null
            this.handleSetCategory(this.category, this.isSubCategory, this.parentCategory)
        }
    },
    methods: {
        handleSetCategory(id, isParent = false, parentId = null) {

            if(!this.isFilter)
                this.$emit('setCategory', id, isParent)
            else
                this.$emit('setCategory', id, isParent, parentId)

        },
        handleChangeCategory() {
            this.subCategories = this.categories.filter(cat => cat.id === this.category)[0]?.sub_categories
            if(!this.isFilter) {
                if(this.subCategories.length > 0) {
                    this.handleSetCategory(this.category, true)
                } else {
                    this.handleSetCategory(this.category, false)
                }
            } else {
                if(this.subCategories.length > 0) {
                    this.handleSetCategory(this.category, true, this.parentCategory)
                } else {
                    this.handleSetCategory(this.category, false, this.parentCategory)
                }
            }
        },
        handleSetFilters(attribute, filterId) {
            this.$emit('setFilters', attribute, filterId, this.category)
        },
        handleChangeFilter(opt, filterId) {
            this.$emit('setFilters', opt.id, filterId, this.category)
        }
    }
}
</script>
