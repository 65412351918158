<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <div v-if="ad">
                <ad-description v-if="ad" :ad="ad" />
                <ad-content v-if="ad" :ad="ad" />
                <foot />
                <scroll />
            </div>
            <div v-else>
                <details-page-skeleton />
                <scroll />
            </div>
        </div>

    </div>
</template>
<script>
import AdContent from "@/views/pages/ads/view/AdContent";
import AdDescription from "@/views/pages/ads/view/AdDescription";
import DetailsPageSkeleton from "@/components/DetailsPageSkeleton";

import axios from "axios";
import {router} from "@/router";
import Scroll from "@/views/pages/Scroll";
export default {
    components: {
        Scroll,
        AdContent,
        AdDescription,
        DetailsPageSkeleton
    },
    data() {
        return {
            ad: null
        }
    },
    mounted() {
        this.getAd(this.$route.params.id)
    },
    methods: {
        getAd(id) {
            axios.get(`${process.env.VUE_APP_API_URL}ads/view/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.ad = res.data.data.ad
            }).catch(err => {
                router.replace('/error-404')
            })
        }
    }
}
</script>

