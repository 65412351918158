<template>
    <div class="row categories-container header-navbar-rht">
        <div class="col-lg-2 col-md-3 col-sm-4 col-5">
            <div class="category-links d-flex flex-column align-items-center">
                <img class="img-responsive category-img placeholder"/>
                <h5 class="category-title mt-3 placeholder"></h5>
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-5">
            <div class="category-links d-flex flex-column align-items-center">
                <img class="img-responsive category-img placeholder"/>
                <h5 class="category-title mt-3 placeholder"></h5>
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-5">
            <div class="category-links d-flex flex-column align-items-center">
                <img class="img-responsive category-img placeholder"/>
                <h5 class="category-title mt-3 placeholder"></h5>
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-5">
            <div class="category-links d-flex flex-column align-items-center">
                <img class="img-responsive category-img placeholder"/>
                <h5 class="category-title mt-3 placeholder"></h5>
            </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-4 col-5">
            <div class="category-links d-flex flex-column align-items-center">
                <img class="img-responsive category-img placeholder"/>
                <h5 class="category-title mt-3 placeholder"></h5>
            </div>
        </div>
    </div>
    <div class="categories-container header-navbar-rht-opp">
        <div class="row flex-nowrap flex-md-wrap">
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
        </div>
        <div class="row flex-nowrap flex-md-wrap mt-2">
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
            <div class="category-mobile-box">
                <div class="category-links d-flex flex-column align-items-center">
                    <img class="img-responsive category-img placeholder"/>
                    <h5 class="category-title mt-3 placeholder"></h5>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>
img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dee2e6;
}
h4, h5, h6, p, li {
    width: 40%;
    margin-bottom: 10px;
    background-color: #dee2e6;
}
h4 {
    width: 100%;
}
span {
    width: 20%;
    margin-bottom: 10px;
    background-color: #dee2e6;
}
button {
    margin-block: 10px;
    min-width: 50px;
    background-color: #dee2e6;
}
</style>
