<template>
    <!--Details Description  Section-->
		<section class="details-description">
		    <div class="container">
			      <div class="about-details">
				     <div class="about-headings">
					      <div class="author-img">
						       <img src="../../../assets/img/profiles/avatar-10.jpg" alt="authorimg">
						  </div>
						  <div class="authordetails">
						       <h5>Sleep In a Room in Apartment</h5>
							   <p>Luxury hotel in the heart of Blommsbury</p>
							   <div class="rating">
									<i class="fas fa-star filled"></i>
									<i class="fas fa-star filled"></i>
									<i class="fas fa-star filled"></i>
									<i class="fas fa-star filled"></i>
									<i class="fa-regular fa-star rating-color"></i>
									<span class="d-inline-block average-rating"> 4.5 </span>
							   </div>
						  </div>
					 </div>
					 <div class="rate-details">
					     <h2>$350</h2>
						 <p>Fixed</p>
					 </div>				  
				  </div>
				  <div class="descriptionlinks">
					<div class="row">
						  <div class="col-lg-9">
							  <ul>
								  <li><a href="javascript:void(0);"><i class="feather-map"></i> Get Directions</a></li>
								  <li><a href="javascript:void(0);"><img src="../../../assets/img/website.svg" alt="website">Website</a></li>
								  <li><a href="javascript:void(0);"><i class="feather-share-2"></i> share</a></li>
								  <li><a href="javascript:void(0);"><i class="fa-regular fa-comment-dots"></i> Write a review</a></li>
								  <li><a href="javascript:void(0);"><i class="feather-flag"></i> report</a></li>
								  <li><a href="javascript:void(0);"><i class="feather-heart"></i> Save</a></li>
							  </ul>
						  </div>
						  <div class="col-lg-3">
							  <div class="callnow">
								  <router-link to="/contact"> <i class="feather-phone-call"></i>  Call Now</router-link>
							  </div>
						  </div>
					 </div>
				  </div>
			</div>		
		</section>
		<!--/Details Description  Section-->
</template>