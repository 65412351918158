<script>
import AuthButton from './AuthButton.vue';

export default {
  name: 'SocialAuth',
  components: {
    AuthButton,
  },
  data() {
    return {
      method: "login",
    };
  },
  methods: {
    changeMethod() {
      this.method = this.otherMethod;
    },
  },
  computed: {
    otherMethod() {
      return this.method === 'login' ? 'sign up' : 'login';
    },
  },
};
</script>

<template>

  <div class="social-auth">
    <AuthButton :method="method" company="facebook"/>
  </div>
<!--  <br>-->
<!--  <a v-on:click="changeMethod">Or {{otherMethod}}</a>-->
</template>

<style scoped>
  hr{
    margin-bottom: 50px
  }
  .social-auth{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
</style>
