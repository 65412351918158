<template>
    <div class="mainwrapper error-page">
        <div class="content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 mx-auto">
                        <div class="error-wrap">

                            <div class="error-logo">
                                <router-link to="/">
                                    <img class="my-2" height="250" width="250"
                                         src="../../../../assets/img/logo.png" alt="img">
                                </router-link>
                            </div>
                            <h2>Something went wrong</h2>
                            <div class="error-img">
                                <img class="img-fluid" src="../../../../assets/img/404-error.jpg" alt="img">
                            </div>
                            <router-link to="/" class="btn btn-primary rounded-pill">Back to Home</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
