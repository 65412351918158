<template>
    <div class="modal modals fade" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-between align-items-center mb-4">
                        <h6>{{ $t('current_balance') }}</h6>
                        <p>{{ wallet }} {{ $t('kwd') }}</p>
                    </div>
                    <div class="my-2">
                        <p>{{ $t('wallet_description') }}</p>
                    </div>
                    <div
                        v-if="paymentMethods.length > 0 && !loading"
                    >
                        <div
                            v-for="method in paymentMethods"
                            :key="method.id"
                            class="d-flex justify-content-between align-items-center my-4"
                        >
                            <label for="payment" class="d-flex justify-content-between align-items-center">
                                <img :src="method.image" width="50" class="mx-2" :alt="method.name" />
                                <h6 class="m-0">{{ method.name }}</h6>
                            </label>
                            <input v-model="paymentMethod" :value="method.id" type="radio" id="payment" name="payment" />
                        </div>
                        <input v-model="amount" type="number" step="1" class="form-control my-2" :placeholder="$t('amount')" />
                        <button class="btn btn-primary w-100" @click="handleAddToWallet" :disabled="submitLoading || submitDisabled">
                            <span v-if="!submitLoading">
                                {{ $t('add_to_wallet') }}
                            </span>
                            <span v-else class="text-center">
                                <span class="spinner-border text-white" role="status">
                                    <span class="sr-only">Loading...</span>
                                </span>
                            </span>
                        </button>
                    </div>
                    <div v-else-if="paymentMethods.length === 0 && loading" class="text-center">
                        <div class="spinner-border text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else class="text-center my-3">
                        <h6>{{ $t('no_results') }}</h6>
                    </div>
                </div>
                <!--                <div class="modal-footer">-->
                <!--                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
                <!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        wallet: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            paymentMethods: [],
            paymentMethod: null,
            amount: '',
            loading: true,
            submitLoading: false,
            submitDisabled: false,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            isPhoneVerified: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).phone_verified : true,
        }
    },
    emits: ['updateUserDetails'],
    mounted() {
        document.getElementById('walletModal').addEventListener('shown.bs.modal', () => {
            if(!this.isPhoneVerified) {
                this.submitDisabled = true
                $('#walletModal').modal('hide')
                $('#verifyPhoneModal').modal('show')
            }
            this.getPaymentMethods()
        })
    },
    methods: {
        getPaymentMethods() {
            this.paymentMethod = null
            axios.get(`${process.env.VUE_APP_API_URL}payment-methods`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.loading = false
                this.paymentMethods = res.data.data.payment_methods.filter(method => method.id !== 2)
            }).catch(err => {
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        },
        handleAddToWallet() {
            let data = {
                payment_method_id: this.paymentMethod,
                amount: this.amount
            }

            this.submitLoading = true

            axios.post(`${process.env.VUE_APP_API_URL}user/wallet`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                window.open(res.data.data.payment_url, '_blank')
                this.amount = ''
                this.paymentMethod = null
                this.submitLoading = false

                $('#walletModal').modal('hide')
            }).catch(err => {
                this.submitLoading = false
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        }
    }
}
</script>
