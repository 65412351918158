<template>
    <!--About Content-->
		<section class="about-content">
		    <div class="container">
		        <div class="about-listee">
					<div class="about-img">
						<img src="../../../assets/img/about-img.jpg" class="img-fluid" alt="">
					</div>	
					<div class="about-info">
						<h4> <span>About</span> Listee</h4>  
						<p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla fermentum malesuada pulvinar. Vestibulum laoreet rutrum semper. Vivamus malesuada, nisl in consectetur semper, mauris nulla aliquam risus, nec ultricies sapien elit sed ante. Aenean luctus felis in sem</p>
						<p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla fermentum malesuada pulvinar. Vestibulum laoreet rutrum semper. Vivamus malesuada, </p>
					</div>
			    </div>		
			</div>		
		</section>
		<!--/About Content-->
		
		<!--How It Works-->
		<section class="howitworks">
		    <div class="container">
		      <h3>How It Work</h3>
			  <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla fermentum malesuada pulvinar. Vestibulum laoreet rutrum semper. Vivamus malesuada, nisl in consectetur semper, mauris nulla aliquam risus, nec ultricies sapien elit sed ante. Aenean luctus felis in sem consequat auctor. Nulla turpis enim, scelerisque sit amet consectetur vel, lacinia sed augue. Proin ultricies dui id ex fringilla porta.</p>
              <p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
		      <div class="row">
			        <div class="col-lg-4 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>01</h5>
							<h6>Create Account</h6>
							<p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
						</div>					  
				    </div>
                    <div class="col-lg-4 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>02</h5>
							<h6>Post An Ad</h6>
							<p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
						</div>					  
				    </div>
                    <div class="col-lg-4 col-md-4 d-flex">
				        <div class="howitwork-info">
						    <h5>03</h5>
							<h6>Find,Buy & Own Dreams</h6>
							<p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
						</div>					  
				    </div>						
			  	</div>
            </div>	
		</section>
		<!--/How It Works-->
</template>