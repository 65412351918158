<template>
    <div class="modal modals fade" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t('verify_phone') }}</h5>
                    <!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div class="modal-body">
                    <div v-if="!loading">
                        <div v-if="!isChecked">
                            <input v-model="phone" type="number" class="form-control my-2" :placeholder="$t('phone')" />
                            <button class="btn btn-primary w-100" @click="checkPhone" :disabled="!phone">
                                <span v-if="!continueLoading">
                                    {{ $t('continue') }}
                                </span>
                                <span v-else class="text-center">
                                    <span class="spinner-border text-white" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                </span>
                            </button>
                        </div>
                        <div v-else>
                            <p class="text-success my-3">{{ $t('verification_code_sent') }}</p>
                            <input v-model="code" type="text" class="form-control my-2" :placeholder="$t('code')" />
                            <button class="btn btn-primary w-100" @click="handleVerifyPhone" :disabled="submitLoading">
                                <span v-if="!submitLoading">
                                    {{ $t('verify_phone') }}
                                </span>
                                <span v-else class="text-center">
                                    <span class="spinner-border text-white" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <span class="text-center">
                            <span class="spinner-border text-dark" role="status">
                                <span class="sr-only">Loading...</span>
                            </span>
                        </span>
                    </div>
                </div>
                <!--                <div class="modal-footer">-->
                <!--                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
                <!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
                <!--                </div>-->
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    data() {
        return {
            code: '',
            phone: '',
            isChecked: false,
            loading: true,
            continueLoading: false,
            submitLoading: false,
            user: null,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    mounted() {
        document.getElementById('verifyPhoneModal').addEventListener('shown.bs.modal', () => {
            if (this.authUser) {
                this.getPhone()
            }
        })
        document.getElementById('verifyPhoneModal').addEventListener('hidden.bs.modal', () => {
            this.loading = true
            this.isChecked = false
            this.code = ''
        })
    },
    methods: {
        getPhone() {
            axios.get(`${process.env.VUE_APP_API_URL}profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': localStorage.getItem('lang')
                }
            }).then(res => {
                this.user = res.data.data.user_details
                this.phone = res.data.data.user_details.phone
                this.loading = false
            }).catch(err => {
                console.log(err.response)
            })
        },
        checkPhone() {
            this.continueLoading = true
            if(this.phone !== this.user.phone) {

                let data = {
                    first_name: this.authUser.first_name,
                    last_name: this.authUser.last_name,
                    email: this.authUser.email,
                    phone: this.phone
                }
                axios.put(`${process.env.VUE_APP_API_URL}profile`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    this.continueLoading = false
                    this.isChecked = true

                    this.sendOtpCode()
                }).catch(err => {
                    this.continueLoading = false
                    this.isChecked = false

                    this.$swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })

                })
            } else {

                this.continueLoading = false
                this.isChecked = true

                this.sendOtpCode()
            }
        },
        sendOtpCode() {
            axios.get(`${process.env.VUE_APP_API_URL}check-phone-verification`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    customClass: {
                        container: 'swal-visibility'
                    },
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            }).catch(err => {
                $('#verifyPhoneModal').hide()
                $('.modal-backdrop').remove();

                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('already_verified'),
                    showConfirmButton: false,
                    customClass: {
                        container: 'swal-visibility'
                    },
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })

                axios.get(`${process.env.VUE_APP_API_URL}profile`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
                    window.location.reload()
                }).catch(err => {

                })
            })
        },
        handleVerifyPhone() {
            let data = {
                otp: this.code,
            }

            this.submitLoading = true

            axios.post(`${process.env.VUE_APP_API_URL}verify-phone`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    icon: 'success',
                    title: this.$t('done'),
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })

                this.code = ''
                this.submitLoading = false

                $('#verifyPhoneModal').modal('hide')

                axios.get(`${process.env.VUE_APP_API_URL}profile`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(result => {
                    localStorage.setItem('user', JSON.stringify(result.data.data.user_details))
                    window.location.reload()
                }).catch(err => {

                })

            }).catch(err => {
                this.submitLoading = false
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        }
    }
}
</script>
