<template>
    <section class="latestad-section grid-view featured-slider">
        <div class="container">
            <div class="section-heading">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <h2>{{ $t('latest_auctions') }}</h2>
                                <p>{{ $t('checkout_these_latest_auctions') }}</p>
                            </div>
                            <div>
                                <router-link to="/auctions/all" class="btn  btn-view">{{ $t('view_all') }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lateestads-content">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 d-flex" v-for="item in auctions" :key="item.id">
                        <auction :auction="item" @updateAuctionInList="handleUpdateAuctionInList" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from "axios";
import Auction from "@/components/Auction";

export default {
    data() {
        return {
            auctions: []
        };
    },
    components: {
        Auction
    },
    mounted() {
        this.getAuctions()
    },
    methods: {
        getAuctions() {
            axios.post(`${process.env.VUE_APP_API_URL}auctions?sold=false`,{}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.auctions = res.data.data.items
            }).catch(err => {
                console.log(err)
            })
        },
        handleExpirationDate(expirationDate) {
            let expDate = new Date(expirationDate).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()

            let delta = Math.abs(expDate - currentDate) / 1000;

            let days = Math.floor(delta / 86400);
            delta -= days * 86400;

            let hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            let minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // let seconds = delta % 60;

            return days + ` ${this.$t('d')} ` + hours + ` ${this.$t('h')} ` + minutes + ` ${this.$t('m')} `

        },
        handleUpdateAuctionInList(id, updatedAuction) {
            let auctionIndex = this.auctions.map(auction => auction.id).indexOf(id)
            this.auctions[auctionIndex] = updatedAuction
        }
    }
};
</script>
