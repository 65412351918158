<template>
    <!-- Profile Content -->
    <div class="dashboard-content">
        <div class="container">
            <div class="profile-content">
                <div class="messages-form">
                    <div class="card">
                        <div class="card-body">
                            <div class="row social-info">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label class="col-form-label">{{ $t('title') }} <span class="text-danger">*</span></label>
                                        <div class="pass-group">
                                            <input v-model="title" type="text" class="form-control">
                                        </div>
                                        <span class="text-danger mx-2" v-if="v$.title.$error"> {{ v$.title.$errors[0].$message }} </span>
                                    </div>
                                </div>
                                <div v-if="formType === 'edit' && oldCategory" class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label class="col-form-label">{{ $t('category') }} <span class="text-danger">*</span></label>
                                        <div class="pass-group group-img">
                                            <span class="category-name cursor-pointer" @click="handleResetCategory"><i class="feather-edit"></i></span>
                                            <input
                                                type="text"
                                                class="form-control"
                                                :value="oldCategory.name"
                                                readonly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <category-select
                                    v-if="!oldCategory"
                                    @set-category="handleSetCategory"
                                    @set-filters="handleSetFilters"
                                    :categories="categories"
                                    :error="v$.selectedCategory.$error ? v$.selectedCategory.$errors[0].$message : ''"
                                />
                            </div>
                            <div class="row social-info">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group formlast-input lat-input">
                                        <label class="col-form-label">{{ $t('price') }} <span class="text-danger">*</span></label>
                                        <div class="pass-group">
                                            <input v-model="price" type="number" class="form-control" min="1">
                                        </div>
                                        <span class="text-danger mx-2 d-block" v-if="v$.price.$error"> {{ v$.price.$errors[0].$message }} </span>
                                        <span class="text-danger mx-2 d-block" v-if="v$.price.$error"> {{ $t('min_value_0') }} </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label class="col-form-label">{{ $t('governorate') }} <span class="text-danger">*</span></label>
                                        <div class="pass-group select-box">
                                            <vue-select
                                                v-model="city"
                                                :options="cities"
                                                label="name"
                                                :reduce="opt => opt.id"
                                                :placeholder="$t('choose_governorate')"
                                                @option:selected="handleChangeCity"
                                                :clearable="false"
                                            />
                                        </div>
                                        <span class="text-danger mx-2" v-if="v$.city.$error"> {{ v$.city.$errors[0].$message }} </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label class="col-form-label">{{ $t('area') }}</label>
                                        <div class="pass-group select-box">
                                            <vue-select
                                                v-model="area"
                                                :options="areas"
                                                label="name"
                                                :reduce="opt => opt.id"
                                                :placeholder="$t('choose_area')"
                                                :clearable="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label class="col-form-label">{{ $t('block_number') }}</label>
                                        <div class="pass-group">
                                            <input v-model="block" type="text" class="form-control" @keypress="numbersOnly">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group formlast-input">
                                        <label class="col-form-label">{{ $t('phone') }} <span class="text-danger">*</span></label>
                                        <div class="pass-group">
                                            <input v-model="phone" type="number" step="1" class="form-control">
                                        </div>
                                        <span class="text-danger mx-2" v-if="v$.phone.$error"> {{ v$.phone.$errors[0].$message }} </span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group formlast-input">
                                        <label class="col-form-label">{{ $t('additional_phone') }}</label>
                                        <div class="pass-group">
                                            <input v-model="additionalPhone" type="number" step="1" class="form-control" @keypress="numbersOnly">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row social-info mt-5">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group formlast-input">
                                        <label class="col-form-label">{{ $t('description') }} <span class="text-danger">*</span></label>
                                        <div class="pass-group">
                                            <textarea v-model="description" rows="6" class="form-control"></textarea>
                                        </div>
                                        <span class="text-danger mx-2" v-if="v$.description.$error"> {{ v$.description.$errors[0].$message }} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card media-section">
                        <div class="card-header">
                            <h4>{{ $t('media') }} </h4>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 featured-img1">
                                        <h6 class="media-title">{{ $t('main_image') }} <span class="text-danger">*</span></h6>
                                        <div class="media-image">
                                            <img :src="image ?? imageUrl" alt="">
                                        </div>
                                        <div class="settings-upload-btn">
                                            <input type="file" accept="image/*" name="image" class="hide-input image-upload" id="image" @change="onFileChange">
                                            <label for="image" class="file-upload">{{ $t('upload_image') }}</label>
                                        </div>
                                        <span class="text-danger mx-2" v-if="!image"> {{ $i18n.locale === 'en' ? 'Required' : 'مطلوب' }} </span>
<!--                                        <span class="text-danger mx-2" v-if="v$.requestImage.$error"> {{ v$.requestImage.$errors[0].$message }} </span>-->
                                    </div>
                                </div>
                            </div>
                            <hr class="my-5">
                            <div class="gallery-media">
                                <h6 class="media-title">{{ $t('images') }} <span class="text-danger">*</span></h6>
                                <div v-if="images.length > 0" class="galleryimg-upload row">
                                    <div class="gallery-upload col-md-2 my-1" v-for="img in images" :key="img.id">
                                        <img :src="img.image" width="200" height="200" alt="">
                                        <button v-if="images.length > 1" class="profile-img-del btn" @click="handleDeleteImage(img.id)"><i class="feather-trash-2"></i></button>
                                    </div>
                                </div>
                                <div v-else class="text-center my-5">
                                    <h6>{{ $t('no_images') }}</h6>
                                </div>
                                <div class="settings-upload-btn">
                                    <input type="file" @change="onImagesSelect" accept="image/*" name="image" multiple class="hide-input image-upload" id="images">
                                    <label for="images" class="file-upload">{{ $t('upload_images') }}</label>
                                </div>
                                <span class="text-danger mx-2" v-if="requestImages.length === 0"> {{ $i18n.locale === 'en' ? 'Required' : 'مطلوب' }} </span>
                            </div>
                            <hr class="my-5">
                            <div class="gallery-media">
                                <h6 class="media-title">{{ $t('videos') }}</h6>
                                <div v-if="videos.length > 0" class="galleryimg-upload row">
                                    <div class="gallery-upload col-md-2 my-1" v-for="video in videos" :key="video.id">
                                        <video :id="'video-'+video.id" width="200" height="200" controls>
                                            <source :src="video.video">
                                        </video>
                                        <button class="profile-img-del profile-video-del btn" @click="handleDeleteVideo(video.id)"><i class="feather-trash-2"></i></button>
                                    </div>

                                </div>
                                <div v-else class="text-center my-5">
                                    <h6>{{ $t('no_videos') }}</h6>
                                </div>
                                <div class="settings-upload-btn">
                                    <input type="file" @change="onVideosSelect" accept="video/*" name="video" multiple class="hide-input image-upload" id="videos">
                                    <label for="videos" class="file-upload">{{ $t('upload_videos') }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="text-danger my-2 mx-2">-->
<!--                        {{ $t('review_content_message') }}-->
<!--                    </div>-->
                    <b-button variant="primary" type="submit" @click="handlePublish" :disabled="submitLoading || submitDisabled">
                        <span v-if="!submitLoading">
                            {{ $t('publish') }}
                        </span>
                        <span v-else class="text-center">
                            <span class="spinner-border text-white" role="status">
                                <span class="sr-only">Loading...</span>
                            </span>
                        </span>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
    <!-- /Profile Content -->
</template>

<script>
import axios from "axios";
import CategorySelect from "@/components/form/CategorySelect";

import useValidate from '@vuelidate/core'
import { required, minValue, numeric, helpers } from '@vuelidate/validators'
import {router} from "@/router";

const img1 = require(`@/assets/img/image.png`)
export default {
    components: {
        CategorySelect
    },
    props: {
        formType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            v$: useValidate(),
            title: '',
            category: null,
            selectedCategory: null,
            categories: [],
            cities: [],
            areas: [],
            city: '',
            area: '',
            block: '',
            price: '',
            phone: '',
            additionalPhone: '',
            description: '',
            image: null,
            requestImage: null,
            images: [],
            requestImages: [],
            videos: [],
            requestVideos: [],
            imageUrl: img1,
            submitDisabled: false,
            submitLoading: false,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            oldCategory: null,
            oldImages: [],
            oldVideos: [],
            oldThumbs: [],
            deletedImagesIds: [],
            deletedVideosIds: [],
            deletedThumbsIds: [],
            isPhoneVerified: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).phone_verified : true,
            attributeIds: {},
        };
    },
    validations() {
        return {
            title: { required: helpers.withMessage(this.$t('required'), required) },
            selectedCategory: { required: helpers.withMessage(this.$t('required'), required) },
            price: {
                required: helpers.withMessage(this.$t('required'), required),
                numeric: helpers.withMessage(this.$t('numeric'), numeric),
                minValue: minValue(0),
            },
            phone: {
                required: helpers.withMessage(this.$t('required'), required),
                numeric: helpers.withMessage(this.$t('numeric'), numeric)
            },
            // additionalPhone: {
            //     numeric: helpers.withMessage(this.$t('numeric'), numeric)
            // },
            description: { required: helpers.withMessage(this.$t('required'), required) },
            // requestImage: { required: helpers.withMessage(this.$t('required'), required) },
            city: { required: helpers.withMessage(this.$t('required'), required) },
            // area: { required: helpers.withMessage(this.$t('required'), required) },
        }
    },
    created() {
        this.phone = this.authUser.phone

        if (this.formType === 'edit') {
            this.getAd()
        }
        this.getCategories()
        this.getCities()
    },
    mounted() {
        if(!this.isPhoneVerified) {
            this.submitDisabled = true
            $('#verifyPhoneModal').modal('show')
        }
    },
    methods: {
        getCities() {
            axios.get(`${process.env.VUE_APP_API_URL}countries/1/cities`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.cities = res.data.data.items
            }).catch(err => {
                console.log(err.response)
            })
        },
        handleChangeCity() {
            this.getAreas()
        },
        getAreas(selectedArea = '') {
            axios.get(`${process.env.VUE_APP_API_URL}countries/1/cities/${this.city}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.areas = res.data.data.items
                this.area = selectedArea
            }).catch(err => {
                console.log(err.response)
            })
        },
        onFileChange(event) {
            const file = event.target.files[0];
            this.createBase64Image(file)
        },
        createBase64Image(fileObject, type = 'image', i = 0) {
            const reader = new FileReader()

            reader.onload = e => {
                if (type === 'image') {
                    this.requestImage = e.target.result
                    this.image = e.target.result
                }
                else if (type === 'images') {

                    this.requestImages.push({
                        id: i,
                        image: e.target.result
                    })
                    this.images.push({
                        id: i,
                        image: e.target.result
                    })
                }
            }
            reader.readAsDataURL(fileObject)
        },
        onImagesSelect(event) {
            const files = event.target.files

            let i = Math.floor(Math.random() * 9999999) + 1
            Array.from(files).forEach(file => {
                // this.images.push(URL.createObjectURL(file))
                this.createBase64Image(file, 'images', i)
                i++
            })

        },
        onVideosSelect(event) {
            const files = event.target.files

            let i = Math.floor(Math.random() * 9999999) + 1
            Array.from(files).forEach(file => {
                this.requestVideos.push({
                    id: i,
                    video: file
                })

                let vid = {
                    id: i,
                    video: URL.createObjectURL(file)
                }
                this.videos.push(vid)

                i++
            })

        },
        generateVideoThumb(video) {
            let w = video.videoWidth;
            let h = video.videoHeight;
            let canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            let dataURI = canvas.toDataURL('image/jpeg');

            return {
                canvas: canvas,
                file: this.dataURLtoFile(dataURI, `${+new Date()}.jpg`),
                base64: dataURI
            };
        },
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        handleDeleteImage(id) {
            if(this.formType === 'edit') {
                let imgExists = this.oldImages.filter(img => img.id === id)[0]
                if(imgExists) {
                    this.deletedImagesIds.push(id)
                }
            }
            this.requestImages = this.requestImages.filter(img => img.id !== id)
            this.images = this.images.filter(img => img.id !== id)
        },
        handleDeleteVideo(id) {
            if(this.formType === 'edit') {
                let vid = this.oldVideos.filter(vid => vid.id === id)[0]
                if(vid) {
                    this.deletedVideosIds.push(id)
                    this.deletedThumbsIds.push(vid.thumb_id)
                }
            }
            this.videos = this.videos.filter(vid => vid.id !== id)
            this.requestVideos = this.requestVideos.filter(vid => vid.id !== id)
        },
        getAd() {
            axios.get(`${process.env.VUE_APP_API_URL}user/ads/${this.$route.params.id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                let ad = res.data.data.ad
                this.title = ad.title
                this.category = ad.category.id
                this.selectedCategory = ad.category.id
                this.oldCategory = ad.category
                this.description = ad.description
                this.price = ad.price
                this.city = ad.city.id
                this.getAreas(ad.area ? ad.area.id : '')
                this.block = ad.block
                this.phone = ad.phone
                this.additionalPhone = ad.additional_phone
                this.image = ad.image
                this.images = ad.images.map(img => ({id: img.id, image: img.url}))
                this.videos = ad.video.map(vid => ({id: vid.id, video: vid.path}))
                this.oldImages = ad.images
                this.oldVideos = ad.video

            }).catch(err => {
                // console.log(err)
                router.replace('/error-404')
            })
        },
        getCategories() {
            axios.get(`${process.env.VUE_APP_API_URL}categories?type=1`, {
                headers: {
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.categories = res.data.data.items

            }).catch(err => {
                console.log(err)
            })
        },
        handleSetCategory(id, isParent) {
            this.attributeIds = {}
            if(isParent) {
                // delete this.attributeIds[this.category]
                this.category = id
                this.selectedCategory = null
                // this.attributeIds = {}
            } else {
                // delete this.attributeIds[this.selectedCategory]
                this.selectedCategory = id
            }

        },
        handleSetFilters(attribute, filterId, categoryId) {
            if(categoryId in this.attributeIds) {
                this.attributeIds[categoryId][filterId] = attribute
            } else {
                this.attributeIds[categoryId] = {
                    [filterId]: attribute
                }
            }

        },
        handleResetCategory() {
            this.category = null
            this.selectedCategory = null
            this.oldCategory = null
            this.attributeIds = {}
        },
        handlePublish() {
            this.v$.$validate() // checks all inputs
            if (!this.v$.$error && ((this.formType === 'create' && this.requestImages.length > 0) || (this.formType === 'edit' && this.oldImages.length > 0)) && this.image) {
                this.submitLoading = true

                let videosThumb = []
                this.requestVideos.forEach(vid => {
                    let videoElement = document.getElementById('video-' + vid.id)
                    videosThumb.push(this.generateVideoThumb(videoElement).file)
                })

                let data = new FormData()

                data.append('title', this.title)
                data.append('description', this.description)
                data.append('category_id', this.selectedCategory)
                data.append('city_id', this.city)
                if(this.area)
                    data.append('area_id', this.area)
                if(this.block)
                    data.append('block', this.block)
                data.append('phone', this.phone)
                data.append('additional_phone', this.additionalPhone)
                data.append('price', this.price)
                data.append('country_id', 1)

                if(this.requestImage)
                    data.append('image', this.requestImage)

                for (let i = 0; i < this.requestImages.length; i++) {
                    data.append('images[]', this.requestImages[i].image);
                }
                for (let i = 0; i < this.requestVideos.length; i++) {
                    data.append('video[]', this.requestVideos[i].video);
                }
                for (let i = 0; i < videosThumb.length; i++) {
                    data.append('thumb[]', videosThumb[i]);
                }

                if (this.attributeIds.length > 0) {
                    let filters = Object.values(this.attributeIds)[0]
                    let attributes = Object.values(filters)

                    attributes.forEach(attribute => {
                        data.append('attribute_ids[]', attribute);
                    })
                }

                if (this.formType === 'edit') {
                    for (let i = 0; i < this.deletedImagesIds.length; i++) {
                        data.append('deleted_images_ids[]', this.deletedImagesIds[i]);
                    }
                    for (let i = 0; i < this.deletedVideosIds.length; i++) {
                        data.append('deleted_videos_ids[]', this.deletedVideosIds[i]);
                    }
                    for (let i = 0; i < this.deletedThumbsIds.length; i++) {
                        data.append('deleted_thumbs_ids[]', this.deletedThumbsIds[i]);
                    }

                    axios.post(`${process.env.VUE_APP_API_URL}user/ads/${this.$route.params.id}?_method=PUT`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.submitLoading = false

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            confirmButtonText: this.$t('ok'),
                            allowOutsideClick: false
                        }).then(result => {
                            router.push(`/ads/${this.$route.params.id}/details`)
                        })

                    }).catch(err => {
                        this.submitLoading = false
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })
                } else {

                    axios.post(`${process.env.VUE_APP_API_URL}user/ads`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.submitLoading = false

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            confirmButtonText: this.$t('ok'),
                            allowOutsideClick: false
                        }).then(result => {
                            router.push('/ads/all')
                        })

                    }).catch(err => {
                        this.submitLoading = false
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })
                }

            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('form_validation_failed'),
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            }
        },
        numbersOnly(evt) {
            evt = evt || window.event;
            const charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
};
</script>
