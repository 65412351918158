<template>
    <div class="login-content">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-5 mx-auto">
                    <div class="login-wrap">

                        <div class="login-header">
                            <h3>{{ $t("Welcome_Back") }}</h3>
                            <p>{{ $t("Please_Enter_your_Details") }}</p>
                        </div>

                        <!-- Login Form -->
                        <b-form>
                            <div class="form-group group-img">
                                <div class="group-img">
                                    <i class="feather-mail"></i>
                                    <b-form-input v-model="email" type="email" class="form-control"
                                                  :placeholder="Email_placeholder" required/>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="pass-group group-img">
                                    <span class="lock-icon"><i class="feather-lock"></i></span>
                                    <b-form-input
                                        :type="passwordType"
                                        class="form-control pass-input"
                                        v-model="password"
                                        :placeholder="password_placeholder"
                                        required
                                    />
                                    <span
                                        class="toggle-password"
                                        @click="toggleShow"
                                        :class="{
											'feather-eye': showPassword,
											'feather-eye-off': !showPassword,
											}"
                                        style="font-family: 'feather' !important"
                                    ></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <label class="custom_check">
                                        <input v-model="rememberMe" type="checkbox" name="rememberme" class="rememberme">
                                        <span class="checkmark"></span>{{ $t("Remember_Me") }}
                                    </label>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="text-md-end">
                                        <router-link class="forgot-link" to="forgot-password">{{ $t("Forgot_password?") }}
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <span  :hidden="!hidden" v-for="error in errors" class="text-danger">{{ error[0]}}</span>
                                <span :hidden="hidden" class="text-danger">{{ exception}}</span>

                            </div>
                            <b-button variant="primary w-100 login-btn" @click="handleLogin" type="submit">{{ $t("Sign_in") }}</b-button>
                            <div class="register-link text-center">
                                <p>{{ $t("No_account_yet?") }}
                                    <router-link class="forgot-link" to="/signup">{{ $t("Signup") }}</router-link>
                                </p>
                            </div>
                            <div class="login-or">
                                <span class="or-line"></span>
                                <span class="span-or">{{$t("Sign_in_with_Social_Media_Accounts")}}</span>
                            </div>
                            <!--              <div class="social-login">-->
                            <!--                <a href="#" class="btn btn-apple w-100"><img src="../../../assets/img/apple.svg" class="me-1" alt="img">Sign-->
                            <!--                  in with Apple</a>-->
                            <!--              </div>-->
                            <div class="social-login">

<!--                                <button @click="login">Login Using Google</button>-->


                                <!--                                <a href="http://localhost:8000/api/login/google" class="btn btn-google w-100"><img src="../../../assets/img/google.svg"-->
<!--                                                                              class="me-1"-->
<!--                                                                              alt="img">{{$t("Sign_in_with_Google")}}</a>-->

                                <b-button class="btn btn-google w-100" @click="login()"><img src="../../../assets/img/google.svg"
                                                                                                       class="me-1"
                                                                                                       alt="img">{{$t("Sign_in_with_Google")}}</b-button>

                            </div>

                            <div class="social-login">
                                <SocialAuth/>
<!--                                <div class="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="false"></div>-->
<!--                                <a href="http://localhost:8000/api/social/auth/redirect/facebook" class="btn btn-facebook w-100 mb-0"><img-->
<!--                                    src="../../../assets/img/facebook.svg" class="me-2"-->
<!--                                    alt="img">{{$t("Continue_with_Facebook")}}</a>-->
                            </div>
                        </b-form>
                        <!-- /Login Form -->

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {router} from "@/router"
export default {
    name: "app",
    data() {
        return {
            email: null,
            password: null,
            rememberMe: false,
            password_placeholder:this.$t("password"),
            Email_placeholder:this.$t("email_address"),
            showPassword: false,
            passwordType: 'password',
            errors:[],
            isConnected: false,
            name: '',
            personalID: '',
            FB: undefined,
            hidden:true,
            exception:''
        };
    },
    computed: {
        buttonLabel() {
            return this.showPassword ? "Hide" : "Show";
        },
    },
    mounted() {
        let rememberMeCookie = this.$cookies.get('rememberMe');
        if (rememberMeCookie === 'true') {
            this.rememberMe = true
            this.email = this.$cookies.get('email')
            this.password = this.$cookies.get('password')
        }
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword
            if (this.passwordType === 'password') {
                this.passwordType = 'text'
            } else {
                this.passwordType = 'password'
            }
        },
        handleLogin(e) {
            e.preventDefault()
            let data = {
                email: this.email,
                password: this.password,

            }
            this.hidden=true
            axios.post(`${process.env.VUE_APP_API_URL}login`, data,{ headers: {
                    'Accepted-Language': this.$i18n.locale
                }}).then(res => {
                localStorage.setItem('token', res.data.data.access_token)
                localStorage.setItem('user', JSON.stringify(res.data.data.user_details))

                if (this.rememberMe) {
                    this.$cookies.set('rememberMe', 'true', '1y' );
                    this.$cookies.set('email', this.email, '1y');
                    this.$cookies.set('password', this.password, '1y');
                } else {
                    this.$cookies.set('rememberMe', 'false');
                    this.$cookies.set('email', '');
                    this.$cookies.set('password', '');
                }

                this.handleGetCart()

                router.replace('/')
            }).catch(err => {

                if(err.response.status!==422){
                    this.hidden=false
                    console.log( err.response.status)
                    this.errors=[]
                    this.exception= err.response.data.message
                    }else {
                    this.exception=''
                this.errors= err.response.data.errors
                    }
            })
        },
        handleGetCart() {
            axios.get(`${process.env.VUE_APP_API_URL}user/cart`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {

                res.data.data.cart.items.forEach(item => {
                    this.$store.commit('addToCart', {
                        product: item.product,
                        quantity: item.quantity
                    })
                })
            }).catch(err => {
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        },
        SocialLogin(provider) {

            axios.get(`${process.env.VUE_APP_API_URL}login/`+provider).then(res => {
                // localStorage.setItem('token', res.data.data.access_token)
                // localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
                // router.replace('/')
            }).catch(err => {
                this.error = err.response.data.message
            })

        },
        getUserData() {
            this.FB.api('/me', 'GET', { fields: 'id,name,email' },
                userInformation => {
                    console.warn("data api",userInformation)
                    this.personalID = userInformation.id;
                    this.email = userInformation.email;
                    this.name = userInformation.name;
                }
            )
        },
        sdkLoaded(payload) {
            this.isConnected = payload.isConnected
            this.FB = payload.FB
            if (this.isConnected) this.getUserData()
        },
        onLogin() {
            this.isConnected = true
            this.getUserData()
        },
        onLogout() {
            this.isConnected = false;
        },
        // SocialLogin(provider,response){
        //     axios.post(`${process.env.VUE_APP_API_URL}sociallogin/`+provider,response).then(response => {
        //     // this.$process.env.VUE_APP_API_URL.post('/sociallogin/'+provider,response).then(response => {
        //
        //         console.log(response.data)
        //
        //     })
        //     //     .catch(err => {
        //     //     console.log({err:err})
        //     // })
        // },


    },
};
</script>

<script setup>

import { googleTokenLogin } from "vue3-google-login"
import SocialAuth from '../../../../SocialAuth.vue'

const login = () => {
    googleTokenLogin().then((response) => {
        // console.log("Handle the response", response)
        let data = {
            token: response.access_token,
            method_id: 2
        }
        axios.post(`${process.env.VUE_APP_API_URL}social/auth`, data).then(res => {
            localStorage.setItem('token', res.data.data.access_token)
            localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
            router.replace('/')
        }).catch(err => {
            this.error = err.response.data.message
        })
    })


}
</script>
