<template>
    <!-- Popular Location Section -->
    <section class="popular-locations">
        <div class="popular-circleimg">
            <img class="img-fluid" src="../../../assets/img/popular-img.png" alt="Popular-sections">
        </div>
        <div class="container">
            <div class="section-heading">
                <h2>{{ $t('latest_ads') }}</h2>
                <p>{{ $t('checkout_these_latest_ads') }}</p>
            </div>
            <div class="location-details">
                <div class="row">
                    <div
                        v-for="item in ads"
                        :key="item.id"
                        class="location-info col-lg-4 col-md-6"
                    >
                        <index-ad :ad="item" />
<!--                        <div class="location-info-details d-flex align-items-center index-ad-box">-->
<!--                            <div class="location-img">-->
<!--                                <router-link :to="'/ads/'+item.id+'/details'"><img :src="item.image" width="100" height="100" alt="ads"></router-link>-->
<!--                            </div>-->
<!--                            <div class="location-content">-->
<!--                                <h6 :id="`title-header-${item.id}`"><router-link :to="'/ads/'+item.id+'/details'" class="direction-auto">{{ item.title }}</router-link></h6>-->
<!--                                <p>{{ item.category.name }}</p>-->
<!--                                <span class="main-color small">{{ item.price }} {{ $t('kwd') }} </span>-->
<!--                                <router-link :to="'/ads/'+item.id+'/details'" class="view-detailsbtn">{{ $t('view_details') }}</router-link>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>

                </div>
            </div>
            <div class="align-items-center"><router-link to="/ads/all" class="browse-btn">{{ $t('view_all') }}</router-link></div>
        </div>
    </section>
    <!-- /Popular Locations Section -->
</template>

<script>
import axios from "axios";
import IndexAd from "@/components/IndexAd";

export default {
    data() {
        return {
            ads: []
        }
    },
    components: {
        IndexAd
    },
    mounted() {
        this.getAds()

    },
    methods: {
        getAds() {
            axios.post(`${process.env.VUE_APP_API_URL}ads?sold=false`,{}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.ads = res.data.data.items

            }).catch(err => {
                console.log(err)
            })
        }
    },
}
</script>
