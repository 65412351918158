<template>
    <div v-if="banners.length > 0" class="pt-5 banners-box" :class="type == 1 ? 'mt-5' : 'mb-5' ">
        <carousel
            :autoplay="3000"
            transition="3000"
            :wrap-around="true"
            :breakpoints="breakpoints"
            :items-to-show="1"
        >
            <slide
                v-for="banner in banners"
                :key="banner.id"
            >
                <div class="carousel__item">
                    <a v-if="banner.url" :href="banner.url" target="_blank">
                        <img :src="banner.image" alt="Banner Image" loading="lazy" />
                    </a>
                    <router-link v-else-if="banner.target_type" :to="'/' + banner.target_type === 2 ? 'auctions' : banner.target_type === 3 ? 'ads' : 'products' + '/' + banner.target.id">
                        <img :src="banner.image" alt="Banner Image" loading="lazy" />
                    </router-link>
                    <img v-else :src="banner.image" alt="Banner Image" loading="lazy" />
                </div>
            </slide>
            <template #addons="{ slidesCount }">
                <navigation v-if="slidesCount > 1" />
                <pagination v-if="slidesCount > 1" />
            </template>
        </carousel>
    </div>
    <div v-else-if="banners.length === 0 && type == 1">
        <banners-skeleton />
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import BannersSkeleton from "@/components/home/BannersSkeleton";
import axios from "axios";
import {checkAuth} from "@/shared";
export default {
    props: {
        type: {
            type: Number,
            required: false,
            default: 1
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        BannersSkeleton
    },
    data() {
        return {
            banners: [],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: "center"
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: "center"
                }
            }
        }
    },
    mounted() {
        this.getBanners()
    },
    methods: {
        getBanners() {
            axios.get(`${process.env.VUE_APP_API_URL}banners?type=${this.type}`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                // this.banners = res.data.data.items.filter(banner => banner.position == this.type || banner.position == 0)
                this.banners = res.data.data.items
            }).catch(err => {
                console.log(err)
            })
        },
    }
}
</script>

<style>

.banners-box .carousel__item {
    min-height: 350px;
    max-height: 350px;
    width: 100%;
    background-color: transparent;
    /*color: var(--vc-clr-white);*/
    font-size: 20px;
    border-radius: 8px;
    display: block !important;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
}

.banners-box .carousel__item img {
    width: 1000px;
    height: 350px;
}

@media (max-width: 980px) {
    .banners-box .carousel__item {
        min-height: 200px;
        width: 100%;
        background-color: transparent;
        /*color: var(--vc-clr-white);*/
        font-size: 20px;
        border-radius: 8px;
        display: block !important;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
    }

    .banners-box .carousel__item img {
        height: unset;
        width: 300px;
    }
}
</style>
