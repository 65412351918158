<template>
    <!-- Categories Section -->
		<div class="categorieslist-section">
            <div class="container">
		        <div class="row">
			       <div class="col-lg-3 col-md-4">
				    	<div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
		    				   <img src="../../../assets/img/icons/category-1.svg" alt="car1">
							   <h6>Automotive</h6>
							   <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
					   </div>
				   </div>
			       <div class="col-lg-3 col-md-4">
			            <div class="categories-content">
					        <a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
								<img src="../../../assets/img/icons/category-2.svg" alt="icons">
								<h6>Electronics</h6>
								<span class="ads">09 Ads</span>
								<span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>																			   
						</div>
				   </div>
				   <div class="col-lg-3 col-md-4">
					    <div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-3.svg" alt="car1">
							   <h6>Fashion Style</h6>
					           <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>								   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
					           <img src="../../../assets/img/icons/category-4.svg" alt="car1">
							   <h6>Health Care</h6>
							   <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-5.svg" alt="car1">
							   <h6>Job Board</h6>
					    	   <span class="ads">09 Ads</span>
						       <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-6.svg" alt="car1">
							   <h6>Education</h6>
							   <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
						    </a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content" >
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-7.svg" alt="car1">
							   <h6>Real Estate</h6>
						    	<span class="ads">09 Ads</span>
					    	    <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content ">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-8.svg" alt="car1">
							   <h6>Travel</h6>
					           <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content">
					    	<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-9.svg" alt="car1">
							   <h6>Sports & Game</h6>
							   <span class="ads">09 Ads</span>
						       <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content aos aos-init aos-animate" data-aos="fade-up">
							<a href="javascript:void(0);" class="text-center">
							   <img src="../../../assets/img/icons/category-10.svg" alt="car1">
							   <h6>Magazines</h6>
					           <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-11.svg" alt="car1">
							   <h6>Pet & Animal </h6>
							   <span class="ads">09 Ads</span>
					       	   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
						</div>							   
					</div>
					<div class="col-lg-3 col-md-4">
					    <div class="categories-content">
							<a href="javascript:void(0);" class="text-center aos aos-init aos-animate" data-aos="fade-up">
							   <img src="../../../assets/img/icons/category-12.svg" alt="car1">
							   <h6>House Hold</h6>
							  <span class="ads">09 Ads</span>
							   <span>Lorem Ipsum is simply dummy text of the typewriting</span>
							</a>								   
					   </div>							   
					</div>
				</div>
			</div>
		</div>
		<!-- /Categories Section -->
</template>