<template>
    <div class="modal modals fade" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
<!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div class="modal-body">
                    <div
                        v-if="users.length > 0 && !loading"
                    >
                        <div
                            v-for="user in users"
                            :key="user.id"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <router-link :to="`/profile/${user.id}`" @click="handleRemoveBackdrop">
                                    <div class="d-flex">
                                        <div class="">
                                            <img :src="user.image" alt="authorimg" width="50" height="50" class="rounded-pill">
                                        </div>
                                        <div class="authordetails mx-2 small">
                                            <h6>{{ user.full_name }}</h6>
                                            <p>{{ user.bio }}</p>
                                        </div>
                                    </div>
                                </router-link>
                                <div v-if="authUser && user.id !== authUser.id">
                                    <button v-if="!user.is_followed_by_me" class="btn btn-sm btn-primary" @click="toggleFollow(user.id)">{{ $t('follow') }}</button>
                                    <button v-else class="btn btn-sm btn-secondary" @click="toggleFollow(user.id)">{{ $t('unfollow') }}</button>
                                </div>
                            </div>
                            <hr class="my-2" />
                        </div>
                    </div>
                    <div v-else-if="users.length === 0 && loading" class="text-center">
                        <div class="spinner-border text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else class="text-center my-3">
                        <h6>{{ $t('no_results') }}</h6>
                    </div>
                </div>
<!--                <div class="modal-footer">-->
<!--                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
<!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        type: {
            type: String,
            required: true
        },
        userId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            users: [],
            loading: true,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    emits: ['updateUserDetails'],
    mounted() {
        if(this.type === 'followers') {
            document.getElementById('followersModal').addEventListener('shown.bs.modal', () => {
                this.getUsers()
            })
        } else {
            document.getElementById('followingsModal').addEventListener('shown.bs.modal', () => {
                this.getUsers()
            })

        }
    },
    methods: {
        getUsers() {
            let url = ''
            if(this.type === 'followers')
                url = `${process.env.VUE_APP_API_URL}user/followers/${this.userId}`
            else
                url = `${process.env.VUE_APP_API_URL}user/followings/${this.userId}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.users = this.type === 'followers' ? res.data.data.followers : res.data.data.followings
                this.loading = false
            }).catch(err => {
                this.loading = false
                // router.replace('/error-404')
                console.log(err.response)
            })
        },
        toggleFollow(id) {
            axios.post(`${process.env.VUE_APP_API_URL}user/toggleFollow/${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                let userIndex = this.users.map(user => user.id).indexOf(id)
                this.users[userIndex] = res.data.data.followed_user

                this.$emit('updateUserDetails')

            }).catch(err => {
                // router.replace('/error-404')
                console.log(err.response)
            })
        },
        handleRemoveBackdrop() {
            $('.modal-backdrop').remove();
        }
    }
}
</script>
