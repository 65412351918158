<template>
    <!-- Profile Content -->
    <div class="">
        <div class="container">
            <div class="profile-content">
                <div class="row dashboard-info">
                    <div class="col-lg-9">
                        <div class="card dash-cards">
                            <div class="card-header">
                                <h4>{{ $t('account_details') }}</h4>
                            </div>
                            <div class="card-body">
                                <div class="profile-photo">
                                    <div class="profile-img">
                                        <div class="settings-upload-img">
                                            <img :src="imageUrl" alt="profile">
                                        </div>
                                        <div class="settings-upload-btn">
                                            <input type="file" accept="image/*" name="image"
                                                   class="hide-input image-upload" @change="onFileSelected">
                                            <label for="file" class="file-upload">{{ $t('upload_image') }}</label>
                                        </div>
<!--                                        <span>Max file size: 10 MB</span>-->
                                    </div>
<!--                                    <a href="javascript:void(0)" class="profile-img-del"><i class="feather-trash-2"></i></a>-->
                                </div>
                                <div class="profile-form">
                                    <b-form>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label profile-label">{{ $t('first_name') }}</label>
                                                    <div class="pass-group group-img">
                                                        <span class="lock-icon"><i
                                                            class="feather-user"></i></span>
                                                        <b-form-input v-model="firstName" type="text" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label profile-label">{{ $t('last_name') }}</label>
                                                    <div class="group-img">
                                                        <i class="feather-user"></i>
                                                        <b-form-input v-model="lastName" type="text" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label profile-label">{{ $t('phone') }}</label>
                                                    <div class="pass-group group-img">
                                                        <span class="lock-icon"><i
                                                            class="feather-phone-call"></i></span>
                                                        <b-form-input v-model="phone" type="number" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="form-group">
                                                    <label class="col-form-label profile-label">{{ $t('email') }}</label>
                                                    <div class="group-img">
                                                        <i class="feather-mail"></i>
                                                        <b-form-input v-model="email" type="text" class="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label profile-label">{{ $t('bio') }}</label>
                                            <div class="pass-group group-img">
                                                <textarea v-model="bio" rows="4" class="form-control"></textarea>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="pass-group group-img">
                                                <button class="btn btn-primary" @click="handleEditProfile" :disabled="saveDisabled">
                                                    <b-spinner v-if="saveDisabled" small label="Spinning"></b-spinner>
                                                    <span v-else>{{ $t('save') }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="profile-sidebar">
                            <div v-if="!selectedUser.settings.login_method_id" class="card">
                                <div class="card-header">
                                    <h4>{{ $t('change_password') }}</h4>
                                </div>
                                <div class="card-body">
                                    <b-form>
                                        <div class="form-group">
                                            <label class="col-form-label profile-label">{{ $t('current_password') }}</label>
                                            <div class="pass-group group-img">
                                                <span class="lock-icon"><i class="feather-lock"></i></span>
                                                <b-form-input
                                                    v-if="showCurrentPassword"
                                                    type="text"
                                                    class="form-control pass-input"
                                                    v-model="currentPassword"
                                                    placeholder=".............."
                                                />
                                                <b-form-input v-else type="password"
                                                              class="form-control pass-input"
                                                              placeholder=".............." v-model="currentPassword"/>
                                                <span
                                                    class="toggle-password"
                                                    @click="toggleShowCurrent"
                                                    :class="{
														'feather-eye': showCurrentPassword,
														'feather-eye-off': !showCurrentPassword,
														}"
                                                    style="font-family: 'feather' !important"
                                                ></span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label profile-label">{{ $t('new_password') }}</label>
                                            <div class="pass-group group-img">
                                                <span class="lock-icon"><i class="feather-lock"></i></span>
                                                <b-form-input
                                                    v-if="showNewPassword"
                                                    type="text"
                                                    class="form-control pass-input"
                                                    v-model="newPassword"
                                                    placeholder=".............."
                                                />
                                                <b-form-input v-else type="password"
                                                              class="form-control pass-input"
                                                              placeholder=".............." v-model="newPassword"/>
                                                <span
                                                    class="toggle-password"
                                                    @click="toggleShowNew"
                                                    :class="{
														'feather-eye': showNewPassword,
														'feather-eye-off': !showNewPassword,
														}"
                                                    style="font-family: 'feather' !important"
                                                ></span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label profile-label">{{ $t('confirm_password') }}</label>
                                            <div class="pass-group group-img">
                                                <span class="lock-icon"><i class="feather-lock"></i></span>
                                                <b-form-input
                                                    v-if="showConfirmPassword"
                                                    type="text"
                                                    class="form-control pass-input"
                                                    v-model="confirmPassword"
                                                    placeholder=".............."
                                                />
                                                <b-form-input v-else type="password"
                                                              class="form-control pass-input"
                                                              placeholder=".............." v-model="confirmPassword"/>
                                                <span
                                                    class="toggle-password"
                                                    @click="toggleShowConfirm"
                                                    :class="{
														'feather-eye': showConfirmPassword,
														'feather-eye-off': !showConfirmPassword,
														}"
                                                    style="font-family: 'feather' !important"
                                                ></span>
                                            </div>
                                        </div>
                                        <b-button variant="primary" @click="handleChangePassword" :disabled="changePasswordDisabled">
                                            <b-spinner v-if="changePasswordDisabled" small label="Spinning"></b-spinner>
                                            <span v-else>{{ $t('change_password') }}</span>
                                        </b-button>

                                    </b-form>
                                </div>
                            </div>
                            <div class="card my-2">
                                <div class="card-body">
                                    <button class="btn btn-danger-light w-100" @click="handleDeleteAccount"><i class="fa-regular fa-trash-can"></i> {{ $t('delete_account') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Profile Content -->
</template>

<script>

import axios from "axios";
import {router} from "@/router";

const image = require(`@/assets/img/profile-img.jpg`)
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedUser: this.user,
            showCurrentPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            imageUrl: this.user.image ?? image,
            firstName: this.user.first_name ?? '',
            lastName: this.user.last_name ?? '',
            phone: this.user.phone ?? '',
            email: this.user.email ?? '',
            bio: this.user.bio ?? '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            saveDisabled: false,
            changePasswordDisabled: false
        };
    },
    emits: ['updateUser'],
    methods: {
        toggleShowCurrent() {
            this.showCurrentPassword = !this.showCurrentPassword;
        },
        toggleShowNew() {
            this.showNewPassword = !this.showNewPassword;
        },
        toggleShowConfirm() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },
        onFileSelected(event) {
            const file = event.target.files[0]
            const reader = new FileReader()

            reader.onload = (event) => {
                this.imageUrl = event.target.result
            }

            reader.readAsDataURL(file)
        },
        handleEditProfile() {
            this.saveDisabled = true

            let data = {
                first_name: this.firstName,
                last_name: this.lastName,
                phone: this.phone,
                email: this.email,
                bio: this.bio,
                // image: this.imageUrl
            }

            if(this.imageUrl !== this.selectedUser.image) {
                data.image = this.imageUrl
            }

            axios.put(`${process.env.VUE_APP_API_URL}profile`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.saveDisabled = false

                this.$swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                this.selectedUser = res.data.data.user_details
                localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
                this.$emit('updateUser')
                if(!this.selectedUser.isPhoneVerified) {
                    // this.submitDisabled = true
                    $('#verifyPhoneModal').modal('show')
                }
            }).catch(err => {
                this.saveDisabled = false

                this.$swal.fire({
                    title: err.response.data.message,
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            })
        },
        handleChangePassword() {
            if(this.newPassword === this.confirmPassword) {
                this.changePasswordDisabled = true

                let data = {
                    old_password: this.currentPassword,
                    password: this.newPassword,
                    password_confirmation: this.confirmPassword
                }

                axios.post(`${process.env.VUE_APP_API_URL}change-password`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Accepted-Language': this.$i18n.locale
                    }
                }).then(res => {
                    this.changePasswordDisabled = false

                    this.$swal.fire({
                        title: res.data.message,
                        icon: 'success',
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        showConfirmButton: false,
                    })

                    this.$store.commit('resetCart')
                    let currentLang = this.$i18n.locale
                    localStorage.clear()
                    localStorage.setItem('lang', currentLang)
                    // this.$store.commit('resetCart')
                    router.replace('/login')
                }).catch(err => {
                    this.changePasswordDisabled = false

                    this.$swal.fire({
                        title: err.response.data.message,
                        icon: 'error',
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        showConfirmButton: false,
                    })
                })
            } else {
                this.$swal.fire({
                    title: this.$t('confirm_password_not_matched'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
        handleDeleteAccount() {
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('delete_account'),
                text: this.$t('are_you_sure'),
                showDenyButton: true,
                confirmButtonText: this.$t('yes'),
                denyButtonText: this.$t('no')
            }).then(result => {
                if(result.isConfirmed) {

                    axios.delete(`${process.env.VUE_APP_API_URL}profile`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.$store.commit('resetCart')
                        let currentLang = this.$i18n.locale
                        localStorage.clear()
                        localStorage.setItem('lang', currentLang)
                        router.replace('/login')
                    }).catch(err => {
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })
                }
            })
        }
    },
};
</script>

<style scoped>
.profile-label {
    width: 100%;
    text-align: left;
}

[dir='rtl'] .profile-label {
    text-align: right;
}
</style>
