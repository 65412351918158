<template>
    <!--Blog Banner-->
		<div class="blogbanner">
		   
           <div class="blogbanner-content">
              <span class="blog-hint">Health</span>
              <h1>The Top 25 Bike Stores in Toronto by Neighbourhood</h1>
              <ul class="entry-meta meta-item">
                  <li>
                      <div class="post-author">
                          <div class="post-author-img">
                              <img src="../../../../assets/img/profiles/avatar-01.jpg" alt="author">
                          </div>
                          <a href="javascript:void(0)"><span> John Doe </span></a>
                      </div>
                  </li>
                  <li class="date-icon"><i class="fa-solid fa-calendar-days"></i> October 6, 2022</li>
              </ul>
          </div>		            
      </div>	
      <!--/Blog Banner-->
      
      <!--Blog Content-->
      <div class="blogdetail-content">
          <div class="container">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vehicula sapien nec nisi aliquam, vitae finibus purus sodales. Mauris at turpis nulla. Curabitur nec tellus est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum pharetra arcu ac arcu varius, non congue neque aliquet. Pellentesque at feugiat purus. Aenean faucibus vehicula eros.</p>
              <p>varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla fermentum malesuada pulvinar. Vestibulum laoreet rutrum semper. Vivamus malesuada, nisl in consectetur semper, mauris nulla aliquam risus, nec ultricies sapien elit sed ante. Aenean luctus felis in sem consequat auctor. Nulla turpis enim, scelerisque sit amet consectetur vel, lacinia sed augue. Proin ultricies dui id ex fringilla porta. Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p> 
              <blockquote class="blog-quotes">
                   <img src="../../../../assets/img/blogquote.jpg" alt="quotes">
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vehicula sapien nec nisi aliquam, vitae finibus purus sodales. Mauris at turpis nulla. Curabitur nec tellus est. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum pharetra arcu</p>
                  <h6>Luis Pickford</h6>
              </blockquote> 
              <p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla fermentum malesuada pulvinar. Vestibulum laoreet rutrum semper. Vivamus malesuada, nisl in consectetur semper, mauris nulla aliquam risus, nec ultricies sapien elit sed ante. Aenean luctus felis in sem consequat auctor. Nulla turpis enim, scelerisque sit amet consectetur vel, lacinia sed augue. Proin ultricies dui id ex fringilla porta. Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo. </p>				
              <div class="row">
                 <div class="col-lg-6 col-md-6">
                      <div class="bloginner-img">
                           <img src="../../../../assets/img/blog/blogdetailimg-1.jpg" class="img-fluid" alt="">											 
                      </div>
                 </div>
                 <div class="col-lg-6 col-md-6">
                       <div class="bloginner-img">
                           <img src="../../../../assets/img/blog/blogdetailimg-2.jpg"  class="img-fluid" alt="">					 
                      </div>	   
                 </div>				
              </div>
              <div class="requiremnts-info">
                  <h5>Requirements</h5>
                  <ul>
                     <li>We do not require any previous experience or pre-defined skills to take this course. A Great orientation would be enough to master UI/UX design.</li>
                     <li>A Computer with a good internet connection.</li>
                     <li>Vestibulum laoreet rutrum semper. Vivamus malesuada, nisl in consectetur semper, mauris nulla aliquam risus, nec ultricies sapien elit sed ante. Aenean luctus felis in sem consequat auctor.</li>
                     <li>We do not require any previous experience or pre-defined skills to take this course. A Great orientation would be enough to master UI/UX design.</li>
                     <li>A Computer with a good internet connection.</li>
                     <li>Vestibulum laoreet rutrum semper. Vivamus malesuada, nisl in consectetur semper, mauris nulla aliquam risus, nec ultricies sapien elit sed ante. Aenean luctus felis in sem consequat auctor.</li>
                  </ul>
              </div>
              <div class="share-postsection">
                   <div class="row">
                      <div class="col-lg-4">
                          <div class="sharelink">
                             <a href="javasvript:void();" class="share-img"><i class="fas fa-light fa-share-nodes"></i></a>
                             <a href="javasvript:void();" class="share-text">Share</a>
                          </div>
                      </div>
                      <div class="col-lg-8">
                         <div class="tag-list">
                             <ul class="tags">
                                 <li>Travelling </li>
                                 <li>Art </li>
                                 <li>Vacation </li>
                                 <li>Tourism </li>
                                 <li>Culture </li>								   							
                                 <li>LSifestyle </li>								   							
                              </ul>
                         </div>						
                      </div>					 
                   </div>
              </div>
              <div class="blogdetails-pagination">
                  <ul>
                      <li>
                           <router-link to="/blog-details" class="prev-link"><i class="fas fa-regular fa-arrow-left"></i> Previous Post</router-link>
                          <router-link to="/blog-details"><h3>The Best SPA Salons For Your Relaxation</h3> </router-link>
                      </li>
                      <li>
                            <router-link to="/blog-details" class="next-link">Next Post <i class="fas fa-regular fa-arrow-right"></i> </router-link>
                            <router-link to="/blog-details"><h3>8 Amazing Tricks About Business</h3> </router-link>
                      </li>						
                  </ul>				
              </div>
              <div class="card review-sec mb-0">
                  <div class="card-header align-items-center">
                      <img src="../../../../assets/img/review.svg" alt="review">
                      <h4>14 Reviews</h4>
                  </div>
                  <div class="card-body">
                      <div class="review-list">
                          <ul class="">
                              <li class="review-box feedbackbox mb-0">
                                  <div class="review-details">
                                     <h6>Leave feedback about this</h6>
                                  </div>
                                  <div class="card-body">
                                      <b-form class="">
                                          <div class="form-group">
                                              <b-form-input type="text" class="form-control" placeholder="Title" />
                                          </div>
                                          <div class="namefield">
                                              <div class="form-group">
                                                 <b-form-input type="text" class="form-control" placeholder="Name*" required />
                                              </div>
                                              <div class="form-group me-0">
                                                 <b-form-input type="email" class="form-control" placeholder="Email*" required />
                                              </div>
                                          </div>
                                          <div class="form-group">
                                                 <textarea rows="4" class="form-control" placeholder="Write a Review*" required></textarea>
                                          </div>
                                          <div class="reviewbox-rating">
                                              <p><span> Rating</span> 
                                                  <i class="fas fa-star filled"></i>
                                                   <i class="fas fa-star filled"></i>
                                                  <i class="fas fa-star filled"></i>
                                                  <i class="fas fa-star filled"></i>
                                                  <i class="fas fa-star filled"></i>
                                              </p>
                                          </div>
                                          <div class="submit-section">
                                              <b-button variant="primary submit-btn" type="submit"> Submit Review</b-button>
                                          </div>
                                      </b-form>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>		
      </div>   
      <!--/Blog Content-->
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>