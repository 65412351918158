<template>
    <section class="latestad-section grid-view featured-slider">
        <div class="container">
            <div class="section-heading">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-start">
                            <div>
                                <h2>{{ $t('commercial_ads') }}</h2>
                            </div>
                            <div>
                                <router-link to="/commercial-ads/all" class="btn  btn-view">{{ $t('view_all') }}</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lateestads-content">
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 d-flex" v-for="item in ads" :key="item.id">
                        <commercial-ad :ad="item" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from "axios";
import CommercialAd from "@/views/pages/commercial-ads/CommercialAd";

export default {
    data() {
        return {
            ads: []
        };
    },
    components: {
        CommercialAd,
    },
    mounted() {
        this.getAds()
    },
    methods: {
        getAds() {
            axios.post(`${process.env.VUE_APP_API_URL}commercial-ads`,{}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.ads = res.data.data.items
            }).catch(err => {
                console.log(err)
            })
        }
    }
};
</script>
