<template>
    <!--Details Description  Section-->
    <section class="details-description mt-5">
        <div class="container">
            <div class="about-details">
                <div class="about-headings">
                    <div class="author-img">
                        <img :src="auction.image" alt="authorimg">
                    </div>
                    <div class="authordetails mx-2">
                        <h5>{{ auction.title }}</h5>
                        <h6>{{ auction.category.name }}
                            <span v-for="attribute in auction.attributes" :key="attribute.id"> - {{attribute.name}}</span>
                        </h6>
                        <h6>{{ auction.address }}</h6>
<!--                        <p>{{ auction.description }}</p>-->
                        <span class="small mt-4 d-block">{{ $t('published_at') }}: {{ auction.created_at }}</span>
                        <span class="small my-2">{{ $t('expires_at') }}: {{ auction.expiration_date }}</span>
                        <!--                        <div class="rating">-->
                        <!--                            <i class="fas fa-star filled"></i>-->
                        <!--                            <i class="fas fa-star filled"></i>-->
                        <!--                            <i class="fas fa-star filled"></i>-->
                        <!--                            <i class="fas fa-star filled"></i>-->
                        <!--                            <i class="fa-regular fa-star rating-color"></i>-->
                        <!--                            <span class="d-inline-block average-rating"> 4.5 </span>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <div class="rate-details">
                    <h2 :class="$i18n.locale === 'ar' ? 'text-start' : 'text-end'">{{ auction.min_price }} {{ $t('kwd') }}</h2>
                </div>
            </div>
            <div class="descriptionlinks">
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="align-items-center">
                            <li v-if="!inWishlist"><button class="btn" @click="handleToggleWishlist(auction.id)"><i class="fa-regular fa-heart mx-2"></i> {{ $t('add_to_wishlist') }}</button></li>
                            <li v-else><button class="btn main-color" @click="handleToggleWishlist(auction.id)"><i class="fa-solid fa-heart main-color mx-2"></i> {{ $t('remove_from_wishlist') }}</button></li>
                            <li class="text-dark"><i class="fa-regular fa-eye"></i> {{ auction.views }}</li>
                        </ul>
                    </div>
                    <div :class="this.$i18n.locale === 'ar' ? 'col-lg-6 text-start' : 'col-lg-6 text-end'">
                        <router-link :to="'/auctions/'+auction.id+'/edit'" v-if="auction.user && authUser && (auction.user.id === authUser.id)" class="btn btn-light-success mx-1"><i class="fa-regular fa-pen-to-square"></i> {{ $t('edit') }}</router-link>
                        <button v-if="!auction.user || !authUser || auction.user.id !== authUser.id"  class="btn btn-light-warning mx-1" @click="reportAuction"><i class="fa-regular fa-flag"></i> {{ $t('report') }}</button>
                        <button v-if="auction.user && authUser && (auction.user.id === authUser.id)" class="btn btn-danger-light mx-1" @click="deleteAuction"><i class="fa-regular fa-trash-can"></i> {{ $t('delete') }}</button>
                    </div>
                    <!--                    <div class="col-lg-1" v-if="auction.featured_end_date">-->
                    <!--                        <div class="Featured-text text-center">-->
                    <!--                            {{ $t('featured') }}-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </section>
    <!--/Details Description  Section-->
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        auction: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            inWishlist: this.auction.is_wishlist,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        }
    },
    methods: {
        handleToggleWishlist(id) {
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }
            let data = {
                target_id: id,
                target_type: 'auction'
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/toggle-wishlist`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                this.inWishlist = !this.inWishlist
            }).catch(err => {
                console.log(err)
            })
        },
        reportAuction() {
            this.$swal.fire({
                title: this.$t('report_this_auction'),
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    required: true
                },
                showDenyButton: true,
                denyButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('report'),
                showLoaderOnConfirm: true,
                preConfirm: (reason) => {
                    let data = {
                        comment: reason,
                        target_type: 'auction',
                        target_id: this.auction.id
                    }
                    return axios.post(`${process.env.VUE_APP_API_URL}user/reports`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    })
                        .then(response => {
                            return response.data.message
                        })
                        .catch(error => {
                            this.$swal.showValidationMessage(
                                error
                            )
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire({
                        icon: 'success',
                        title: result.value,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        },
        deleteAuction() {
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('are_you_sure'),
                text: this.$t('this_auction_will_be_archived'),
                showDenyButton: true,
                denyButtonText: this.$t('no'),
                confirmButtonText: this.$t('yes'),
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.patch(`${process.env.VUE_APP_API_URL}user/auctions/${this.auction.id}/archive`, {},{
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(response => {
                        this.$swal.fire({
                            icon: 'success',
                            title: this.$t('done'),
                            confirmButtonText: this.$t('ok'),
                            allowOutsideClick: false,
                        }).then(res => {
                            router.replace('/auctions/all')
                        })
                    }).catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })

                }
            })
        }
    }
}
</script>
