import { createApp } from 'vue';
import { router } from './router';
import App from "./App.vue";
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import VueApexCharts from "vue3-apexcharts";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import VueEasyLightbox from "vue-easy-lightbox";
import Antd from 'ant-design-vue';
import AOS from 'aos'
import VueCarousel from 'vue-carousel';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createI18n } from 'vue-i18n'
import en from './lang/en.json'
import ar from './lang/ar.json'
import store from './store'
import vue3GoogleLogin from 'vue3-google-login'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import $ from 'jquery'
import VueCookies from 'vue-cookies'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

// Header Components
import Navbar from './components/navbar.vue'
import UserNavbar from './components/UserpageNavbar.vue'
import NavbarTwo from './components/navbarTwo.vue'
import NavbarTwoHeader from './components/navbarTwoHeader.vue'
import NavbarThree from './components/navbarThree.vue'
import NavbarFour from './components/navbarFour.vue'
import NavbarFive from './components/navbarFive.vue'
import NavMenu from './components/navmenu.vue'


// Page Components
import Header from './views/layouts/header.vue'
import IndexCategory from './views/pages/index/IndexCategory.vue'
import Foot from './views/pages/Footer.vue'
import Scroll from './views/pages/Scroll.vue'
import AboutContent from './views/pages/about/AboutContent.vue'
import AboutPopular from './views/pages/about/AboutPopular.vue'
import AboutPartners from './views/pages/about/AboutPartners.vue'
import AddListing from './views/pages/add-listing/AddListing.vue'
import BlogContent from './views/pages/blog/blog-details/BlogContent.vue'
import BlogGridSidebar from './views/pages/blog/blog-grid-sidebar/BlogGridSidebar.vue'
import BlogGridList from './views/pages/blog/blog-grid/BlogGridList.vue'
import BlogListSidebar from './views/pages/blog/blog-list-sidebar/BlogListSidebar.vue'
import BlogList from './views/pages/blog/blog-list/BlogList.vue'
import BookContent from './views/pages/bookmarks/BookmarkContent.vue'
import CategoriesList from './views/pages/categories/CategoriesList.vue'
import ContactInfo from './views/pages/contact/ContactInformation.vue'
import Dashboard from './views/pages/dashboard/DashboardContent.vue'
import FaqContent from './views/pages/faq/FaqContent.vue'
import ForgotPassword from './views/pages/forgot-password/ForgotPassword.vue'
import Gallerypage from './views/pages/gallery/GalleryPage.vue'
import HowWorks from './views/pages/howitworks/Works.vue'
import HowItPricing from './views/pages/howitworks/Pricing.vue'
import ListGridSidebar from './views/pages/list/listing-grid-sidebar/ListingGridSidebar.vue'
import Listgrid from './views/pages/list/listing-grid/DashboardContent.vue'
import ListContent from './views/pages/list/listing-list-sidebar/ListContent.vue'
import ListMapContent from './views/pages/list/listingmap-grid/ListingmapContent.vue'
import ListinglistContent from './views/pages/list/listingmap-list/ListingListContent.vue'
import LoginForm from './views/pages/Login/LoginForm.vue'
import MessageContent from './views/pages/messages/MessageContent.vue'
import MyListing from './views/pages/my-listing/MyListing.vue'
import PricingPlan from './views/pages/pricing/PricingPlan.vue'
import PrivacyPolicy from './views/pages/privacy-policy/PrivacyTerms.vue'
import DeletePolicy from './views/pages/privacy-policy/DeletePolicy.vue'
import ProfileDashboard from './views/pages/profile/ProfileDashboard.vue'
import ReviewDashboard from './views/pages/reviews/ReviewDashboard.vue'
import ServiceGallery from './views/pages/service-details/ServiceGallery.vue'
import ServiceDescription from './views/pages/service-details/ServiceDescription.vue'
import ServiceContent from './views/pages/service-details/ServiceContent.vue'
import SignupLogin from './views/pages/signup/SignupLogin.vue'
import TermsContent from './views/pages/terms-conditions/TermsContent'
import ServiceReview from './views/pages/service-details/serviceReview.vue'



// Breadcrumbs
import BreadCrumb from './components/breadcrumb/Component.vue'
import AboutBreadcrumb from './components/breadcrumb/AboutBreadcrumb.vue'

// plugins
// import './assets/js/map.js'
import 'aos/dist/aos.css'
import './assets/js/backToTop.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'ant-design-vue/dist/antd.css';
import './assets/css/feather.css';
import './assets/css/style.css';
import IndexCommercialAds from "@/views/pages/index/IndexCommercialAds";

const options = {
    confirmButtonColor: '#C10037',
    denyButtonColor: '#333',
    customClass: {
        container: 'swal-visibility'
    }
};

const app = createApp(App)
app.use(vue3GoogleLogin, {
    clientId: '430370124423-sd1npukaaoe1973pcl4ae13qjm2f34eo.apps.googleusercontent.com'
})
app.use(FloatingVue)
app.use(VueSweetalert2, options)
app.component('layouts',Header)
app.component('navbar',Navbar)
app.component('usernavbar',UserNavbar)
app.component('navbartwo',NavbarTwo)
app.component('navbartwoHeader',NavbarTwoHeader)
app.component('navbarthree',NavbarThree)
app.component('navbarfour',NavbarFour)
app.component('navbarfive',NavbarFive)
app.component('navmenu',NavMenu)

app.component('indexcategory',IndexCategory)
app.component('index-commercial-ads',IndexCommercialAds)
app.component('foot',Foot)
app.component('scroll',Scroll)
app.component('aboutcontent',AboutContent)
app.component('aboutpopular',AboutPopular)
app.component('aboutpartners',AboutPartners)
app.component('addlisting',AddListing)
app.component('blogcontent',BlogContent)
app.component('bloggridsidebar',BlogGridSidebar)
app.component('bloggridlist',BlogGridList)
app.component('bloglistsidebar',BlogListSidebar)
app.component('bloglist',BlogList)
app.component('bookmarkcontent',BookContent)
app.component('categorieslist',CategoriesList)
app.component('contactinformation',ContactInfo)
app.component('dashboardcontent',Dashboard)
app.component('faqcontent',FaqContent)
app.component('forgotpassword',ForgotPassword)
app.component('gallerypage',Gallerypage)
app.component('howworks',HowWorks)
app.component('howitpricing',HowItPricing)
app.component('listgridsidebar',ListGridSidebar)
app.component('listgrid',Listgrid)
app.component('listcontent',ListContent)
app.component('listmapcontent',ListMapContent)
app.component('litinglistcontent',ListinglistContent)
app.component('loginform',LoginForm)
app.component('messagecontent',MessageContent)
app.component('mylisting',MyListing)
app.component('pricingplan',PricingPlan)
app.component('privacypolicy',PrivacyPolicy)
app.component('deletepolicy',DeletePolicy)
app.component('profiledashboard',ProfileDashboard)
app.component('reviewdashboard',ReviewDashboard)
app.component('servicegallery',ServiceGallery)
app.component('servicedescription',ServiceDescription)
app.component('servicecontent',ServiceContent)
app.component('signuplogin',SignupLogin)
app.component('termscontent',TermsContent)
app.component('servicereview',ServiceReview)

// Breadcrumb
app.component('breadcrumb',BreadCrumb)
app.component('aboutbreadcrumb',AboutBreadcrumb)

app.component('vue-select', vSelect)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
.use(AOS.init())
app.use(VueEasyLightbox);
app.use(CoolLightBox);
app.use(VueApexCharts);
app.use(VueCarousel);
app.use(store)

// 2. Create i18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem('lang') ?? 'ar', // set locale
    fallbackLocale: 'ar', // set fallback locale
    messages: {
        en: en,
        ar: ar,
    }, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBXR1T50P-rXPfvNu3nWJMX83wv6mVv53w',
        // libraries: "places"
        // language: 'de',
    },
})
app.use($)
app.use(VueCookies)
app.use(i18n)
app.use(router).mount('#app');
