<template>
    <!-- Category Section -->
    <section class="category-section">
        <div class="container">
            <div class="section-heading">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-start">
                            <div v-if="isIndex">
                                <h2>{{ $t('categories') }}</h2>
                                <p>{{ $t('checkout_these_categories') }}</p>
                            </div>
                            <div v-if="isIndex">
                                <!--                                <router-link to="/auctions" class="btn  btn-view">{{ $t('view_all') }}</router-link>-->
<!--                                <button class="btn my-2" :class="typeLink === '/products' ? 'btn-primary' : 'btn-dark'"-->
<!--                                        @click="getCategories(2)">{{ $t('products') }}-->
<!--                                </button>-->
                                <button class="btn mx-2 my-2" :class="typeLink === '/auctions' ? 'btn-primary' : 'btn-dark'"
                                        @click="getCategories(3)">{{ $t('auctions') }}
                                </button>
                                <button class="btn my-2" :class="typeLink === '/ads' ? 'btn-primary' : 'btn-dark'"
                                        @click="getCategories(1)">{{ $t('ads') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="categories.length > 0">
                <div class="row categories-container header-navbar-rht">
                    <div class="col-lg-2 col-md-3 col-sm-4 col-5" v-for="item in categories" :key="item.id">
                        <router-link :to="typeLink + '/' + item.name" class="category-links">
                            <!--                        <span>{{ item.text }}</span>-->
                            <img :src="item.image" alt="Category Image" class="img-responsive category-img" width="100" height="100" loading="lazy"/>
                            <h5 class="category-title mt-3">{{ item.name }}</h5>
                        </router-link>
                    </div>
                </div>

                <div class="categories-container header-navbar-rht-opp">
                    <div class="row flex-nowrap flex-md-wrap">
                        <div class="category-mobile-box" v-for="item in categories.filter((cat, index) => index % 2 === 0)" :key="item.id">
                            <router-link :to="typeLink + '/' + item.name" class="category-links">
                                <!--                        <span>{{ item.text }}</span>-->
                                <img :src="item.image" alt="Category Image" class="img-responsive category-img" loading="lazy"/>
                                <h5 class="category-title mt-3">{{ item.name }}</h5>
                            </router-link>
                        </div>
                    </div>
                    <div class="row flex-nowrap flex-md-wrap mt-2">
                        <div class="category-mobile-box" v-for="item in categories.filter((cat, index) => index % 2 !== 0)" :key="item.id">
                            <router-link :to="typeLink + '/' + item.name" class="category-links">
                                <!--                        <span>{{ item.text }}</span>-->
                                <img :src="item.image" alt="Category Image" class="img-responsive category-img" loading="lazy"/>
                                <h5 class="category-title mt-3">{{ item.name }}</h5>
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
            <div v-else>
                <categories-skeleton />
            </div>
        </div>
    </section>
    <!-- /Category Section -->
</template>

<script>
// import IndexCategory from '../../../assets/json/indexCategory.json'
import CategoriesSkeleton from "@/components/home/CategoriesSkeleton";
import axios from "axios";

export default {
    props: {
        isIndex: {
            type: Boolean,
            required: false,
            default: true
        },
        parentCategory: {
            type: [Object, null],
            required: false,
            default: null
        },
        categoryType: {
            type: Number,
            required: false,
            default: 3
        }
    },
    components: {
        CategoriesSkeleton
    },
    data() {
        return {
            categories: [],
            typeLink: '/auctions'
        }
    },
    mounted() {
        this.getCategories(this.categoryType)
    },
    methods: {
        getCategories(type = 3) {
            this.typeLink = type === 2 ? '/products' : type === 3 ? '/auctions' : '/ads'
            axios.get(`${process.env.VUE_APP_API_URL}categories?type=${type}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(this.parentCategory) {
                    this.categories = res.data.data.items.filter(cat => cat.id === this.parentCategory.id)[0]?.sub_categories
                } else {
                    this.categories = res.data.data.items
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>
