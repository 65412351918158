<template>
    <div class="grid-view listgrid-sidebar">
        <div class="row" v-if="orders.length > 0">
            <div class="col-lg-4 col-md-4" v-for="item in orders" :key="item.id">
                <div class="card flex-fill">
                    <div class="d-flex justify-content-between align-items-center">
                        <h6 class="m-0">{{ $t('order_number') }} #{{ item.id }}</h6>
                        <button class="btn btn-sm btn-primary" data-bs-toggle="modal" :data-bs-target="`#orderDetailsModal-${item.id}`">{{ $t('order_details') }}</button>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mt-4">
                        <p class="flex-fill m-0">{{ $t('price') }}</p>
                        <p class="m-0">{{ item.invoice.grand_total_price }} {{ $t('kwd') }}</p>
                    </div>
                    <hr class="my-2" />
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="flex-fill m-0">{{ $t('status') }}</p>
                        <p class="m-0">{{ item.state.name }}</p>
                    </div>
                    <hr class="my-2" />
                    <div class="d-flex justify-content-between align-items-center">
                        <p class="flex-fill m-0">{{ $t('order_date') }}</p>
                        <p class="m-0">{{ item.created_at }}</p>
                    </div>
                </div>

                <order-details-modal :id="`orderDetailsModal-${item.id}`" :orderId="item.id" />
            </div>

        </div>
        <div v-else-if="loading" class="text-center">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="text-center my-5 py-5">
            <h3>{{ $t('no_results') }}</h3>
        </div>
    </div>
    <div class="text-center">
        <button v-if="currentPage < totalPages" class="btn btn-primary" @click="handleLoadMore">
            {{ $t('load_more') }}
        </button>
    </div>
</template>
<script>
import axios from "axios";
import OrderDetailsModal from "@/components/profile/OrderDetailsModal";

export default {
    props: {
        userId: {
            type: Number,
            required: true
        }
    },
    components: {
        OrderDetailsModal
    },
    data() {
        return {
            orders: [],
            loading: true,
            currentPage: 1,
            totalPages: 1,
            totalOrders: 0,
            showing: 0,
            perPage: 15,
        }
    },
    mounted() {
        this.getOrders()
    },
    methods: {
        getOrders(more = false) {

            let url = `${process.env.VUE_APP_API_URL}user/orders?page=${this.currentPage}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(more) {
                    this.orders = [...this.orders, ...res.data.data.items]
                } else {
                    this.orders = res.data.data.items
                }
                this.totalPages = res.data.data.last_page
                this.totalOrders = res.data.data.total
                this.showing = res.data.data.count
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleLoadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++

                this.getOrders(true)
            } else {
                this.$swal.fire({
                    title: this.$t('no_more'),
                    icon: 'error',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
    }
}
</script>
