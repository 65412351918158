<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

            <forgotpassword  />

            <foot />

            <scroll />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Forgot Password",
            text: "Home",
            text1:  this.$t("Forgot_Password"),
            name: "/",
        }
    },
}
</script>