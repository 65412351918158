<template>
    <div class="container my-5" aria-hidden="true">
        <div class="banners-placeholder"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
}
</script>
<style scoped>
    .banners-placeholder {
        width: 100%;
        height: 400px;
        background-color: #dee2e6;
    }
    @media (max-width: 575px) {
        .banners-placeholder {
            width: 100%;
            height: 200px;
            background-color: #dee2e6;
        }
    }
</style>
