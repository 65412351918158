<template>
    <div class="grid-view listgrid-sidebar">
        <div class="row" v-if="bids.length > 0">
            <div class="col-lg-6 col-md-6" v-for="bid in bids" :key="bid.id">
                <router-link :to="`/auctions/${bid.auction_id}/details`" target="_blank">
                    <div class="card">
                        <div class="card-body d-block pb-2 mb-2">
                            <div class="d-flex justify-content-between align-items-start">
                                <div class="author-headings d-flex justify-content-start mb-3">
                                    <div class="author-img">
                                        <img :src="bid.user.image" alt="authorimg">
                                    </div>
                                    <div class="authordetails mx-2">
                                        <h6>{{ bid.user.full_name }}</h6>
                                        <p>{{bid.price}} {{ $t('kwd') }}</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <span class="small mx-2 text-dark">{{ handleBidDate(bid.created_at) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-else-if="loading" class="text-center">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="text-center my-5 py-5">
            <h3>{{ $t('no_results') }}</h3>
        </div>
    </div>
    <div class="text-center">
        <button v-if="currentPage < totalPages" class="btn btn-primary" @click="handleLoadMore">
            {{ $t('load_more') }}
        </button>
    </div>
</template>
<script>

import axios from "axios";

export default {
    data() {
        return {
            bids: [],
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            loading: true,
            currentPage: 1,
            totalPages: 1,
            totalBids: 0,
            showing: 0,
            perPage: 15,
        }
    },
    mounted() {
        this.getBids()
    },
    methods: {
        handleBidDate(createdAt) {
            let createdDate = new Date(createdAt).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()
            // get total seconds between the times
            var delta = Math.abs(currentDate - createdDate) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = delta % 60;  // in theory the modulus is not required

            if (days)
                return days + ` ${this.$t('d')} `
            else if (hours)
                return hours + ` ${this.$t('h')} `
            else if (minutes)
                return minutes + ` ${this.$t('m')} `
            else
                return seconds + ` ${this.$t('s')}`
        },
        getBids(more = false) {

            let url = `${process.env.VUE_APP_API_URL}user/bids?page=${this.currentPage}`

            axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(more) {
                    this.bids = [...this.bids, ...res.data.data.items]
                } else {
                    this.bids = res.data.data.items
                }
                this.totalPages = res.data.data.last_page
                this.totalBids = res.data.data.total
                this.showing = res.data.data.count
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleLoadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++

                this.getBids(true)
            } else {
                this.$swal.fire({
                    title: this.$t('no_more'),
                    icon: 'error',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
    }
}
</script>
