<template>
    <!-- Featured ADS Section -->
    <section :class="category_id ? 'featured-section py-5' : 'featured-section mt-5'">
        <div class="container index-products-box">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <div class="section-heading d-flex justify-content-between align-items-start" v-if="!category_id">
                        <div>
                            <h2>{{ $t('products') }}</h2>
                            <p>{{ $t('checkout_these_products') }}</p>
                        </div>
                        <div>
                            <router-link to="/products/all" class="btn  btn-view">{{ $t('view_all') }}</router-link>
                        </div>
                    </div>
                    <div class="section-heading d-flex justify-content-between align-items-start" v-else>
                        <div>
                            <h2>{{ $t('similar_products') }}</h2>
                            <p>{{ $t('checkout_these_products') }}</p>
                        </div>
                    </div>
                </div>
                <div :class="$i18n.locale === 'en' ? 'col-md-6 text-md-end aos' : 'col-md-6 text-md-start aos'" data-aos="fade-up">
                    <div class="owl-nav mynav2"></div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-12">
                    <div class="owl-carousel featured-slider grid-view">
                        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                            <Slide v-for="item in products" :key="item.id">
                                <div class="carousel__item">
                                    <product :product="item" @updateProductInList="handleUpdateProductInList" />
                                </div>
                            </Slide>
                            <template #addons>
                                <Navigation />
                            </template>
                        </Carousel>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- /Featured ADS Section -->
</template>

<script>
import {
    Carousel,
    Navigation,
    Slide
} from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import axios from "axios";
import Product from "@/components/Product";
import {router} from "@/router";
export default {
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
                loop: true,
                margin: 24,
            },

            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 700px and up
                767: {
                    itemsToShow: 2,
                    snapAlign: "center"
                },
                // 991px and up
                991: {
                    itemsToShow: 3,
                    snapAlign: "center"
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3.95,
                    snapAlign: "start"

                }
            },
            products: [],
        }
    },
    components: {
        Carousel,
        Slide,
        // Pagination,
        Navigation,
        Product
    },
    props: {
        product_id: {
            type: Number,
            required: false
        },
        category_id: {
            type: Number,
            required: false
        }
    },
    mounted() {
        $('.fav-icon').on('click', function () {
            $(this).toggleClass('selected');
        });
        this.getProducts()
    },
    methods: {
        handleToggleWishlist(id) {
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }
            let data = {
                target_id: id,
                target_type: 'product'
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/toggle-wishlist`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })

                this.$emit('updateProductInList', id, res.data.data.item.target)

            }).catch(err => {
                console.log(err)
            })
        },
        getProducts() {
            let data = {}
            if(this.category_id) {
                data = {
                    filters: {
                        category_id: this.category_id
                    }
                }
            }
            axios.post(`${process.env.VUE_APP_API_URL}products`,data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.products = res.data.data.items
                if(this.product_id) {
                    this.products = this.products.filter(product => product.id !== this.product_id)
                }
            }).catch(err => {
                console.log(err)
            })
        },
        handleUpdateProductInList(id, updatedProduct) {
            let productIndex = this.products.map(product => product.id).indexOf(id)
            this.products[productIndex] = updatedProduct
        },
    }
}
</script>

<style>
.carousel__item {
    display: flex;
    justify-content: center;
    align-items: center;

}
[dir='rtl'] .featured-section .featured-slider .carousel .carousel__prev {
    right: unset;
}

[dir='rtl'] .featured-section .featured-slider .carousel .carousel__next {
    right: unset;
    left: 60px;
}

@media (max-width: 980px) {
    .index-products-box .carousel__item {
        width: 100%;
        display: block !important;
        margin: 5px !important;
    }

}
</style>
