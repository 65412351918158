<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

            <ad-form form-type="create" />

            <foot />

            <scroll />

        </div>
    </div>
</template>

<script>
import AdForm from "@/views/pages/ads/form/AdForm";
export default {
    components: {
        AdForm
    },
    data() {
        return {
            title: this.$t('create_ad'),
            text: this.$t('home'),
            text1: this.$t('create_ad'),
            name: "/",
        }
    },
}
</script>
