<template>
    <!-- How It Works section -->
		<section class="work-section">
		    <div class="container">
			    <div class="work-heading">
				    <h4>Steps to improve your Business</h4>
		            <p class="description">Add your business to Listee, so customers can easily find</p>				
				</div>			    
                <div class="row">
				     <div class="col-lg-4 col-md-4 d-flex">
					      <div class="work-info card">
						      <img src="../../../assets/img/icons/work1.jpg" class="img-fluid" alt="">
							  <h5>01</h5>
							  <h6>Create Account</h6>
							  <p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
						  </div>
					 </div>
					  <div class="col-lg-4 col-md-4 d-flex">
					      <div class="work-info card">
						      <h5 class="mt-0">02</h5>
							  <h6>Post An Ad </h6>
							  <p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
						      <img src="../../../assets/img/icons/work2.jpg" class="img-fluid" alt="">							  
						  </div>
					 </div>
					  <div class="col-lg-4 col-md-4 d-flex">
					      <div class="work-info card">
						      <img src="../../../assets/img/icons/work3.jpg" class="img-fluid" alt="">
							  <h5>03</h5>
							  <h6>Find, Buy and Own Dreams</h6>
							  <p>Morbi nisi justo, venenatis ac nibh at, bibendum mattis risus. Maecenas tincidunt, ligula sed congue tempus, magna augue cursus ipsum, in malesuada justo risus nec lorem. Nam augue augue, mollis nec condimentum euismod, lacinia ultricies leo.</p>
						  </div>
					 </div>
				</div>				
			</div>		    
		</section>		
		<!-- /How It Works section -->
</template>