<template>
    <div class="card flex-fill">
        <div class="blog-widget">
            <div class="blog-img">
                <img v-if="type === 'archive'" :src="ad.image" class="card-image" alt="Ad Image" loading="lazy" />
                <router-link v-else :to="'/ads/'+ad.id+'/details'">
                    <img :src="ad.image" class="card-image" alt="Ad Image" loading="lazy" />
                </router-link>
                <div v-if="authUser" class="fav-item d-flex justify-content-between align-items-center">
                    <button :class="ad.is_wishlist ? 'selected btn fav-icon' : 'btn fav-icon'" @click="handleToggleWishlist(ad.id)"><i class="feather-heart"></i></button>
                </div>
            </div>
            <div class="bloglist-content">
                <div class="card-body">
                    <div class="blogfeaturelink">
                        <div v-if="ad.category" class="blog-features">
                            <span> <i class="fa-regular fa-circle-stop"></i> {{ ad.category.name }}</span>
                        </div>
                        <div class="blog-author">
                            <span class="d-flex align-items-baseline"> <i class="feather-eye mx-1"></i> {{ ad.views }} </span>
                        </div>
                    </div>
                    <h6 v-if="type === 'archive'" :id="`title-header-${ad.id}`"><span class="direction-auto">{{ ad.title }}</span></h6>
                    <h6 v-else :id="`title-header-${ad.id}`"><router-link :to="'/ads/'+ad.id+'/details'" class="direction-auto">{{ ad.title }}</router-link></h6>
                    <div class="blog-location-details">
                        <div class="location-info">
                            <i class="fa-solid fa-comment mx-1"></i> {{ ad.comments_count }}
                        </div>
                        <div class="location-info">
                            <p><i class="fa-solid fa-calendar-days mx-1"></i> {{ ad.created_at }}</p>
                        </div>
                    </div>
                    <div class="amount-details">
                        <div class="amount">
                            <span class="validrate">{{ ad.price }} {{ $t('kwd') }}</span>
                        </div>
                    </div>
                    <div v-if="type === 'archive'" class="row mt-3">
                        <div class="col-md-6 mt-1">
                            <button class="btn btn-primary w-100" @click="handleRepublish">{{ $t('republish') }}</button>
                        </div>
                        <div class="col-md-6 mt-1">
                            <button class="btn btn-danger w-100" @click="handleDelete">{{ $t('delete') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        ad: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    emits: ['updateAdInList'],
    mounted() {
        let arabicRegex = /[\u0600-\u06FF]/
        let title = this.ad.title
        if(arabicRegex.test(title)) {
            $('#title-header-'+this.ad.id).attr('style', 'direction: rtl !important')
        } else {
            $('#title-header-'+this.ad.id).attr('style', 'direction: ltr !important')
        }
    },
    methods: {
        handleToggleWishlist(id) {
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }
            let data = {
                target_id: id,
                target_type: 'ad'
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/toggle-wishlist`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })

                this.$emit('updateAdInList', id, res.data.data.item.target)
            }).catch(err => {
                console.log(err)
            })
        },
        handleRepublish() {
            axios.patch(`${process.env.VUE_APP_API_URL}user/ads/${this.ad.id}/republish`, {},{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(response => {
                this.$swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    confirmButtonText: this.$t('ok'),
                    allowOutsideClick: false,
                }).then(res => {
                    router.replace('/ads/all')
                })
            }).catch(error => {
                this.$swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        },
        handleDelete() {
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('are_you_sure'),
                showDenyButton: true,
                denyButtonText: this.$t('no'),
                confirmButtonText: this.$t('yes'),
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${process.env.VUE_APP_API_URL}user/ads/${this.ad.id}`,{
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(response => {
                        this.$swal.fire({
                            icon: 'success',
                            title: response.data.message,
                            confirmButtonText: this.$t('ok'),
                            allowOutsideClick: false,
                        }).then(res => {
                            this.$emit('updateAdInList', this.ad.id, null)
                        })
                    }).catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })

                }
            })
        }
    }
}
</script>
