<template>
    <ul class="main-nav d-flex align-items-center justify-content-between">
        <li class="megamenu" :class="IndexMenu ? 'active' : 'notactive'"
        >
            <router-link to="/">{{ $t('home') }}</router-link>
        </li>
        <li :class="AuctionsMenu ? 'active' : 'notactive'">
            <router-link to="/auctions/all">{{ $t('auctions') }}</router-link>
        </li>
<!--        <li class="add-btns-li">-->
<!--            <button class="btn btn-sm btn-primary d-flex align-items-center justify-content-between add_btns" id="add_btns" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-add mx-1" aria-hidden="true"></i> {{ $t('add') }}</button>-->
<!--            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="add_btns">-->
<!--                <router-link   to="/ads/create" v-on:click="checkUser('/ads/create')"   class="btn">{{ $t('add_ad') }}</router-link>-->
<!--                <router-link   to="/auctions/create" v-on:click="checkUser('/auctions/create')"   class="btn">{{ $t('add_auction') }}</router-link>-->
<!--            </div>-->
<!--        </li>-->
        <li :class="AdsMenu ? 'active' : 'notactive'">
            <router-link to="/ads/all">{{ $t('ads') }}</router-link>
        </li>

        <li :class="CommercialAdsMenu ? 'active' : 'notactive'">
            <router-link to="/commercial-ads/all">{{ $t('commercial_ads') }}</router-link>
        </li>
<!--        <li :class="ProductsMenu ? 'active' : 'notactive'">-->
<!--            <router-link to="/products/all">{{ $t('products') }}</router-link>-->
<!--        </li>-->


<!--        <li v-if="user" :class="CheckoutMenu ? 'active' : 'notactive'" class="header-navbar-rht-opp">-->
<!--            <router-link to="/checkout">{{ $t('checkout') }}</router-link>-->
<!--        </li>-->
<!--        <li class="header-navbar-rht-opp">-->
<!--            <a class="dropdown-item-custom" @click="handleChangeLang">{{ $i18n.locale === 'en' ? 'AR' : 'EN' }}</a>-->
<!--        </li>-->
        <li class="add-btns-li-opp">
            <router-link to="/ads/create" @click="checkUser('/ads/create')">{{ $t('add_ad') }}</router-link>
        </li>
        <li class="add-btns-li-opp">
            <router-link to="/auctions/create" @click="checkUser('/auctions/create')">{{ $t('add_auction') }}</router-link>
        </li>
<!--        <li v-if="user" :class="CheckoutMenu ? 'active' : 'notactive'" class="header-navbar-rht-opp">-->
<!--            <router-link to="/checkout">{{ $t('checkout') }}</router-link>-->
<!--        </li>-->
        <li v-if="user" :class="ProfileMenu ? 'active' : 'notactive'" class="header-navbar-rht-opp">
            <router-link to="/profile">{{ $t('profile') }}</router-link>
        </li>
        <li v-if="user" class="header-navbar-rht-opp">
            <a class="dropdown-item-custom" @click="handleLogout">{{ $t('logout') }}</a>
        </li>

        <li v-if="!user" class="header-navbar-rht-opp">
            <router-link class="nav-link header-reg" to="/signup">{{ $t('register') }}</router-link>
        </li>
        <li v-if="!user" class="header-navbar-rht-opp">
            <router-link class="nav-link header-login" to="/login">{{ $t('login') }}</router-link>
        </li>

    </ul>
</template>

<script>
import {router} from "@/router";
import axios from "axios";

export default {
    components: {},
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cart: [],
            cartLength: 0
        }
    },
    mounted() {
        if ($(window).width() <= 991) {
            var Sidemenu = function () {
                this.$menuItem = $('.main-nav a');
            };

            function init() {
                var $this = Sidemenu;
                $('.main-nav a').on('click', function (e) {
                    if ($(this).parent().hasClass('has-submenu')) {
                        e.preventDefault();
                    }
                    if (!$(this).hasClass('submenu')) {
                        $('ul', $(this).parents('ul:first')).slideUp(350);
                        $('a', $(this).parents('ul:first')).removeClass('submenu');
                        $(this).next('ul').slideDown(350);
                        $(this).addClass('submenu');
                    } else if ($(this).hasClass('submenu')) {
                        $(this).removeClass('submenu');
                        $(this).next('ul').slideUp(350);
                    }
                });
            }

            init();
        }
    },
    computed: {
        currentPath() {
            return this.$route.name
        },
        IndexMenu() {
            return this.$route.name == 'index';
        },
        AuctionsMenu() {
            return this.$route.name == 'auctions' || this.$route.name == 'create-auctions' || this.$route.name == 'edit-auctions';
        },
        AdsMenu() {
            return this.$route.name == 'ads' || this.$route.name == 'create-ads' || this.$route.name == 'edit-ads';
        },
        CommercialAdsMenu() {
            return this.$route.name == 'commercial-ads';
        },
        ProductsMenu() {
            return this.$route.name == 'products';
        },
        CheckoutMenu() {
            return this.$route.name == 'checkout';
        },
        ProfileMenu() {
            return this.$route.name == 'profile';
        },
        // ListingsMenu() {
        //     return this.$route.name == 'listing-grid' || this.$route.name == 'listing-grid-sidebar' || this.$route.name == 'listing-list-sidebar' || this.$route.name == 'listingmap-list' || this.$route.name == 'listingmap-grid';
        // },
        // PagesMenu() {
        //     return this.$route.name == 'about' || this.$route.name == 'service-details' || this.$route.name == 'pricing' || this.$route.name == 'faq' || this.$route.name == 'gallery' || this.$route.name == 'categories'
        //         || this.$route.name == 'howitworks' || this.$route.name == 'terms-condition' || this.$route.name == 'privacy-policy' || this.$route.name == 'error-404' || this.$route.name == 'error-500';
        // },
        // UserMenu() {
        //     return this.$route.name == 'dashboard' || this.$route.name == 'profile' || this.$route.name == 'my-listing' || this.$route.name == 'bookmarks' || this.$route.name == 'messages' || this.$route.name == 'reviews' || this.$route.name == 'add-listing';
        // },
        // BlogMenu() {
        //     return this.$route.name == 'blog-list' || this.$route.name == 'blog-grid' || this.$route.name == 'blog-details' || this.$route.name == 'blog-list-sidebar' || this.$route.name == 'blog-grid-sidebar';
        // }
        cartChanged() {
            return this.$store.state.cart.cartChanged
        }
    },
    watch: {
        cartChanged: {
            handler() {
                this.handleGetCart()
            },
        }
    },
    methods: {
        checkUser(path){
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }else {
                router.replace(path)
            }
        },
        handleLogout() {
            // this.handleResetCart()
            this.$store.commit('resetCart')
            let currentLang = this.$i18n.locale
            localStorage.clear()
            localStorage.setItem('lang', currentLang)
            // this.$store.commit('resetCart')
            router.replace('/login')
        },
        handleChangeLang() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'ar'
                localStorage.setItem('lang', 'ar')
                document.dir = 'rtl'
            } else {
                this.$i18n.locale = 'en'
                localStorage.setItem('lang', 'en')
                document.dir = 'ltr'
            }
            window.location.reload()
        },
        handleGetCart() {
            axios.get(`${process.env.VUE_APP_API_URL}user/cart`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.cartLength = 0
                this.cart = res.data.data.cart.items
                this.cart.forEach(item => {
                    this.cartLength += item.quantity
                })
            }).catch(err => {
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        },
        handleResetCart() {
            this.$store.commit('resetCart')

            let data = {
                items: []
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/cart`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if (router.currentRoute.value.path === '/checkout') {
                    window.location.reload()
                }

            }).catch(err => {
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        }
    }
}
</script>
