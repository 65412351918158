<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">

            <div v-if="auction">
                <auction-description v-if="auction" :auction="auction" />
                <auction-content v-if="auction" :auction="auction" />
                <foot />
                <scroll />
            </div>
            <div v-else>
                <details-page-skeleton />
                <scroll />
            </div>
        </div>

    </div>
</template>
<script>
import AuctionContent from "@/views/pages/auctions/view/AuctionContent";
import AuctionDescription from "@/views/pages/auctions/view/AuctionDescription";
import DetailsPageSkeleton from "@/components/DetailsPageSkeleton";
import axios from "axios";
import {router} from "@/router";
export default {
    components: {
        AuctionContent,
        AuctionDescription,
        DetailsPageSkeleton
    },
    data() {
        return {
            auction: null
        }
    },
    mounted() {
        this.getAuction(this.$route.params.id)
    },
    methods: {
        getAuction(id) {
            axios.get(`${process.env.VUE_APP_API_URL}auctions/view/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.auction = res.data.data.auction
            }).catch(err => {
                router.replace('/error-404')
            })
        }
    }
}
</script>

