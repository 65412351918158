<template>
    <div class="modal modals fade" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable">
            <div v-if="!loading" class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ $t('order_details_header') }} #{{ order.id }}</h5>
                    <!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div class="modal-body">
                    <div class="flex-fill">
                        <div class="d-flex justify-content-between align-items-center mt-4">
                            <p class="flex-fill m-0">{{ $t('price') }}</p>
                            <p class="m-0">{{ order.invoice.grand_total_price }} {{ $t('kwd') }}</p>
                        </div>
                        <hr class="my-2" />
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('status') }}</p>
                            <p class="m-0">{{ order.state.name }}</p>
                        </div>
                        <hr class="my-2" />
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('order_date') }}</p>
                            <p class="m-0">{{ order.created_at }}</p>
                        </div>
                        <hr class="my-2" />
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('delivery_address') }}</p>
                            <p class="m-0">{{ order.address.name }}</p>
                        </div>
                        <hr class="my-2" />
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('payment_method') }}</p>
                            <p class="m-0">{{ order.transaction?.payment_method?.name }}</p>
                        </div>
                        <hr class="my-2" />
                        <h6>{{ $t('payment_conclusion') }}</h6>
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('delivery_fees') }}</p>
                            <p class="m-0">{{ order.invoice.delivery_fees_price }} {{ $t('kwd') }}</p>
                        </div>
                        <div v-if="order.invoice.promo_discount_price" class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('discount') }}</p>
                            <p class="m-0">{{ order.invoice.promo_discount_price }} {{ $t('kwd') }}</p>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('wallet_deducted') }}</p>
                            <p class="m-0">{{ order.transaction?.wallet_deducted_price }} {{ $t('kwd') }}</p>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <p class="flex-fill m-0">{{ $t('total_fees') }}</p>
                            <p class="m-0 text-success">{{ order.invoice.grand_total_price }} {{ $t('kwd') }}</p>
                        </div>

                        <hr class="my-2" />

                        <h6 class="my-4">{{ $t('order_contains') }}</h6>
                        <div class="row">
                            <div
                                v-for="item in order.items"
                                :key="item.id"
                                class="col-md-12 card"
                            >
                                <div class="d-flex justify-content-between align-items-center">
                                    <img :src="item.product.image" alt="image" width="50" />
                                    <p>{{ item.quantity }}</p>
                                    <div class="d-flex flex-column justify-content-center align-items-center">
                                        <p>{{ item.product.name }}</p>
                                        <span class="small">{{ item.unit_discount_price ? item.unit_discount_price : item.unit_price }} {{ $t('kwd') }}</span>
                                    </div>
                                    <p class="text-danger">{{ item.grand_total_price }} {{ $t('kwd') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="modal-content">
                <div class="text-center m-4">
                    <div class="spinner-border text-dark" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        orderId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            order: null,
            loading: true
        }
    },
    mounted() {
        document.getElementById('orderDetailsModal-'+this.orderId).addEventListener('shown.bs.modal', () => {
            this.getOrder()
        })
    },
    methods: {
        getOrder() {

            axios.get(`${process.env.VUE_APP_API_URL}user/orders/${this.orderId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.order = res.data.data.order
                this.loading = false
            }).catch(err => {
                this.loading = false
                // router.replace('/error-404')
                console.log(err.response)
            })
        }
    }
}
</script>
