<template>
    <section class="details-description mt-5" aria-hidden="true">
        <div class="container">
            <div class="">
                <div class="about-headings">
                    <div class="author-img placeholder">
                        <img />
                    </div>
                    <div class="authordetails mx-2 col-md-6">
                        <p class="placeholder"></p>
                        <p class="placeholder"></p>
                        <p class="placeholder"></p>
                        <p class="placeholder"></p>
                        <p class="placeholder"></p>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container my-5">
        <div class="d-flex justify-content-between">
            <div class="tab-placeholder mb-3 mx-3"></div>
            <div class="tab-placeholder mb-3 mx-3"></div>
            <div class="tab-placeholder mb-3 mx-3"></div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="tab-placeholder mb-3 mx-3"></div>
            <div class="tab-placeholder mb-3 mx-3"></div>
            <div class="tab-placeholder mb-3 mx-3"></div>
        </div>
        <div class="d-flex justify-content-between">
            <div class="tab-placeholder mb-3 mx-3"></div>
            <div class="tab-placeholder mb-3 mx-3"></div>
            <div class="tab-placeholder mb-3 mx-3"></div>
        </div>
    </div>

    <div class="details-main-wrapper" aria-hidden="true">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">

                </div>
                <div class="col-lg-3 theiaStickySidebar">
                    <div class="stickysidebar">
                        <div class="rightsidebar">
                            <div class="card">
                                <h4 class="placeholder"></h4>
                                <h4 class="placeholder"></h4>
                                <div class="my-2 text-center">
                                    <p class="placeholder"></p>
                                    <p class="placeholder"></p>
                                    <p class="placeholder"></p>
                                    <p class="placeholder"></p>
                                    <p class="placeholder"></p>
                                    <p class="placeholder"></p>
                                </div>
                                <div class="mt-4 text-center">
                                    <button class="btn w-100 placeholder"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.tab-placeholder {
    width: 380px;
    height: 110px;
    background-color: #dee2e6;
}

img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dee2e6;
}
h4, h5, h6, p, li {
    width: 40%;
    margin-bottom: 10px;
    background-color: #dee2e6;
}
h4 {
    width: 100%;
}
span {
    width: 20%;
    margin-bottom: 10px;
    background-color: #dee2e6;
}
button {
    margin-block: 10px;
    min-width: 50px;
    background-color: #dee2e6;
}
</style>
