import axios from "axios";

import {router} from "@/router"

const app_id = 885609149123854;
const api_version = 'v15.0';
// const backendEndpoint = import.meta.env.VITE_FACEBOOK_BACKEND_URL;


function facebookLoginHandler(window) {
  initializeFacebook(window);
  addFacebookFunctionality();
  const button = getFacebookButton(document);
  addButtonBehavior(button);
}

function initializeFacebook(window) {
  window.fbAsyncInit = function() {
    FB.init({
      appId      : app_id,
      cookie     : true,
      status:   true,
      xfbml      : true,
      version    : api_version
    });
    FB.AppEvents.logPageView();
    onLoadLogout();
  };
}

function addFacebookFunctionality() {
  var js, facebook_js = document.getElementsByTagName("script")[0];
  if (document.getElementById("facebook-jssdk")) return;
  js = document.createElement("script");
  js.id = "facebook-jssdk";
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  facebook_js.parentNode.insertBefore(js, facebook_js);
}

function onLoadLogout() {
  FB.getLoginStatus(function(response) {
    if (response.status === "connected") logout();
  });
}

function logout() {
  FB.logout(function(response) {});
}

function getFacebookButton(document) {
  return document.getElementById("facebook");
}

function addButtonBehavior(button) {
  button.onclick = function () {
    login();
  }
}

function login() {
  FB.login(function() {
    FB.getLoginStatus(function(response) {
      statusChangeCallback(response);
      console.log(response);
    });

  });
}

function statusChangeCallback(response) {
  if (response.status === "connected") {

    console.log( response.authResponse.accessToken);
    // const token = response.authResponse.accessToken;
    let data = {
      token:response.authResponse.accessToken,
      method_id: 1
    }
    axios.post(`${process.env.VUE_APP_API_URL}social/auth`, data).then(res => {
      localStorage.setItem('token', res.data.data.access_token)
      localStorage.setItem('user', JSON.stringify(res.data.data.user_details))
      router.replace('/')
    }).catch(err => {
      this.error = err.response.data.message
    })
    // axios.post(backendEndpoint, {token})
  };
}

export default facebookLoginHandler;
