<template>
    <div class="modal modals fade" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
                    <!--                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('name') }} <span class="text-danger">*</span></label>
                                <div class="pass-group">
                                    <input v-model="addressName" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.addressName.$error"> {{ v$.addressName.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('phone') }} <span class="text-danger">*</span></label>
                                <div class="pass-group">
                                    <input v-model="phone" type="number" step="1" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.phone.$error"> {{ v$.phone.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('city') }} <span class="text-danger">*</span></label>
                                <div class="pass-group select-box">
                                    <vue-select
                                        v-model="city"
                                        :options="cities"
                                        label="name"
                                        :reduce="opt => opt.id"
                                        :placeholder="$t('choose_city')"
                                        @option:selected="handleChangeCity"
                                        :clearable="false"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('area') }} <span class="text-danger">*</span></label>
                                <div class="pass-group select-box">
                                    <vue-select
                                        v-model="area"
                                        :options="areas"
                                        label="name"
                                        :reduce="opt => opt.id"
                                        :placeholder="$t('choose_area')"
                                        :clearable="false"
                                    />
                                </div>
                                <span class="text-danger mx-2" v-if="v$.area.$error"> {{ v$.area.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('block_number') }} <span class="text-danger">*</span></label>
                                <div class="pass-group">
                                    <input v-model="block" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.block.$error"> {{ v$.block.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('avenue') }}</label>
                                <div class="pass-group">
                                    <input v-model="avenue" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.avenue.$error"> {{ v$.avenue.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('street') }} <span class="text-danger">*</span></label>
                                <div class="pass-group">
                                    <input v-model="street" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.street.$error"> {{ v$.street.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('building') }} <span class="text-danger">*</span></label>
                                <div class="pass-group">
                                    <input v-model="building" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.building.$error"> {{ v$.building.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('floor') }}</label>
                                <div class="pass-group">
                                    <input v-model="floor" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.floor.$error"> {{ v$.floor.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('flat_or_office') }}</label>
                                <div class="pass-group">
                                    <input v-model="flat" type="text" class="form-control">
                                </div>
                                <span class="text-danger mx-2" v-if="v$.flat.$error"> {{ v$.flat.$errors[0].$message }} </span>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('additional_details') }}</label>
                                <div class="pass-group">
                                    <input v-model="details" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
<!--                            <google-map api-key="AIzaSyBXR1T50P-rXPfvNu3nWJMX83wv6mVv53w" style="width: 100%; height: 300px" :center="center" :zoom="15">-->
<!--                                <Marker :options="{ position: center }" />-->
<!--                            </google-map>-->

                            <GMapMap
                                :center="center"
                                :zoom="7"
                                style="width: 100%; height: 300px"
                                @click="handleMapClick"
                            >
                                <GMapMarker
                                    :position="location ? location : center"
                                    :draggable="true"
                                    @dragend="handleMarkerDrag"
                                />
                            </GMapMap>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
<!--                    <button type="button" class="btn btn-secondary d-none" id="closeModal" data-bs-dismiss="modal">{{ $t('close') }}</button>-->
                    <button type="button" class="btn btn-primary" :disabled="loading" @click="handleCreateAddress">
                        <span v-if="!loading">
                            {{ $t('save') }}
                        </span>
                        <span v-else class="text-center">
                            <span class="spinner-border text-white" role="status">
                                <span class="sr-only">Loading...</span>
                            </span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import useValidate from '@vuelidate/core'
import { required, numeric, helpers } from '@vuelidate/validators'
import {router} from "@/router";


export default {
    props: {
        title: {
            type: String,
            required: false
        },
        type: {
            type: String,
            required: true
        },
        address: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            v$: useValidate(),
            users: [],
            loading: false,
            disabled: false,
            addressName: '',
            phone: '',
            city: '',
            cities: [],
            area: '',
            areas: [],
            block: '',
            avenue: '',
            street: '',
            building: '',
            floor: '',
            flat: '',
            details: '',
            location: '',
            lat: null,
            lng: null,
            center: { lat: 29.375859, lng: 47.977405 }
        }
    },
    validations() {
        return {
            addressName: { required: helpers.withMessage(this.$t('required'), required) },
            area: { required: helpers.withMessage(this.$t('required'), required) },
            block: { required: helpers.withMessage(this.$t('required'), required) },
            street: { required: helpers.withMessage(this.$t('required'), required) },
            building: { required: helpers.withMessage(this.$t('required'), required) },
            avenue: { numeric: helpers.withMessage(this.$t('numeric'), numeric) },
            flat: { numeric: helpers.withMessage(this.$t('numeric'), numeric) },
            floor: { numeric: helpers.withMessage(this.$t('numeric'), numeric) },
            phone: {
                required: helpers.withMessage(this.$t('required'), required),
                numeric: helpers.withMessage(this.$t('numeric'), numeric)
            },
        }
    },
    emits: ['addAddressInList', 'updateAddressInList'],
    mounted() {
        if(this.type === 'create') {
            document.getElementById('addressCreateModal').addEventListener('shown.bs.modal', () => {
                this.getCities()
                navigator.geolocation.getCurrentPosition((position) => {

                    this.lat = position.coords.latitude
                    this.lng = position.coords.longitude
                    this.location = { lat: this.lat, lng: this.lng }
                    this.center = { lat: this.lat, lng: this.lng }

                }, () => {
                    this.lat = null
                    this.lng = null

                });
            })
        } else {
            document.getElementById('addressEditModal-'+this.address.id).addEventListener('shown.bs.modal', () => {
                this.getCities()
                this.addressName = this.address.name
                this.phone = this.address.phone
                this.city = this.address.area.city.id
                this.block = this.address.block
                this.avenue = this.address.avenue
                this.street = this.address.street
                this.building = this.address.building
                this.floor = this.address.floor_number
                this.flat = this.address.apartment_number
                this.details = this.address.details
                this.location = this.address.latitude && this.address.longitude ? { lat: this.address.latitude, lng: this.address.longitude } : ''
                this.lat = this.address.latitude
                this.lng = this.address.longitude
                this.center = this.address.latitude && this.address.longitude ? { lat: this.address.latitude, lng: this.address.longitude } : { lat: 29.375859, lng: 47.977405 }

                this.getAreas()
                this.area = this.address.area.id

                if(!this.address.latitude && !this.address.longitude) {
                    navigator.geolocation.getCurrentPosition((position) => {

                        this.lat = position.coords.latitude
                        this.lng = position.coords.longitude
                        this.location = { lat: this.lat, lng: this.lng }
                        this.center = { lat: this.lat, lng: this.lng }

                    }, () => {
                        this.lat = null
                        this.lng = null

                    });
                }
            })
        }
    },
    methods: {
        handleMapClick(e) {
            this.location = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }

            this.lat = e.latLng.lat()
            this.lng = e.latLng.lng()

            this.center = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        },
        handleMarkerDrag(e) {
            this.location = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
            this.lat = e.latLng.lat()
            this.lng = e.latLng.lng()
            this.center = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        },
        getCities() {
            axios.get(`${process.env.VUE_APP_API_URL}countries/1/cities`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.cities = res.data.data.items
            }).catch(err => {
                console.log(err.response)
            })
        },
        handleChangeCity() {
            this.area = ''
            this.getAreas()
        },
        getAreas() {
            axios.get(`${process.env.VUE_APP_API_URL}countries/1/cities/${this.city}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.areas = res.data.data.items
            }).catch(err => {
                console.log(err.response)
            })
        },
        handleCreateAddress() {
            this.v$.$validate() // checks all inputs
            if (!this.v$.$error) {
                this.loading = true

                let data = {
                    name: this.addressName,
                    avenue: this.avenue,
                    apartment_number: this.flat,
                    floor_number: this.floor,
                    block: this.block,
                    street: this.street,
                    building: this.building,
                    details: this.details,
                    phone: this.phone,
                    area_id: this.area,
                }

                if(this.lat && this.lng) {
                    data.latitude = this.lat
                    data.longitude = this.lng
                }

                if (this.type === 'edit') {

                    axios.put(`${process.env.VUE_APP_API_URL}user/addresses/${this.address.id}`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.loading = false

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })

                        $('#addressEditModal-'+this.address.id).modal('hide')
                        this.resetData()


                        this.$emit('updateAddressInList', this.address.id, res.data.data.address)


                    }).catch(err => {
                        this.loading = false
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })

                } else {


                    axios.post(`${process.env.VUE_APP_API_URL}user/addresses`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.loading = false

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })

                        $('#addressCreateModal').modal('hide')
                        this.resetData()


                        this.$emit('addAddressInList', res.data.data.address)


                    }).catch(err => {
                        this.loading = false
                        this.$swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })
                }

            } else {
                this.$swal.fire({
                    icon: 'error',
                    title: this.$t('form_validation_failed'),
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            }
        },
        resetData() {
            this.addressName = ''
            this.phone = ''
            this.city = ''
            this.area = ''
            this.block = ''
            this.avenue = ''
            this.street = ''
            this.building = ''
            this.floor = ''
            this.flat = ''
            this.details = ''
            this.location = ''
            this.lat = null
            this.lng = null
            this.center = { lat: 29.375859, lng: 47.977405 }
        }
    }
}
</script>
