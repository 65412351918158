<script>
import facebookLoginHandler from './facebook.js';

export default {
  props: ['method', 'company'],
  data() {
    return {
      possibleCompanies: [ 'facebook'],
    };
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  created() {
    // this.src = "../src/assets/img/facebook.svg";
  },
  mounted() {
    if (this.company === "facebook") facebookLoginHandler(window);
  }
}
</script>

<template>
    <a  id="facebook" class="btn btn-facebook w-100 mb-0"><img src="/img/facebook.8b172ee4.svg" class="me-2" alt="img">{{$t("Continue_with_Facebook")}}</a>
<!--  <button :id="company" v-if="possibleCompanies.includes(company)"  class="btn btn-facebook w-100 mb-0">-->
<!--      <img-->
<!--              src="/img/facebook.svg" class="me-2"-->
<!--              alt="img">-->
<!--    <p>{{$t("Continue_with_Facebook")}}</p>-->
<!--  </button>-->
</template>