<template>
    <section class="details-description mt-5" aria-hidden="true">
        <div class="container">
            <div class="about-details">
                <div class="about-headings">
                    <div class="author-img placeholder">
                        <img />
                    </div>
                    <div class="authordetails mx-2 col-md-6">
                        <p class="placeholder"></p>
                        <p class="placeholder"></p>
                        <p class="placeholder"></p>
                    </div>
                </div>
                <div class="rate-details">
                    <h2 :class="$i18n.locale === 'ar' ? 'text-start' : 'text-end'" class="placeholder"></h2>
                </div>
            </div>
            <div class="descriptionlinks">
                <div class="row">
                    <div class="col-md-6">
                        <ul>
                            <li><button class="btn placeholder"></button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <div class="details-main-wrapper" aria-hidden="true">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="card ">
                        <div class="card-header">
                            <h4 class="placeholder"></h4>
                        </div>
                        <div class="card-body">
                            <p class="placeholder"></p>
                            <p class="placeholder"></p>
                            <p class="placeholder"></p>
                            <p class="placeholder"></p>
                            <p class="placeholder"></p>
                        </div>
                    </div>

                    <!--Gallery Section-->
                    <div class="card gallery-section ">
                        <div class="card-header ">
                            <h4 class="mx-2 placeholder"></h4>
                        </div>
                        <div class="card-body">
                            <div class="gallery-content">
                                <div class="row w-100">
                                    <div class="col-12 col-lg-3 col-md-3 col-sm-4">
                                        <div class="gallery-widget">
                                            <a class="placeholder">
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!--/Gallery Section-->


                </div>
                <div class="col-lg-3 theiaStickySidebar">
                    <div class="stickysidebar">
                        <div class="rightsidebar">
                            <div class="card">
                                <h4 class="placeholder"></h4>
                                <ul>
                                    <li class="placeholder"></li>
                                </ul>
                                <div class="d-flex justify-content-between align-items-center">
                                    <button class="btn placeholder"></button>
                                    <p class="placeholder"></p>
                                    <button class="btn placeholder"></button>
                                </div>
                                <div class="mt-4 text-center">
                                    <button class="btn w-100 placeholder"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    }
}
</script>
<style scoped>
    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #dee2e6;
    }
    h4, h5, h6, p, li {
        width: 40%;
        margin-bottom: 10px;
        background-color: #dee2e6;
    }
    span {
        width: 20%;
        margin-bottom: 10px;
        background-color: #dee2e6;
    }
    button {
        margin-block: 10px;
        min-width: 50px;
        background-color: #dee2e6;
    }
</style>
