<template>
    <!-- Header -->
    <header class="header">
        <div class="d-flex justify-content-between align-items-center w-100 p-2 bg-black header-navbar-rht-mobile" id="smartBanner">
            <div class="d-flex">
                <button class="btn text-white mx-1" @click="handleCloseBanner">x</button>
                <img :src="require('../assets/img/logo.png')" alt="Mazad Logo" style="width: 50px !important; height: 50px !important">
                <div class="mx-2">
                    <h6 class="text-white">{{ $t('mazad_kwt') }}</h6>
                    <p class="small">{{ $t('free') }} - {{ mobileStore }}</p>
                </div>
            </div>
            <a class="btn btn-outline-light" :href="dynamicLink">{{ $t('view') }}</a>
        </div>
        <div class="container">
            <nav class="navbar navbar-expand-lg header-nav d-flex flex-nowrap">
                <div class="navbar-header d-flex justify-content-between align-items-center">

                    <a id="mobile_btn" href="javascript:void(0);">
                        <span class="bar-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </a>
                    <router-link to="/" class="mx-2"> <!-- navbar-brand logo -->
                        <img :src="require('../assets/img/logo.png')" width="50" alt="Mazad Logo">
                    </router-link>
                    <button class="btn header-navbar-rht-opp" @click="handleChangeLang">{{
                            $i18n.locale === 'en' ? 'AR' : 'EN'
                        }}
                    </button>
                </div>
                <div class="main-menu-wrapper">
                    <div class="menu-header">
                        <router-link to="/" class="menu-logo">
                            <img :src="require('../assets/img/logo.png')" width="50" alt="Logo">
                        </router-link>
                        <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i
                            class="fas fa-times"></i></a>
                    </div>
                    <navmenu :user="user" />
                </div>
                <ul v-if="!user" class="nav header-navbar-rht">
                    <li class="nav-item mx-2">
                        <button class="btn" @click="handleChangeLang">{{ $i18n.locale === 'en' ? 'AR' : 'EN' }}</button>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link header-reg" to="/signup">{{ $t('register') }}</router-link>
                    </li>
                    <li class="nav-item mx-2">
                        <router-link class="nav-link header-login" to="/login">{{ $t('login') }}</router-link>
                    </li>
                </ul>
                <div v-else class="d-flex justify-content-center align-items-center">
<!--                    <ul class="nav mx-2">-->
<!--                        <li class="nav-item logged-item header-navbar-rht">-->

<!--                            <div class="btn-group">-->
<!--                                <button class="btn btn-sm p-0 position-relative" type="button" id="cartButton" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--                                    <img :src="require('../assets/icons/cart.svg')" alt="cart" width="30" />-->
<!--                                    <span-->
<!--                                        v-if="cartLength"-->
<!--                                        class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">-->
<!--                                        {{ cartLength }}-->
<!--                                    </span>-->
<!--                                </button>-->
<!--                                <div :class="$i18n.locale === 'ar' ? 'dropdown-menu-end' : ' dropdown-menu-start'" class="dropdown-menu cart-dropdown" aria-labelledby="cartButton">-->
<!--                                    <div v-if="cart.length > 0">-->
<!--                                        <div class="p-2">-->
<!--                                            <div>-->
<!--                                                <div v-for="item in cart" :key="item.product.id" class="my-2">-->
<!--                                                    <div class="d-flex">-->
<!--                                                        <div class="mx-2">-->
<!--                                                            <img :src="item.product.image" alt="img" class="img-responsive rounded-pill" width="50" height="50">-->
<!--                                                        </div>-->
<!--                                                        <div class="d-flex flex-column w-100">-->
<!--                                                            <router-link :to="'/products/'+item.product.id+'/details'">-->
<!--                                                                <h6 :class="$i18n.locale === 'ar' ? 'text-end' : 'text-start'">{{ item.product.name }}</h6>-->
<!--                                                            </router-link>-->
<!--                                                            <div class="d-flex justify-content-between align-items-center mx-2">-->
<!--                                                                <div>-->
<!--                                                                    <h6 v-if="item.product.discount_price" class="m-0 main-color">{{ item.product.discount_price }} {{ $t('kwd') }}</h6>-->
<!--                                                                    <h6 v-else class="m-0 main-color">{{ item.product.price }} {{ $t('kwd') }}</h6>-->
<!--                                                                </div>-->
<!--                                                                <span class="small text-muted">{{ $t('quantity') }}: {{ item.quantity }}</span>-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                    <hr class="mx-2">-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="mx-2">-->
<!--                                        <div v-if="cart.length > 0">-->
<!--                                            <router-link to="/checkout" class="btn btn-primary w-100">{{ $t('checkout') }}</router-link>-->
<!--                                            <button class="btn btn-dark w-100 my-2" @click="handleResetCart">{{ $t('reset_cart') }}</button>-->
<!--                                        </div>-->
<!--                                        <div v-else>-->
<!--                                            <h6 class="text-center mt-2">{{ $t('cart_empty') }}</h6>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->
                    <ul class="nav mx-2 header-navbar-rht">
                        <li>
                            <button class="btn" @click="handleChangeLang">{{
                                    $i18n.locale === 'en' ? 'AR' : 'EN'
                                }}
                            </button>
                        </li>
                    </ul>
                    <div class="add-btns-li position-relative">
                        <button class="btn btn-sm btn-primary d-flex align-items-center justify-content-between add_btns" id="add_btns" data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-add mx-1" aria-hidden="true"></i> {{ $t('add') }}</button>
                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="add_btns">
                            <router-link   to="/ads/create" v-on:click="checkUser('/ads/create')"   class="btn d-block">{{ $t('add_ad') }}</router-link>
                            <router-link   to="/auctions/create" v-on:click="checkUser('/auctions/create')"   class="btn d-block">{{ $t('add_auction') }}</router-link>
                        </div>
                    </div>
                    <ul class="nav mx-5 header-navbar-rht">
                        <li class="nav-item dropdown has-arrow logged-item">
                            <a href="#" class="dropdown-toggle profile-userlink" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                <img :src="user.image" alt="">
                                <span class="mx-2 header-navbar-rht">{{ user.first_name }}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                                <!--                            <router-link class="dropdown-item" to="/dashboard">Dashboard</router-link>-->
                                <router-link class="dropdown-item" to="/profile">{{ $t('profile') }}</router-link>
                                <a class="dropdown-item" @click="handleLogout">{{ $t('logout') }}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
<!--        <verify-phone-modal id="verifyPhoneModal" />-->
    </header>
    <!-- /Header -->
</template>

<script>
import {router} from "@/router";
import axios from "axios";
import {checkAuth} from "@/shared";
// import VerifyPhoneModal from "@/components/VerifyPhoneModal";
// import firebase from '@/firebase';

export default {
    data() {
        return {
            user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            cart: [],
            cartLength: 0,
            mobileAppLink: "",
            mobileStore: "",
            dynamicLink: ""
        }
    },
    components: {
        // VerifyPhoneModal
    },
    computed: {
        cartChanged() {
            return this.$store.state.cart.cartChanged
        }
    },
    watch: {
        cartChanged: {
            handler() {
                this.handleGetCart()
            },
        }
    },
    created() {
        this.createShortLink()
    },
    mounted() {
        // this.user = JSON.parse(localStorage.getItem('user'))
        if (this.$i18n.locale === 'ar') {
            document.dir = 'rtl'
        } else {
            document.dir = 'ltr'
        }

        // if (localStorage.getItem('token')) {
        //     this.handleGetCart()
        // }



        $('body').append('<div class="sidebar-overlay"></div>');
        $(document).on('click', '#mobile_btn', function () {
            $('main-wrapper').toggleClass('slide-nav');
            $('.sidebar-overlay').toggleClass('opened');
            $('html').addClass('menu-opened');
            return false;
        });

        $(document).on('click', '.sidebar-overlay', function () {
            $('html').removeClass('menu-opened');
            $(this).removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        });

        $(document).on('click', '#menu_close', function () {
            $('html').removeClass('menu-opened');
            $('.sidebar-overlay').removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        });

        $(document).on('click', '.main-nav li', function () {
            $('html').removeClass('menu-opened');
            $('.sidebar-overlay').removeClass('opened');
            $('main-wrapper').removeClass('slide-nav');
        });

        if (navigator.userAgent.match(/Android/i)) {
            this.mobileAppLink = "https://play.google.com/store/apps/details?id=com.mazad.q8"
            this.mobileStore = this.$t('on_google_play')
        } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
            this.mobileAppLink = "https://apps.apple.com/kw/app/id6444540042"
            this.mobileStore = this.$t('on_the_app_store')
        } else if (navigator.userAgent.match(/huawei/i)) {
            this.mobileAppLink = "https://appgallery.huawei.com/app/C107966277"
            this.mobileStore = this.$t('on_app_gallery')
        }

    },
    methods: {
        checkUser(path){
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }else {
                router.replace(path)
            }
        },
        handleLogout() {
            // this.handleResetCart()
            this.$store.commit('resetCart')
            let currentLang = this.$i18n.locale
            localStorage.clear()
            localStorage.setItem('lang', currentLang)
            // this.$store.commit('resetCart')
            router.replace('/login')
        },
        handleChangeLang() {
            if (this.$i18n.locale === 'en') {
                this.$i18n.locale = 'ar'
                localStorage.setItem('lang', 'ar')
                document.dir = 'rtl'
            } else {
                this.$i18n.locale = 'en'
                localStorage.setItem('lang', 'en')
                document.dir = 'ltr'
            }
            window.location.reload()
        },
        handleGetCart() {
            axios.get(`${process.env.VUE_APP_API_URL}user/cart`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.cartLength = 0
                this.cart = res.data.data.cart.items
                this.cart.forEach(item => {
                    this.cartLength += item.quantity
                })
            }).catch(err => {
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
                checkAuth(err.response.data.message)
            })
        },
        handleResetCart() {
            this.$store.commit('resetCart')
            this.$store.commit('triggerCartChange')

            let data = {
                items: []
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/cart`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                // console.log(router.currentRoute.value.path)
                // if (router.currentRoute.value.path === '/checkout') {
                //     window.location.reload()
                // }

            }).catch(err => {
                this.$swal.fire({
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        },
        handleCloseBanner() {
            const element = document.getElementById("smartBanner");
            element.remove();
        },
        createShortLink() {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    dynamicLinkInfo: {
                        domainUriPrefix: "https://mazadkwt.page.link",
                        link: "https://mazadkwt.page.link",
                        dynamicLinkDomain: 'https://mazadkwt.page.link',
                        androidInfo: {
                            androidPackageName: "com.mazad.q8",
                            // androidFallbackLink: "https://play.google.com/store/apps/details?id=com.mazad.q8",

                        },
                        iosInfo: {
                            iosBundleId: "com.mazad.q8.mazadQ8",
                            // iosFallbackLink: "https://apps.apple.com/kw/app/id6444540042",
                            iosAppStoreId: "6444540042"

                        }
                    },
                    suffix: {
                        option: "SHORT"
                    },
                })
            };
            fetch("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC3K-DkY-v2g9LnpTorRH8UBu3Kg7Iilhc", requestOptions)
                .then(async response => {
                    const data = await response.json();

                    if (!response.ok) {
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    // console.log(data)
                    this.dynamicLink = data.shortLink;
                })
                .catch(error => {
                    this.errorMessage = error;
                    console.error('There was an error!', error);
                });
        }
    }
}
</script>
