<template>
    <!-- Privacy policy -->
	<div class="main-wrapper">
		<layouts></layouts>

		<div class="page-wrapper">
			<breadcrumb :title="title" :name="name" :text="text" :text1="text1" />
			<div class="row">


						<div class="page-title">
						   <h5>1. 	Data Deletion Request Mazad KWT
							 </h5>
							<br>
						</div>
				<p>

					At Mazad KWT, we take data privacy and security seriously. We understand that our users may want to delete their data for various reasons, and we want to make this process as easy and transparent as possible.
				</p>
							<p>
								If you would like to request that your data is deleted, please follow these steps:</p>

							<p>
								Go to your profile page in the side menu inside the app, then press "pencil" edit icon in the appbar, scroll down and press "Delete your account"</p>

							<p>
								Once we receive your request, we will verify your identity and review the data that we have collected about you.</p>

							<p>
								We will delete all data that we are legally and technically able to delete.</p>

							<p>
								Please note that some data may be necessary for us to provide certain features or services, or for legal or regulatory reasons. </p>
							<p>
								In these cases, we will inform you of the data that we are unable to delete and the reasons why.</p>

							<p>
								If you have any questions or concerns about this process, please don't hesitate to contact us at mazadkwt.kw@gmail.com.</p>

							<p>
					Thank you for using  Mazad KWT, and for trusting us with your data.</p>
			</div>

							<foot />

							<scroll />
						</div>
				</div>


		<!-- /Privacy policy -->
</template>
<script>
export default {
	data() {
		return {
			title: "Delete Policy",
			text: "Home",
			text1: "Delete Policy",
			name: "/",
		}
	},
}
</script>