<template>
    <div class="main-wrapper">
        <layouts></layouts>

        <div class="page-wrapper">
            <breadcrumb :title="title" :name="name" :text="text" :text1="text1" />

            <index-banners type="3" />

            <auctions-listing :home-category="$route.params.category ?? 'all'" />

            <foot />

            <scroll />

<!--            <router-view :key="$route.fullPath"></router-view>-->
        </div>
    </div>
</template>

<script>
import AuctionsListing from "@/views/pages/auctions/AuctionsListing";
import IndexBanners from "@/views/pages/index/IndexBanners";
export default {
    components: {
        AuctionsListing,
        IndexBanners
    },
    data() {
        return {
            title: this.$t('auctions'),
            text: this.$t('home'),
            text1: this.$t('auctions'),
            name: "/",
        }
    },
}
</script>
