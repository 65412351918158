<template>
    <b-tabs content-class="mt-3" fill pills lazy>
        <b-tab title-item-class="col-md-3 mb-3" :title="$t('auctions')" active>
            <profile-auctions :user-id="userId" type="wishlist" />
        </b-tab>
        <b-tab title-item-class="col-md-3 mb-3" :title="$t('ads')">
            <profile-ads :user-id="userId" type="wishlist" />
        </b-tab>
        <b-tab title-item-class="col-md-3 mb-3" :title="$t('products')">
            <profile-products :user-id="userId" type="wishlist" />
        </b-tab>
    </b-tabs>
</template>
<script>
import ProfileAuctions from "@/views/pages/profile/ProfileAuctions";
import ProfileAds from "@/views/pages/profile/ProfileAds";
import ProfileProducts from "@/views/pages/profile/ProfileProducts";

export default {
    props: {
        userId: {
            type: Number,
            required: true
        }
    },
    components: {
        ProfileAuctions,
        ProfileAds,
        ProfileProducts
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
    }
}
</script>
