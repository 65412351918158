<template>
    <div class="location-info-details d-flex align-items-center index-ad-box">
        <div class="location-img">
            <router-link :to="'/ads/'+ad.id+'/details'"><img :src="ad.image" width="100" height="100" alt="ads"></router-link>
        </div>
        <div class="location-content">
            <h6 :id="`title-header-${ad.id}`"><router-link :to="'/ads/'+ad.id+'/details'" class="direction-auto">{{ ad.title }}</router-link></h6>
            <p>{{ ad.category.name }}</p>
            <span class="main-color small">{{ ad.price }} {{ $t('kwd') }} </span>
            <router-link :to="'/ads/'+ad.id+'/details'" class="view-detailsbtn">{{ $t('view_details') }}</router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        ad: {
            type: Object,
            required: true
        }
    },
    mounted() {
        let arabicRegex = /[\u0600-\u06FF]/
        let title = this.ad.title
        if(arabicRegex.test(title)) {
            $('#title-header-'+this.ad.id).attr('style', 'direction: rtl !important')
        } else {
            $('#title-header-'+this.ad.id).attr('style', 'direction: ltr !important')
        }
    }
}
</script>
