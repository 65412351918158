<template>
    <!-- Footer -->
		<footer class="footer"> <!--  :class="noScroll ? ' position-absolute w-100 bottom-0' : ''" -->
<!--		    <div class="container">
			   	<div class="stay-tuned">
			        <h3>Stay Tuned With Us</h3>
			        <p>Subcribe to our newletter and never miss our latest news and promotions. Our newsletter is sent once a week, every thursday.</p>
			        <b-form>
                        <div class="form-group">
						    <div class="group-img">
								<i class="feather-mail"></i>
								<b-form-input type="text" class="form-control" placeholder="Enter Email Address" />
							</div>
						</div>
                        <b-button variant="primary" type="submit"> Subscribe</b-button>
                    </b-form>
				</div>
			</div>-->

			<!-- Footer Top -->
<!--			<div class="footer-top aos" data-aos="fade-up">
				<div class="container">
					<div class="row">
						<div class="col-lg-3 col-md-6">
							&lt;!&ndash; Footer Widget &ndash;&gt;
							<div class="footer-widget">
								<div class="footer-logo">
									<router-link to="#"><img src="../../assets/img/footerlogo.svg" alt="logo"></router-link>
								</div>
								<div class="footer-content">
									<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt et magna aliqua.  </p>
								</div>
								<div class="social-icon">
									<ul>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-facebook-f"></i> </router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-twitter"></i> </router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-instagram"></i></router-link>
										</li>
										<li>
											<router-link to="#" target="_blank"><i class="fab fa-linkedin-in"></i></router-link>
										</li>
									</ul>
                                </div>
							</div>
							&lt;!&ndash; /Footer Widget &ndash;&gt;
						</div>
						<div class="col-lg-2 col-md-6">
							&lt;!&ndash; Footer Widget &ndash;&gt;
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">About us</h2>
								<ul>
									<li>
										<router-link to="/about">Our product</router-link>
									</li>
									<li>
										<a href="javascript:void(0)">Documentation</a>
									</li>
									<li>
										<router-link to="/service-details">Our Services</router-link>
									</li>
									<li>
										<a href="javascript:void(0)">Get Started Us</a>
									</li>
									<li>
										<router-link to="/contact">Contact Us</router-link>
									</li>
								</ul>
							</div>
							&lt;!&ndash; /Footer Widget &ndash;&gt;
						</div>
						<div class="col-lg-2 col-md-6">
							&lt;!&ndash; Footer Widget &ndash;&gt;
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Quick links</h2>
								<ul>
									<li>
										<a href="javascript:void(0)">Market Place</a>
									</li>
									<li>
										<a href="javascript:void(0)">Documentation</a>
									</li>
									<li>
										<a href="javascript:void(0)">Customers</a>
									</li>
									<li>
										<a href="javascript:void(0)">Carriers</a>
									</li>
									<li>
										<router-link to="blog-list">Our Blog</router-link>
									</li>
								</ul>
							</div>
							&lt;!&ndash; /Footer Widget &ndash;&gt;
						</div>
						<div class="col-lg-2 col-md-6">
							&lt;!&ndash; Footer Widget &ndash;&gt;
							<div class="footer-widget footer-menu">
								<h2 class="footer-title">Top Cities</h2>
								<ul>
									<li>
										<a href="javascript:void(0)">Manhatten</a>
									</li>
									<li>
										<a href="javascript:void(0)">Los Angeles</a>
									</li>
									<li>
										<a href="javascript:void(0)">Houston</a>
									</li>
									<li>
										<a href="javascript:void(0)">Chicago</a>
									</li>
									<li>
										<a href="javascript:void(0)">Alabama</a>
									</li>
								</ul>
							</div>
							&lt;!&ndash; /Footer Widget &ndash;&gt;
						</div>
						<div class="col-lg-3 col-md-6">
							&lt;!&ndash; Footer Widget &ndash;&gt;
							<div class="footer-widget">
								<h2 class="footer-title">Communication</h2>
								<div class="footer-contact-info">
										<div class="footer-address">
											<img src="../../assets/img/call-calling.svg" alt="Callus">
											<p><span>Call Us</span> <br> +017 123 456 78	 </p>
										</div>
										<div class="footer-address">
											<img src="../../assets/img/sms-tracking.svg" alt="Callus">
											<p><span>Send Message</span> <br> listee@example.com	 </p>
										</div>
								</div>
							</div>
							&lt;!&ndash; /Footer Widget &ndash;&gt;
						</div>
					</div>

					&lt;!&ndash; Footer Counter Section&ndash;&gt;
					<div class="footercount">
						    <div class="row">
							         <div class="col-lg-3 col-md-3">
									    <div class="vistors-details">
										    <p>Our Unique Visitor</p>
											<p class="visitors-value">25,329,532</p>
										</div>
									 </div>
									  <div class="col-lg-3 col-md-3">
									      <div class="vistors-details">
										    <p>Our Unique Visitor</p>
											<p class="visitors-value">25,329,53264546</p>
										</div>
									 </div>
									 <div class="col-lg-3 col-md-3">
									     <div class="vistors-details">
										    <p>Our Unique Visitor</p>
											<p class="visitors-value">25,329,53264546</p>
										</div>
									 </div>
									  <div class="col-lg-3 col-md-3">
									    <div class="vistors-details">
										    <p>We Accept</p>
											<ul class="d-flex">
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../assets/img/amex-pay.svg" alt="amex"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../assets/img/apple-pay.svg" alt="pay"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../assets/img/gpay.svg" alt="gpay"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../assets/img/master.svg" alt="paycard"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../assets/img/phone.svg" alt="spay"></a></li>
												<li><a href="javascript:void(0)"><img class="img-fluid" src="../../assets/img/visa.svg" alt="visa"></a></li>
											</ul>
										</div>
									 </div>
							</div>
					</div>
			        &lt;!&ndash; /Footer  Counter Section&ndash;&gt;

				</div>
			</div>-->
			<!-- /Footer Top -->

			<!-- Footer Bottom -->
			<div class="footer-bottom">
				<div class="container">
					<!-- Copyright -->
					<div class="copyright">
						<div class="row">
							<div class="col-md-12 pt-2">
                                <div class="d-flex flex-column justify-content-center align-items-center">
                                    <p class="text-white">{{ $t('footer_header') }}</p>
                                    <h6 class="text-white">{{ $t('download_app') }}</h6>
                                    <div class="row d-flex justify-content-center my-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.mazad.q8" target="_blank" class="col-md-4 my-2 text-center">
                                            <img :src="require('@/assets/icons/googlePlay.webp')" :alt="$t('google_play')" loading="lazy" class="app-icons" />
                                        </a>
                                        <a href="https://apps.apple.com/us/app/%D9%85%D8%B2%D8%A7%D8%AF-%D8%A7%D9%84%D9%83%D9%88%D9%8A%D8%AA/id6444540042" target="_blank" class="col-md-4 my-2 text-center">
                                            <img :src="require('@/assets/icons/appStore.webp')" :alt="$t('app_store')" loading="lazy" class="app-icons" />
                                        </a>
                                        <a href="https://appgallery.huawei.com/app/C107966277" target="_blank" class="col-md-4 my-2 text-center">
                                            <img :src="require('@/assets/icons/appGallery.webp')" :alt="$t('app_gallery')" loading="lazy" class="app-icons" />
                                        </a>
                                    </div>
                                </div>
								<div class="copyright-text my-3">
									<p class="mb-0 text-center">{{ $t('all_copyrights_reserved') }} © {{ new Date().getFullYear() }} - Sigma Tech.</p>
								</div>
							</div>
<!--							<div class="col-md-6">-->
<!--								<div class="copyright-menu">-->
<!--									<ul class="policy-menu">-->
<!--										<li>-->
<!--											<router-link to="/privacy-policy">Privacy </router-link>-->
<!--										</li>-->
<!--										<li>-->
<!--											<router-link to="/faq">Faq </router-link>-->
<!--										</li>-->
<!--										<li>-->
<!--											<router-link to="/terms-condition">Terms</router-link>-->
<!--										</li>-->
<!--									</ul>-->
<!--								</div>-->
<!--							</div>-->
						</div>
					</div>
					<!-- /Copyright -->
				</div>
			</div>
			<!-- /Footer Bottom -->

		</footer>
        <verify-phone-modal id="verifyPhoneModal" />
		<!-- /Footer -->
</template>

<script>
    import VerifyPhoneModal from "@/components/VerifyPhoneModal";

    export default {
        props: {
            shortPage: {
                type: Boolean,
                required: false
            }
        },
        data() {
          return {
            noScroll: false
          }
        },
        components: {
            VerifyPhoneModal
        },
        mounted() {
            if(document.body.scrollHeight > document.body.clientHeight) {
                this.noScroll = false
            }
            window.onscroll = () => {
                if(document.body.scrollHeight > document.body.clientHeight) {
                    this.noScroll = false
                }
            }
        },
        // watch: {
        //     [document.body.scrollHeight]: {
        //         handler(){
        //             setTimeout(() => {
        //                 if(document.body.scrollHeight > document.body.clientHeight) {
        //                     this.noScroll = false
        //                 } else {
        //                     this.noScroll = true
        //                 }
        //             }, 1000)
        //         },
        //         deep: true,
        //         immediate: true
        //     }
        // }
    }
</script>

<style scoped>
.app-icons {
    width: 200px;
}
</style>
