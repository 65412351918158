<template>
    <div class="card flex-fill">
        <div class="blog-widget">
            <div class="blog-img">
                <img v-if="type === 'archive'" :src="auction.image" class="card-image" alt="Auction Image" loading="lazy" />
                <router-link v-else :to="'/auctions/'+auction.id+'/details'">
                    <img :src="auction.image" class="card-image" alt="Auction Image" loading="lazy" />
                </router-link>
                <div v-if="authUser" class="fav-item d-flex justify-content-between align-items-center">
                    <!--                                        <span class="Featured-text">Featured</span>-->
                    <button :class="auction.is_wishlist ? 'btn selected fav-icon' : 'btn fav-icon'" @click="handleToggleWishlist(auction.id)"><i class="feather-heart"></i></button>
                </div>
            </div>
            <div class="bloglist-content">
                <div class="card-body">
                    <div class="blogfeaturelink">
                        <!--                                            <div class="grid-author">-->
                        <!--                                                <img :src="require(`../../../assets/img/profiles/${auction.img2}`)" alt="author" />-->
                        <!--                                            </div>-->
                        <div v-if="auction.category" class="blog-features">
                            <span> <i class="fa-regular fa-circle-stop"></i> {{ auction.category.name }}</span>
                        </div>
                        <div class="blog-author">
                            <span class="d-flex align-items-baseline"> <i class="feather-eye mx-1"></i> {{ auction.views }} </span>
                        </div>
                    </div>
                    <h6 v-if="type === 'archive'" :id="`title-header-${auction.id}`"><span class="direction-auto">{{ auction.title }}</span></h6>
                    <h6 v-else :id="`title-header-${auction.id}`"><router-link :to="'/auctions/'+auction.id+'/details'" class="direction-auto">{{ auction.title }}</router-link></h6>
                    <div class="blog-location-details">
                        <div class="location-info">
                            <i class="fa-solid fa-gavel mx-1"></i> {{ auction.bids_count }}
                        </div>
                        <div class="location-info">
                            <p><i class="fa-solid fa-calendar-days mx-1"></i> {{ auction.created_at }}</p>
                        </div>
                    </div>
                    <div class="amount-details">
                        <div class="amount">
                            <span class="validrate">{{ auction.min_price }} {{ $t('kwd') }}</span>
                        </div>
                        <!--                                            <div class="ratings">-->
                        <!--                                                <span>{{ auction.rating}}</span> (50)-->
                        <!--                                            </div>-->
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center my-2 bg-default-light rounded small p-1">
                        <div>
                            <img :src="require('../../src/assets/icons/flames.png')" width="18" />
                        </div>
                        <div>{{ handleExpirationDate(auction.expiration_date) }}</div>
                    </div>
                    <div v-if="type === 'archive'" class="row mt-3">
                        <div class="col-md-6 mt-1">
                            <button class="btn btn-primary w-100" @click="handleRepublish">{{ $t('republish') }}</button>
                        </div>
                        <div class="col-md-6 mt-1">
                            <button class="btn btn-danger w-100" @click="handleDelete">{{ $t('delete') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {router} from "@/router";

export default {
    props: {
        auction: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        }
    },
    emits: ['updateAuctionInList'],
    mounted() {
        let arabicRegex = /[\u0600-\u06FF]/
        let title = this.auction.title
        if(arabicRegex.test(title)) {
            $('#title-header-'+this.auction.id).attr('style', 'direction: rtl !important')
        } else {
            $('#title-header-'+this.auction.id).attr('style', 'direction: ltr !important')
        }
    },
    methods: {
        handleExpirationDate(expirationDate) {
            let expDate = new Date(expirationDate).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()

            let delta = Math.abs(expDate - currentDate) / 1000;

            let days = Math.floor(delta / 86400);
            delta -= days * 86400;

            let hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            let minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // let seconds = delta % 60;

            return days + ` ${this.$t('d')} ` + hours + ` ${this.$t('h')} ` + minutes + ` ${this.$t('m')} `

        },
        handleToggleWishlist(id) {
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }
            let data = {
                target_id: id,
                target_type: 'auction'
            }
            axios.post(`${process.env.VUE_APP_API_URL}user/toggle-wishlist`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    title: res.data.message,
                    icon: 'success',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })

                this.$emit('updateAuctionInList', id, res.data.data.item.target)
            }).catch(err => {
                console.log(err)
            })
        },
        handleRepublish() {
            axios.patch(`${process.env.VUE_APP_API_URL}user/auctions/${this.auction.id}/republish`, {},{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(response => {
                this.$swal.fire({
                    icon: 'success',
                    title: response.data.message,
                    confirmButtonText: this.$t('ok'),
                    allowOutsideClick: false,
                }).then(res => {
                    router.replace('/auctions/all')
                })
            }).catch(error => {
                this.$swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
            })
        },
        handleDelete() {
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('are_you_sure'),
                showDenyButton: true,
                denyButtonText: this.$t('no'),
                confirmButtonText: this.$t('yes'),
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${process.env.VUE_APP_API_URL}user/auctions/${this.auction.id}`,{
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(response => {
                        this.$swal.fire({
                            icon: 'success',
                            title: response.data.message,
                            confirmButtonText: this.$t('ok'),
                            allowOutsideClick: false,
                        }).then(res => {
                            this.$emit('updateAuctionInList', this.auction.id, null)
                        })
                    }).catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    })

                }
            })
        }
    }
}
</script>
