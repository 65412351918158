<template>
    <!-- Pricing Plan Section -->
		<section class="pricingplan-section how-work">
		     	<div class="section-heading">
						<div class="container">				
							 <div class="row text-center">
									<h2>Our Pricing <span>Pla</span>n</h2>
									<p>You can set to charge users for Packages, Subscriptions, Hybrid or Free</p>
							 </div>									 
						</div>	 
				</div>
				<div class="container">
					<div class="row">
					    <div class="col-lg-3 d-flex col-md-6">
						    <div class="price-card flex-fill">
								<div class="price-head">
									<div class="price-level">
										<h6>Intro</h6>
									</div>
									<h4>$10 <span>/ month</span></h4>								
								</div>	
								<div class="price-body">
									<p>For most business that want to optimize web queries</p>
									<ul>
										<li class="active">Basic listing submission</li>
										<li class="active">One Listing</li>
										<li class="active">30 days Availabilty</li>
										<li class="inactive">Limited Support</li>
										<li class="inactive">Edit your listing</li>
									</ul>
									<div>
										<router-link to="login" class="btn viewdetails-btn">View details</router-link>
									</div>							
								</div>							
						    </div>
						</div>
						<div class="col-lg-3 d-flex col-md-6">
						    <div class="price-card flex-fill">
								<div class="price-head">
									<div class="price-level">
										<h6>Basic</h6>
									</div>
									<h4>$25 <span>/ month</span></h4>								
								</div>	
								<div class="price-body">
									<p>For most business that want to optimize web queries</p>
									<ul>
										<li class="active">Basic listing submission</li>
										<li class="active">One Listing</li>
										<li class="active">30 days Availabilty</li>
										<li class="inactive">Limited Support</li>
										<li class="inactive">Edit your listing</li>
									</ul>
									<div>
										<router-link to="login" class="btn viewdetails-btn">View details</router-link>
									</div>							
								</div>							
						    </div>	
						</div>
						<div class="col-lg-3 d-flex col-md-6">
						    <div class="price-card flex-fill">
								<div class="price-head">
									<div class="price-level">
										<h6>Popular</h6>
									</div>
									<h4>$50 <span>/ month</span></h4>									
								</div>	
								<div class="price-body">
									<p>For most business that want to optimize web queries</p>
									<ul>
										<li class="active">Basic listing submission</li>
										<li class="active">One Listing</li>
										<li class="active">30 days Availabilty</li>
										<li class="inactive">Limited Support</li>
										<li class="inactive">Edit your listing</li>
									</ul>
									<div>
										<router-link to="login" class="btn viewdetails-btn">View details</router-link>
									</div>							
								</div>							
						    </div>
						</div>
						<div class="col-lg-3 d-flex col-md-6">
						     <div class="price-card flex-fill">
								<div class="price-head">
									<div class="price-level">
										<h6>Enterprise</h6>
									</div>
									<h4>$100 <span>/ month</span></h4>									
								</div>	
								<div class="price-body">
									<p>For most business that want to optimize web queries</p>
									<ul>
										<li class="active">Basic listing submission</li>
										<li class="active">One Listing</li>
										<li class="active">30 days Availabilty</li>
										<li class="inactive">Limited Support</li>
										<li class="inactive">Edit your listing</li>
									</ul>
									<div>
										<router-link to="login" class="btn viewdetails-btn">View details</router-link>
									</div>							
								</div>							
						    </div>
						</div>
					</div>
				</div>
		</section>
		<!-- /Pricing Plan Section -->
</template>