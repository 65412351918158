<template>
    <div class="card h-25">
        <div class="card-body d-block pb-2 mb-2">
            <div class="d-flex justify-content-between align-items-start">
                <div class="author-headings d-flex justify-content-start mb-3">
                    <div class="author-img">
                        <img :src="bid.user.image" alt="authorimg">
                    </div>
                    <div class="authordetails mx-2">
                        <h6>{{ bid.user.full_name }}</h6>
                        <p>{{bid.price}} {{ $t('kwd') }}</p>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <span class="small mx-2">{{ handleBidDate() }}</span>
                    <div v-if="authUser" class="dropdown ">
                        <a href="#" class="profile-userlink" data-bs-toggle="dropdown"
                           aria-expanded="false">
                            ...
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <button v-if="bid.user.id !== authUser.id" class="dropdown-item" @click="reportBid(bid.id)">{{ $t('report') }}</button>
                            <button v-if="bid.user.id === authUser.id" class="dropdown-item" @click="deleteBid(bid.id)">{{ $t('delete') }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="auctionUserId && authUser && auctionUserId === authUser.id" class="mx-3">
                <i class="fa-solid fa-phone main-color fs-6"></i>
                <span class="small mx-1">{{bid.user.phone}} </span>
            </div>
            <div v-else class="mx-3">
                <i class="fa-solid fa-phone main-color fs-6"></i>
                <span v-if="authUser && bid.user.id === authUser.id" class="small mx-1">{{bid.user.phone}} </span>
                <span v-else class="small mx-1">{{bid.user.phone.slice(0, 4)}}xxxx </span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: 'auction-bid',
    props: {
        bid: {
            type: Object,
            required: true
        },
        auctionId: {
            type: Number,
            required: true
        },
        auctionUserId: {
            type: [Number, null],
            required: true
        }
    },
    emits:["removeBid"],
    data() {
        return {
            reply: '',
            repliesShown: false,
            authUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            date: ''
        }
    },
    methods: {
        handleBidDate() {
            let createdDate = new Date(this.bid.created_at).getTime()
            let currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kuwait" })).getTime()
            // get total seconds between the times
            var delta = Math.abs(currentDate - createdDate) / 1000;

            // calculate (and subtract) whole days
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;

            // calculate (and subtract) whole hours
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;

            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;

            // what's left is seconds
            var seconds = delta % 60;  // in theory the modulus is not required

            if (days)
                return days + ` ${this.$t('d')} `
            else if (hours)
                return hours + ` ${this.$t('h')} `
            else if (minutes)
                return minutes + ` ${this.$t('m')} `
            else
                return seconds + ` ${this.$t('s')}`
        },
        deleteBid(id) {
            let deletedBid = this.bid
            this.$swal.fire({
                icon: 'warning',
                title: this.$t('delete_bid'),
                text: this.$t('are_you_sure'),
                showDenyButton: true,
                confirmButtonText: this.$t('yes'),
                denyButtonText: this.$t('no')
            }).then(result => {
                if(result.isConfirmed) {

                    axios.delete(`${process.env.VUE_APP_API_URL}user/bids/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    }).then(res => {
                        this.$emit('removeBid', deletedBid)

                        this.$swal.fire({
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            toast: true,
                            timer: 5000,
                            position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                        })
                    }).catch(err => {
                        if (err.response.data.message === 'Unauthenticated') {
                            this.$swal.fire({
                                icon: 'error',
                                title: this.$t('you_have_to_login'),
                                showConfirmButton: false,
                                toast: true,
                                timer: 5000,
                                position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                            })
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: err.response.data.message,
                                showConfirmButton: false,
                                toast: true,
                                timer: 5000,
                                position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                            })
                        }
                    })
                }
            })
        },
        reportBid(id) {
            this.$swal.fire({
                title: this.$t('report_this_bid'),
                input: 'textarea',
                inputAttributes: {
                    autocapitalize: 'off',
                    required: true
                },
                showDenyButton: true,
                denyButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('report'),
                showLoaderOnConfirm: true,
                preConfirm: (reason) => {
                    let data = {
                        comment: reason,
                        target_type: 'review',
                        target_id: id
                    }
                    return axios.post(`${process.env.VUE_APP_API_URL}user/reports`, data, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Accepted-Language': this.$i18n.locale
                        }
                    })
                        .then(response => {
                            return response.data.message
                        })
                        .catch(error => {
                            this.$swal.showValidationMessage(
                                error
                            )
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire({
                        icon: 'success',
                        title: result.value,
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        }
    }
}
</script>
