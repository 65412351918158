<template>
    <Dropdown
        :distance="6"
        placement="top"
        class="comment-gif-button"
    >
        <!-- This will be the popover reference (for the events and position) -->
        <button class="btn btn-secondary">GIF</button>

        <!-- This will be the content of the popover -->
        <template #popper>
            <div class="p-3 gifs-container">
                <input v-model="gifSearch" class="form-control" :placeholder="$t('search')" />
                <div class="d-flex justify-content-center align-items-center my-1">
                    <button
                        class="btn btn-sm"
                        :class="{
                            'btn-primary': gifFilter === 'all',
                            'btn-light': gifFilter !== 'all'
                        }"
                        @click="gifFilter = 'all'"
                    >{{ $t('all') }}</button>
                    <button
                        class="btn btn-sm mx-2"
                        :class="{
                            'btn-primary': gifFilter === 'preferred',
                            'btn-light': gifFilter !== 'preferred'
                        }"
                        @click="gifFilter = 'preferred'"
                    >{{ $t('favorite') }}</button>
                    <button
                        class="btn btn-sm"
                        :class="{
                            'btn-primary': gifFilter === 'recently',
                            'btn-light': gifFilter !== 'recently'
                        }"
                        @click="gifFilter = 'recently'"
                    >{{ $t('recently') }}</button>
                </div>
                <div class="my-2 row gifs-box">
                    <div v-for="gif in gifs" :key="gif.id" class="col-6 col-sm-3 col-md-3 my-1">
<!--                        <img :src="gif.gif" class="img-thumbnail gif-img cursor-pointer" @click="() => handleSelectGif(gif)" loading="lazy" v-close-popper />-->
                        <div class="py-2 position-relative fit-content">
                            <img :src="gif.gif" class="gif-img cursor-pointer" @click="() => handleSelectGif(gif)" loading="lazy" v-close-popper />
                            <button v-if="!gif.isLiked" class="btn btn-danger-light py-2 btn-sm rounded-circle position-absolute remove-gif d-flex" @click="handleAddToFavorite(gif.id)" :title="$t('add_to_favorite')">
                                <span class="fa fa-heart-broken"></span>
                            </button>
                            <button v-else class="btn btn-danger-light py-2 btn-sm rounded-circle position-absolute remove-gif d-flex" @click="handleRemoveFromFavorite(gif.id)" :title="$t('remove_from_favorite')">
                                <span class="fa fa-heart"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="loadMoreFlag" class="text-center">
                    <button class="btn btn-dark" @click="getGifs(true)">{{ $t('load_more') }}</button>
                </div>
            </div>
        </template>
    </Dropdown>
</template>
<script>
import {
    Dropdown
} from 'floating-vue'
import axios from "axios";

export default {
    components: {
        Dropdown
    },
    data() {
        return {
            gifs: [],
            gifCurrentPage: 1,
            gifSearch: '',
            gifSearchTimer: null,
            gifFilter: 'all',
            loadMoreFlag: true
        }
    },
    emits: ['handleSelectGif'],
    watch: {
        gifSearch() {
            clearTimeout(this.gifSearchTimer)
            this.gifSearchTimer = setTimeout(() => {
                this.getGifs()
            }, 500)
        },
        gifFilter() {
            this.gifCurrentPage = 1
            this.getGifs()
        }
    },
    mounted() {
        this.getGifs()
    },
    methods: {
        getGifs(loadMore = false) {
            let filters = ``
            let gifType = ''

            if(!this.gifSearch) {
                filters += `page=${this.gifCurrentPage}`
            } else {
                filters += `filters[search]=${this.gifSearch}`
            }

            if(this.gifFilter === 'preferred') {
                gifType = `&filters[preferred]=true`
            }

            if(this.gifFilter === 'recently') {
                gifType = `&filters[recently]=true`
            }
            filters += gifType
            axios.get(`${process.env.VUE_APP_API_URL}user/comments/gifs?${filters}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(loadMore) {
                    this.gifs = [...this.gifs, ...res.data.data.items]
                } else {
                    this.gifs = res.data.data.items
                }
                if (res.data.data.current_page === res.data.data.last_page) {
                    this.loadMoreFlag = false
                } else {
                    this.loadMoreFlag = true
                }
                this.gifCurrentPage = res.data.data.current_page + 1
            }).catch(err => {
                console.log(err)
            })
        },
        handleSelectGif(gif) {
            this.$emit('handleSelectGif', gif)
        },
        handleAddToFavorite(id) {
            axios.put(`${process.env.VUE_APP_API_URL}user/gifs/${id}/like`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
                let selectedGifIndex = this.gifs.map(gi => gi.id).indexOf(id)
                if(selectedGifIndex >= 0)
                    this.gifs[selectedGifIndex].isLiked = true
            }).catch(err => {
                if (err.response.data.message === 'Unauthenticated') {
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('you_have_to_login'),
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        },
        handleRemoveFromFavorite(id) {
            axios.put(`${process.env.VUE_APP_API_URL}user/gifs/${id}/like`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.$swal.fire({
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                })
                let selectedGifIndex = this.gifs.map(gi => gi.id).indexOf(id)
                if(selectedGifIndex >= 0)
                    this.gifs[selectedGifIndex].isLiked = false
            }).catch(err => {
                if (err.response.data.message === 'Unauthenticated') {
                    this.$swal.fire({
                        icon: 'error',
                        title: this.$t('you_have_to_login'),
                        showConfirmButton: false,
                        toast: true,
                        timer: 5000,
                        position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    })
                }
            })
        }
    },
}
</script>
