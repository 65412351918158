<template>
    <!-- Main Content Section -->
    <div class="list-content">
        <div class="container">
            <div class="row">
                <div class="my-3 col-lg-3 theiaStickySidebar">
                    <div class="listings-sidebar stickysidebar">
                        <div class="card">
                            <h4><img src="../../../assets/img/details-icon.svg" alt="details-icon"> <span class="mx-2">{{ $t('filters') }}</span>
                            </h4>
                            <b-form>
<!--                                <div class="filter-content looking-input form-group">-->
<!--                                    <b-form-input v-model="search" type="text" class="form-control"-->
<!--                                                  :placeholder=" $t('what_are_you_looking_for') "/>-->
<!--                                </div>-->
                                <div class="filter-content form-group filter-category-box">
                                    <category-select
                                        @set-category="handleSetCategory"
                                        :categories="categories"
                                        :is-filter="true"
                                        :reset="resetCategory"
                                    />
                                </div>
                                <div class="filter-content amenities mb-0">
                                    <div class="search-btn">
                                        <b-button variant="primary" @click="getAds(false, true)"><i class="fa fa-search"
                                                                                            aria-hidden="true"></i>
                                            {{ $t('search') }}
                                        </b-button>
                                        <b-button variant="reset mb-0" @click="handleResetFilters"><i
                                            class="fas fa-light fa-arrow-rotate-right"></i> {{ $t('reset_filters') }}
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row sorting-div">
                        <div class="col-lg-4 col-md-4 col-sm-4 align-items-center d-flex">
                            <div class="count-search">
                                <p>{{ $t('showing') }} {{ showing }} {{ $t('of') }} {{ totalAds }} {{
                                        $t('results')
                                    }}</p>
                            </div>
                        </div>
<!--                        <div class="col-lg-8 col-md-8 col-sm-8  align-items-center">-->
<!--                            <div class="sortbyset">-->
<!--                                <span class="sortbytitle mx-2">{{ $t('sort_by') }}</span>-->
<!--                                <div class="sorting-select">-->
<!--                                    <vue-select-->
<!--                                        v-model="sortBy"-->
<!--                                        class="select"-->
<!--                                        :options="Default"-->
<!--                                        label="label"-->
<!--                                        :placeholder="$t('default')"-->
<!--                                    />-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <div class="grid-view listgrid-sidebar">
                        <div class="row" v-if="ads.length > 0">
                            <div class="col-lg-4 col-md-4" v-for="item in ads" :key="item.id">
                                <commercial-ad :ad="item" />
                            </div>

                        </div>
                        <div v-else-if="loading" class="text-center">
                            <div class="spinner-border text-dark" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div v-else class="text-center my-5 py-5">
                            <h3>{{ $t('no_results') }}</h3>
                        </div>
                    </div>
                    <div class="text-center">
                        <button v-if="currentPage < totalPages" class="btn btn-primary" @click="handleLoadMore">
                            {{ $t('load_more') }}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- /Main Content Section -->
</template>

<script>
import axios from "axios";
import CategorySelect from "@/components/form/CategorySelect";
import {router} from "@/router";
import CommercialAd from "@/views/pages/commercial-ads/CommercialAd";

export default {
    props: {
        homeCategory: {
            type: String,
            required: true
        }
    },
    components: {
        CommercialAd,
        CategorySelect,
    },
    data() {
        return {
            Default: [
                {
                    by: 'price',
                    type: 'DESC',
                    label: this.$t('price_high_to_low'),
                },
                {
                    by: 'price',
                    type: 'ASC',
                    label: this.$t('price_low_to_high'),
                },
                {
                    by: 'comments_count',
                    type: 'DESC',
                    label: this.$t('comments_count_high_to_low'),
                },
                {
                    by: 'comments_count',
                    type: 'ASC',
                    label: this.$t('comments_count_low_to_high'),
                },
            ],
            sortBy: null,
            ads: [],
            categories: [],
            subCategories: [],
            search: '',
            category: null,
            subCategory: null,
            currentPage: 1,
            totalPages: 1,
            totalAds: 0,
            showing: 0,
            perPage: 15,
            loading: true,
            resetCategory: false,
            grandCategory: null,
            user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
            selectedImage: null,
        };
    },
    watch: {
        grandCategory() {
            if (!this.grandCategory) {
                this.grandCategory = null
                this.category = null
                this.subCategory = null
                this.subCategories = []
                // this.resetCategory = true
            }
        },
        category() {
            if (!this.category) {
                this.category = null
                this.subCategory = null
                this.subCategories = []
                // this.resetCategory = true
            }
        },
        sortBy() {
            this.currentPage = 1
            this.getAds()
        }
    },
    created() {
        $('.fav-icon').on('click', function () {
            $(this).toggleClass('selected');
        });

        this.getCategories()
    },
    methods: {
        checkUser(){
            if(!localStorage.getItem('user')){
                this.$swal.fire({
                    title: this.$t('you_have_to_login'),
                    icon: 'error',
                    toast: true,
                    timer: 5000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
                router.replace('/login')
            }
        },
        getAds(more = false, resetPage = false) {
            if(resetPage)
                this.currentPage = 1

            let data = {
                category_id: this.subCategory ?? this.category ?? this.grandCategory,
                search: this.search
            }
            let url = `${process.env.VUE_APP_API_URL}commercial-ads?page=${this.currentPage}`
            // if (this.search || this.subCategory)
            //     url = `${process.env.VUE_APP_API_URL}ads`

            if(this.sortBy) {
                data.sort_by = this.sortBy.by
                data.sort_by_type = this.sortBy.type
            }
            axios.post(url, {
                filters: data
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                if(more) {
                    this.ads = [...this.ads, ...res.data.data.items]
                    this.showing += res.data.data.count
                } else {
                    this.ads = res.data.data.items
                    this.showing = res.data.data.count
                }
                this.totalPages = res.data.data.last_page
                this.totalAds = res.data.data.total
                this.perPage = res.data.data.per_page
                this.loading = false
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },
        handleLoadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++

                this.getAds(true)
            } else {
                this.$swal.fire({
                    title: this.$t('no_more'),
                    icon: 'error',
                    toast: true,
                    timer: 2000,
                    position: this.$i18n.locale === 'en' ? 'top-end' : 'top-start',
                    showConfirmButton: false,
                })
            }
        },
        getCategories() {
            axios.get(`${process.env.VUE_APP_API_URL}categories?type=5`, {
                headers: {
                    'Accepted-Language': this.$i18n.locale
                }
            }).then(res => {
                this.categories = res.data.data.items
                if(this.homeCategory !== 'all') {
                    this.category = this.categories.filter(cat => cat.name === this.homeCategory)[0]
                    this.subCategories = this.category.sub_categories
                }
                // res.data.data.items.map(cat => {
                //     if (cat.sub_categories.length > 0) {
                //         this.subCategories = [...this.subCategories, ...cat.sub_categories]
                //     }
                // })

                this.getAds()
            }).catch(err => {
                console.log(err)
            })
        },
        handleChangeCategory() {
            this.subCategories = this.categories.filter(cat => cat.id === this.category)[0]?.sub_categories
        },
        handleResetFilters() {
            this.search = ''
            this.grandCategory = null
            this.category = null
            this.subCategory = null
            this.resetCategory = true
            this.getAds()
        },
        handleSetCategory(id, isParent = false, parentId = null) {
            // if(isParent)
            //     this.category = parentId
            // else
            //     this.subCategory = id

            if (isParent && !parentId) {
                this.grandCategory = id
                this.category = null
                this.subCategory = null
            } else {
                this.category = parentId
                this.subCategory = id

                if (!parentId && !id) {
                    this.grandCategory = null
                }
            }

        },
    }
};
</script>

<style>
.roundskin .slider-connect {
    background: var(--slider-connect-bg, #c10037) !important;
    cursor: pointer;
    height: 13px;
}

.roundskin .slider-base, .slider-connects {
    height: 13px !important;
}

.roundskin .slider-base,
.slider-connects {
    height: 4px;
    position: relative;
    width: 100%;
    border-radius: 4px;
}

.roundskin .slider-tooltip {
    background: var(--slider-tooltip-bg, #c10037) !important;
    border: 1px solid var(--slider-tooltip-bg, #c10037) !important;
    border-radius: var(--slider-tooltip-radius, 5px);
    color: var(--slider-tooltip-color, #fff);
    display: block;
    font-size: var(--slider-tooltip-font-size, 0.875rem);
    font-weight: var(--slider-tooltip-font-weight, 600);
    line-height: var(--slider-tooltip-line-height, 1.25rem);
    min-width: var(--slider-tooltip-min-width, 20px);
    padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
    position: absolute;
    text-align: center;
    white-space: nowrap;
}

.roundskin-slider .slider-horizontal .slider-handle {
    top: calc(
        (var(--slider-handle-height, 30px) - var(--slider-height, 12px)) / 2 * -1 + 2px
    );
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    border: 3px solid #fff;
    background-color: #c10037;
    border-radius: 24px;
    box-shadow: none;
}

</style>
<style src="@vueform/slider/themes/default.css"></style>
