export default  {
    state: () => ({ cart: [], cartChanged: false }),
    mutations: {
        addToCart(state, payload) {
            let existProduct = state.cart.filter(product => product.product.id === payload.product.id)[0]
            if (existProduct) {
                payload.quantity += existProduct.quantity
                state.cart = state.cart.filter(product => product.product.id !== payload.product.id)
            }

            state.cart.push(payload)
        },
        removeFromCart(state, payload) {
            state.cart = state.cart.filter(product => product.product.id !== payload.product.id)
        },
        increaseQuantity(state, payload) {
            state.cart.filter(product => product.product.id === payload.product.id)[0].quantity = payload.quantity
        },
        decreaseQuantity(state, payload) {
            state.cart.filter(product => product.product.id === payload.product.id)[0].quantity = payload.quantity
        },
        resetCart(state) {
            Object.assign(state, { cart: [] })
        },
        triggerCartChange(state) {
            state.cartChanged = !state.cartChanged
        }
    }
}
